export const parsingDate = (date, withSeconds = false, withTime = true) => {
    if (date) {
        var second = withSeconds ? 'numeric' : undefined;
        var time = withTime ? 'numeric' : undefined;

        var options = { year: 'numeric', month: 'short', day: 'numeric', hour: time, minute: time, second: second };
        return new Date(date).toLocaleDateString([], options);
    } else {
        return '';
    }
};

export const parsingDateWithotTime = (date) => {
    if (date) {
        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(date).toLocaleDateString([], options);
    } else return '';
};

export const uuidv4 = () => {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) => (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16));
};

export const getTotalLengthOfArrays = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        throw new TypeError('Input should be a non-null object');
    }

    let totalLength = 0;

    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            totalLength += obj[key].length;
        }
    }

    return totalLength;
};

export const sizeToByes = (size, units) => {
    if (Number(size) === -1 || Number(size) === 0) return -1;
    switch (units) {
        case 'kb':
            return Number(size) * 1024;
        case 'mb':
            return Number(size) * 1024 * 1024;
        case 'gb':
            return Number(size) * 1024 * 1024 * 1024;
        case 'tb':
            return Number(size) * 1024 * 1024 * 1024 * 1024;
        default:
            return Number(size);
    }
};

export const bytesToSize = (bytes) => {
    const sizes = ['b', 'kb', 'mb', 'gb', 'tb'];
    if (bytes === 0 || bytes === -1) return { size: bytes, unit: 'b' };
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return { size: Math.round(bytes / Math.pow(1024, i), 2), unit: sizes[i] };
};

export const timeToMilliseconds = (time, units) => {
    if (Number(time) === -1 || Number(time) === 0) return -1;
    switch (units) {
        case 's':
            return Number(time) * 1000;
        case 'm':
            return Number(time) * 1000 * 60;
        case 'h':
            return Number(time) * 1000 * 60 * 60;
        case 'd':
            return Number(time) * 1000 * 60 * 60 * 24;
        default:
            return Number(time) * 1000;
    }
};

export const millisecondsToTime = (milliseconds) => {
    if (milliseconds === 0 || milliseconds === -1) return { time: -1, unit: 's' };

    let unit;
    let time;

    if (milliseconds % (1000 * 60 * 60 * 24) === 0) {
        time = milliseconds / (1000 * 60 * 60 * 24);
        unit = 'd';
    } else if (milliseconds % (1000 * 60 * 60) === 0) {
        time = milliseconds / (1000 * 60 * 60);
        unit = 'h';
    } else if (milliseconds % (1000 * 60) === 0) {
        time = milliseconds / (1000 * 60);
        unit = 'm';
    } else {
        time = milliseconds / 1000;
        unit = 's';
    }

    return { time: time, unit: unit };
};

export const retentionToDifferentUnits = (retention) => {
    const bytes = retention?.bytes;
    const time = retention?.time_ms;
    const retentionByUnits = {
        s: time === -1 ? null : Math.floor(time / 1000),
        m: time === -1 ? null : Math.floor(time / (1000 * 60)),
        h: time === -1 ? null : Math.floor(time / (1000 * 60 * 60)),
        d: time === -1 ? null : Math.floor(time / (1000 * 60 * 60 * 24)),
        b: bytes === -1 ? null : bytes.size,
        kb: bytes === -1 ? null : Math.floor(bytes / 1024),
        mb: bytes === -1 ? null : Math.floor(bytes / (1024 * 1024)),
        gb: bytes === -1 ? null : Math.floor(bytes / (1024 * 1024 * 1024)),
        tb: bytes === -1 ? null : Math.floor(bytes / (1024 * 1024 * 1024 * 1024))
    };
    return retentionByUnits;
};
