import './style.scss';
import Button from '../button';
import { TbHexagonMinus } from 'react-icons/tb';
import { IoMdCheckboxOutline } from 'react-icons/io';
import Tooltip from '../tooltip/tooltip';
import { FaLock, FaPlay } from 'react-icons/fa';
import Badge from '../badge';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { CgDanger } from 'react-icons/cg';
import React, { useCallback, useEffect, useState } from 'react';
import FixNow from '../fixNowButton';
import FixNowButton from '../fixNowButton/fixNowButton';
import { Checkbox } from 'antd';

const ListSubItem = React.memo(({ item, handleRemoveSubItem, selectedSubItems, setSelectedSubItems }) => {
    const [sortedMetadata, setSortedMetadata] = useState(item.metadata);

    useEffect(() => {
        if (item.type === 'change_topic_retention') {
            const { retention, ...restMetadata } = item.metadata;

            const newMetadata = {
                retention,
                ...restMetadata
            };

            setSortedMetadata(newMetadata);
        }
    }, [item]);

    const [loading, setLoading] = useState(false);

    const statuses = {
        fixed: 'Fixed',
        ignored: 'Marked as ignore',
        failed: 'Failed'
    };

    const isVisible = (item) => {
        const status = item?.status;
        return !['ignored', 'fixed', 'failed', 'locked'].includes(status);
    };

    const getDisabledState = (value, active) => {
        return value && Array.isArray(value) && value.filter(({ is_active }) => is_active === active).reduce((acc, { name }) => acc + (name ? name.length : 0), 0) < 60;
    };

    const handleCheckboxChange = useCallback((e, item) => {
        const checked = e.target.checked;
        setSelectedSubItems((prevSelectedSubItems) => {
            if (checked) {
                return [...prevSelectedSubItems, { connection_id: item.connection_id, type: item.type, task_id: item.id, data: item.data }];
            } else {
                return prevSelectedSubItems.filter((subItem) => subItem.task_id !== item.id);
            }
        });
    }, []);

    return (
        <div className="sub-list-item">
            <div className="left">
                <Checkbox
                    checked={selectedSubItems.some((subItem) => subItem.task_id === item.id)}
                    onChange={(e) => handleCheckboxChange(e, item)}
                />
            </div>
            <div className="right">
                <div className="top">
                    <div className="sub-list-item-title">
                        <span style={{ color: 'var(--page-subtitle-gray-color)' }}>Name:</span>
                        {item?.data ? (
                            <Tooltip text={item?.data} disabled={item?.data?.length < 50}>
                                <div className="eclipse-text" style={{ maxWidth: 330 }}>
                                    {item?.data}
                                </div>
                            </Tooltip>
                        ) : (
                            'N/A'
                        )}
                    </div>
                    <div className="list-item-actions">
                        {item?.status === 'locked' && (
                            <Tooltip text={'This topic is protected'}>
                                <Badge text={<FaLock fontSize={12} color={'var(--primary-color)'} />} type={'secondary'} />
                            </Tooltip>
                        )}
                        {['fixed', 'ignored'].includes(item?.status) && (
                            <Badge
                                text={
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 4, padding: '2px 7px' }}>
                                        {item?.status === 'fixed' ? <FaRegCircleCheck /> : <TbHexagonMinus />}
                                        {statuses[item?.status]}
                                    </div>
                                }
                                type={'secondary'}
                            />
                        )}
                        {item?.status === 'failed' && (
                            <Tooltip text={item?.failed_reason}>
                                <Badge
                                    text={
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 4, padding: '2px 7px' }}>
                                            <CgDanger />
                                            {statuses[item?.status]}
                                        </div>
                                    }
                                    type={'danger'}
                                />
                            </Tooltip>
                        )}
                        {isVisible(item) && (
                            <FixNow
                                item={item}
                                type="subTask"
                                setPending={setLoading}
                                onComplete={() => handleRemoveSubItem(item)}
                                slot={({ handleConfirmationModalOpen }) => {
                                    return (
                                        <>
                                            <Tooltip text={'Mark as ignored'} placement={'top'}>
                                                <Button
                                                    shape="circle"
                                                    placeholder={<TbHexagonMinus color={'var(--text-color)'} />}
                                                    disabled={item?.status === 'fixed' || item?.status === 'ignored'}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleConfirmationModalOpen('markAsIgnored');
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip text={'Mark as fixed'} placement={'top'}>
                                                <Button
                                                    shape="circle"
                                                    placeholder={<IoMdCheckboxOutline color={'var(--text-color)'} />}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleConfirmationModalOpen('markAsCompleted');
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    );
                                }}
                            />
                        )}
                        {isVisible(item) && (
                            <FixNow
                                item={item}
                                type={'subTask'}
                                setPending={setLoading}
                                onComplete={() => handleRemoveSubItem(item)}
                                slot={({ handleRecreateTopicsModalOpen, handleConfirmationModalOpen, handleRecreateTopicRetentionModalOpen }) => {
                                    return (
                                        <FixNowButton
                                            item={item}
                                            triggerConfirmation={handleConfirmationModalOpen}
                                            triggerRecreateTopicsModal={handleRecreateTopicsModalOpen}
                                            triggerChangeRetentionModal={handleRecreateTopicRetentionModalOpen}
                                            buttonStyle={'subTask'}
                                        />
                                    );
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="body">
                    <div className="meta">
                        {sortedMetadata &&
                            Object.entries(sortedMetadata).map(([key, { label, value }], index) => {
                                const isFullWidth = ['cgs', 'topics'].includes(key);
                                const needsVerticalDivider = ((index + 2) % 3 !== 0 || index === 1) && !['cgs', 'topics'].includes(key);
                                const metaClass = `meta-item ${isFullWidth ? 'full-width' : ''} ${needsVerticalDivider ? 'divider-vertical' : ''}`;
                                const formattedValue = (value) => (Array.isArray(value) ? value.map(({ name }) => name).join(', ') : value);
                                const formattedValueArr = (value) => (Array.isArray(value) ? value.join(', ') : value);

                                const renderTooltip = (active) => (
                                    <Tooltip
                                        overlayStyle={{ maxWidth: 350, fontSize: 12 }}
                                        placement={'left'}
                                        text={
                                            <ul style={{ paddingLeft: 20, display: 'inline-flex', flexDirection: 'column', gap: 7 }}>
                                                {value
                                                    .filter(({ is_active }) => is_active === active)
                                                    .map(({ name }) => (
                                                        <li style={{ wordBreak: 'break-all' }} key={name}>
                                                            {name}
                                                        </li>
                                                    ))}
                                            </ul>
                                        }
                                        disabled={getDisabledState(value, active)}
                                    >
                                        <span>{formattedValue(value.filter(({ is_active }) => is_active === active)) || 'N/A'}</span>
                                    </Tooltip>
                                );

                                return (
                                    <div key={index} className={metaClass}>
                                        {label}:
                                        {key === 'cgs' && value?.length > 0 ? (
                                            <>
                                                {renderTooltip(true)}
                                                <br />
                                                Inactive Consumer Groups:
                                                {renderTooltip(false)}
                                            </>
                                        ) : (
                                            <Tooltip
                                                overlayStyle={{ maxWidth: 350, fontSize: 12 }}
                                                placement={'bottom'}
                                                text={value}
                                                disabled={
                                                    value &&
                                                    ((Array.isArray(value) && value?.reduce((acc, str) => acc + str.length, 0) < 60) || value?.toString().length < 50)
                                                }
                                            >
                                                <span>{isNaN(value) ? formattedValueArr(value) : value?.toLocaleString() || 'N/A'}</span>
                                            </Tooltip>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ListSubItem;
