import './style.scss';
import React from 'react';
import { Input as AntInput } from 'antd';

const Input = ({ id, label, subLabel, placeholder, value, type, onChange, labelColor, icon, customClass, variant, disabled, min, max, suffix, addonAfter, ...props }) => {
    return (
        <div className={`ms-input-wrapper ${icon ? 'icon' : ''} ${customClass || ''}`}>
            <div className="ms-form-label-wrapper">
                {label && (
                    <label htmlFor={id} className="ms-form-label" style={{ color: labelColor }}>
                        {label}
                    </label>
                )}
                {subLabel && <div className="ms-form-label-two">{subLabel}</div>}
            </div>
            {icon && <div className="ms-input-icon">{icon}</div>}
            {type === 'password' ? (
                <AntInput.Password
                    type={type}
                    className={'ms-form-control '}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    style={{ padding: '9px 16px' }}
                    autoComplete="off"
                    disabled={disabled}
                    {...props}
                />
            ) : (
                <AntInput
                    variant={variant}
                    type={type}
                    className={'ms-form-control '}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    autoComplete="off"
                    disabled={disabled}
                    min={min}
                    max={max}
                    suffix={suffix}
                    addonAfter={addonAfter}
                    {...props}
                />
            )}
        </div>
    );
};

export default Input;
