import React, { useEffect, useState, useContext } from 'react';
import { Form, Select } from 'antd';
import Input from 'components/input';
import { Context } from 'hooks/store';
import { IoIosArrowDown } from 'react-icons/io';

const KeyModalForm = ({ onFinish, form, updated, keyToUpdate, currentCluster, connectionView, vendor }) => {
    const [state, dispatch] = useContext(Context);
    const [formFields, setFormFields] = useState([]);

    useEffect(() => {
        form.setFieldsValue({
            type: 'cloud_api_key',
            vendor: vendor || keyToUpdate?.vendor
        });
        setFormFields(form.getFieldsValue());
    }, [form]);

    const handleFinish = (values) => {
        onFinish({ ...values, type: 'cloud_api_key', vendor: formFields?.vendor || vendor || keyToUpdate?.vendor });
    };

    useEffect(() => {
        if (connectionView) {
            form.setFieldsValue({
                type: 'cloud_api_key',
                vendor: vendor
            });
            setFormFields({ ...formFields, type: 'cloud_api_key', vendor: vendor });
        }
    }, [connectionView]);

    useEffect(() => {
        if (currentCluster) {
            form.setFieldValue('connections', [currentCluster]);
            setFormFields({ ...formFields, connections: [currentCluster] });
        }
    }, [currentCluster]);

    const keyFormFields = [
        {
            name: 'name',
            type: 'string',
            label: 'Key name',
            required: true,
            placeholder: 'Enter name'
        },
        {
            name: 'type',
            type: 'select',
            label: 'Key type',
            required: true,
            placeholder: 'Select key type',
            options: [{ value: 'cloud_api_key', label: 'Cloud API key' }],
            default: 'cloud_api_key',
            dependent: {
                cloud_api_key: [
                    {
                        name: 'vendor',
                        type: 'select',
                        label: 'Vendor',
                        required: true,
                        placeholder: 'Select vendor',
                        options: [
                            { value: 'confluent_cloud_kafka', label: 'Confluent (Cloud)' },
                            { value: 'aiven', label: 'Aiven' },
                            { value: 'msk', label: 'MSK' },
                            { value: 'msk_serverless', label: 'MSK (Serverless)' }
                        ],
                        default: vendor || keyToUpdate?.vendor,
                        dependent: {
                            confluent_cloud_kafka: [
                                {
                                    name: 'key',
                                    type: 'string',
                                    label: 'Key',
                                    required: true,
                                    placeholder: 'Enter key'
                                },
                                {
                                    name: 'secret',
                                    type: 'string',
                                    label: 'Secret',
                                    required: true,
                                    placeholder: 'Enter secret'
                                },
                                {
                                    name: 'connections',
                                    type: 'select',
                                    label: 'Connections',
                                    required: false,
                                    placeholder: 'Select connections',
                                    options:
                                        state?.connections
                                            ?.filter((connection) => connection?.id !== -2 && connection?.type === formFields?.vendor)
                                            ?.map((option) => ({ value: option?.id, label: option?.name })) || [],
                                    mode: 'multiple',
                                    disabled: keyToUpdate || connectionView
                                }
                            ],
                            aiven: [
                                {
                                    name: 'secret',
                                    type: 'string',
                                    label: 'Secret',
                                    required: true,
                                    placeholder: 'Enter secret'
                                },
                                {
                                    name: 'connections',
                                    type: 'select',
                                    label: 'Connections',
                                    required: false,
                                    placeholder: 'Select connections',
                                    options:
                                        state?.connections
                                            ?.filter((connection) => connection?.id !== -2 && connection?.type === formFields?.vendor)
                                            ?.map((option) => ({ value: option?.id, label: option?.name })) || [],
                                    mode: 'multiple',
                                    disabled: keyToUpdate || connectionView
                                }
                            ],
                            msk: [
                                {
                                    name: 'region',
                                    type: 'select',
                                    label: 'Region',
                                    required: true,
                                    placeholder: 'Enter region',
                                    options: [
                                        { value: 'af-south-1', label: 'af-south-1' },
                                        { value: 'ap-east-1', label: 'ap-east-1' },
                                        { value: 'ap-northeast-1', label: 'ap-northeast-1' },
                                        { value: 'ap-northeast-2', label: 'ap-northeast-2' },
                                        { value: 'ap-northeast-3', label: 'ap-northeast-3' },
                                        { value: 'ap-south-1', label: 'ap-south-1' },
                                        { value: 'ap-south-2', label: 'ap-south-2' },
                                        { value: 'ap-southeast-1', label: 'ap-southeast-1' },
                                        { value: 'ap-southeast-2', label: 'ap-southeast-2' },
                                        { value: 'ap-southeast-3', label: 'ap-southeast-3' },
                                        { value: 'ap-southeast-4', label: 'ap-southeast-4' },
                                        { value: 'ca-central-1', label: 'ca-central-1' },
                                        { value: 'cn-north-1', label: 'cn-north-1' },
                                        { value: 'cn-northwest-1', label: 'cn-northwest-1' },
                                        { value: 'eu-central-1', label: 'eu-central-1' },
                                        { value: 'eu-central-2', label: 'eu-central-2' },
                                        { value: 'eu-north-1', label: 'eu-north-1' },
                                        { value: 'eu-south-1', label: 'eu-south-1' },
                                        { value: 'eu-south-2', label: 'eu-south-2' },
                                        { value: 'eu-west-1', label: 'eu-west-1' },
                                        { value: 'eu-west-2', label: 'eu-west-2' },
                                        { value: 'eu-west-3', label: 'eu-west-3' },
                                        { value: 'il-central-1', label: 'il-central-1' },
                                        { value: 'me-central-1', label: 'me-central-1' },
                                        { value: 'me-south-1', label: 'me-south-1' },
                                        { value: 'sa-east-1', label: 'sa-east-1' },
                                        { value: 'us-east-1', label: 'us-east-1' },
                                        { value: 'us-east-2', label: 'us-east-2' },
                                        { value: 'us-gov-east-1', label: 'us-gov-east-1' },
                                        { value: 'us-gov-west-1', label: 'us-gov-west-1' },
                                        { value: 'us-west-1', label: 'us-west-1' },
                                        { value: 'us-west-2', label: 'us-west-2' }
                                    ]
                                },
                                {
                                    name: 'security_protocol',
                                    type: 'select',
                                    label: 'Security protocol',
                                    required: true,
                                    placeholder: 'Choose your sec protocol',
                                    options: [
                                        { value: 'iam_user', label: 'IAM user' },
                                        { value: 'iam_role', label: 'IAM role' }
                                    ],
                                    dependent: {
                                        iam_user: [
                                            {
                                                name: 'key',
                                                type: 'string',
                                                label: 'Key',
                                                required: true,
                                                placeholder: 'Enter key'
                                            },
                                            {
                                                name: 'secret',
                                                type: 'string',
                                                label: 'Secret',
                                                required: true,
                                                placeholder: 'Enter secret'
                                            }
                                        ],
                                        iam_role: [
                                            {
                                                name: 'key',
                                                type: 'string',
                                                label: 'Role ARN',
                                                required: true,
                                                placeholder: 'Enter role ARN'
                                            }
                                        ]
                                    }
                                },
                                {
                                    name: 'connections',
                                    type: 'select',
                                    label: 'Connections',
                                    required: false,
                                    placeholder: 'Select connections',
                                    options:
                                        state?.connections
                                            ?.filter((connection) => connection?.id !== -2 && connection?.type === formFields?.vendor)
                                            ?.map((option) => ({ value: option?.id, label: option?.name })) || [],
                                    mode: 'multiple',
                                    disabled: keyToUpdate || connectionView
                                }
                            ],
                            msk_serverless: [
                                {
                                    name: 'region',
                                    type: 'select',
                                    label: 'Region',
                                    required: true,
                                    placeholder: 'Enter region',
                                    options: [
                                        { value: 'af-south-1', label: 'af-south-1' },
                                        { value: 'ap-east-1', label: 'ap-east-1' },
                                        { value: 'ap-northeast-1', label: 'ap-northeast-1' },
                                        { value: 'ap-northeast-2', label: 'ap-northeast-2' },
                                        { value: 'ap-northeast-3', label: 'ap-northeast-3' },
                                        { value: 'ap-south-1', label: 'ap-south-1' },
                                        { value: 'ap-south-2', label: 'ap-south-2' },
                                        { value: 'ap-southeast-1', label: 'ap-southeast-1' },
                                        { value: 'ap-southeast-2', label: 'ap-southeast-2' },
                                        { value: 'ap-southeast-3', label: 'ap-southeast-3' },
                                        { value: 'ap-southeast-4', label: 'ap-southeast-4' },
                                        { value: 'ca-central-1', label: 'ca-central-1' },
                                        { value: 'cn-north-1', label: 'cn-north-1' },
                                        { value: 'cn-northwest-1', label: 'cn-northwest-1' },
                                        { value: 'eu-central-1', label: 'eu-central-1' },
                                        { value: 'eu-central-2', label: 'eu-central-2' },
                                        { value: 'eu-north-1', label: 'eu-north-1' },
                                        { value: 'eu-south-1', label: 'eu-south-1' },
                                        { value: 'eu-south-2', label: 'eu-south-2' },
                                        { value: 'eu-west-1', label: 'eu-west-1' },
                                        { value: 'eu-west-2', label: 'eu-west-2' },
                                        { value: 'eu-west-3', label: 'eu-west-3' },
                                        { value: 'il-central-1', label: 'il-central-1' },
                                        { value: 'me-central-1', label: 'me-central-1' },
                                        { value: 'me-south-1', label: 'me-south-1' },
                                        { value: 'sa-east-1', label: 'sa-east-1' },
                                        { value: 'us-east-1', label: 'us-east-1' },
                                        { value: 'us-east-2', label: 'us-east-2' },
                                        { value: 'us-gov-east-1', label: 'us-gov-east-1' },
                                        { value: 'us-gov-west-1', label: 'us-gov-west-1' },
                                        { value: 'us-west-1', label: 'us-west-1' },
                                        { value: 'us-west-2', label: 'us-west-2' }
                                    ]
                                },
                                {
                                    name: 'security_protocol',
                                    type: 'select',
                                    label: 'Security protocol',
                                    required: true,
                                    placeholder: 'Choose your sec protocol',
                                    options: [
                                        { value: 'iam_user', label: 'IAM user' },
                                        { value: 'iam_role', label: 'IAM role' }
                                    ],
                                    dependent: {
                                        iam_user: [
                                            {
                                                name: 'key',
                                                type: 'string',
                                                label: 'Key',
                                                required: true,
                                                placeholder: 'Enter key'
                                            },
                                            {
                                                name: 'secret',
                                                type: 'string',
                                                label: 'Secret',
                                                required: true,
                                                placeholder: 'Enter secret'
                                            }
                                        ],
                                        iam_role: [
                                            {
                                                name: 'key',
                                                type: 'string',
                                                label: 'Role ARN',
                                                required: true,
                                                placeholder: 'Enter role ARN'
                                            }
                                        ]
                                    }
                                },
                                {
                                    name: 'connections',
                                    type: 'select',
                                    label: 'Connections',
                                    required: false,
                                    placeholder: 'Select connections',
                                    options:
                                        state?.connections
                                            ?.filter((connection) => connection?.id !== -2 && connection?.type === formFields?.vendor)
                                            ?.map((option) => ({ value: option?.id, label: option?.name })) || [],
                                    mode: 'multiple',
                                    disabled: keyToUpdate || connectionView
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ];

    const generateFormItem = (form, input, index, depth, inputName) => {
        return (
            <div key={`${input.name}_${index}`}>
                {input.name !== 'type' && (!connectionView || (input.name !== 'connections' && input.name !== 'vendor')) && (
                    <Form.Item
                        key={input.name}
                        name={input?.name}
                        validateTrigger="onChange"
                        rules={[
                            {
                                required: input?.required,
                                message: `required`
                            }
                        ]}
                        initialValue={input?.default}
                    >
                        {input?.type === 'string' && (
                            <div key={'input_' + input.name}>
                                <Input
                                    label={
                                        <>
                                            {input.label}
                                            {input.required && <span className="ms-form-required">*</span>}
                                        </>
                                    }
                                    type={input.type}
                                    placeholder={input.placeholder}
                                    value={formFields[input.name]}
                                    onChange={(e) => {
                                        form.setFieldValue(input.name, e.target.value);
                                        setFormFields({ ...formFields, [input.name]: e.target.value });
                                        updated && updated({ [input.name]: e.target.value });
                                    }}
                                    disabled={input?.disabled}
                                />
                            </div>
                        )}
                        {input?.type === 'select' && (
                            <div key={'select_' + input.name}>
                                <div className="ms-form-label-wrapper" style={{ marginBottom: '10px' }}>
                                    <label htmlFor={input.name} className="ms-form-label">
                                        {input.label}
                                        {input.required && <span className="ms-form-required">*</span>}
                                    </label>
                                    <div className="ms-form-label-two">{input.description}</div>
                                </div>
                                <Select
                                    defaultValue={input.default}
                                    suffixIcon={<IoIosArrowDown />}
                                    id={input.name}
                                    placeholder={input.placeholder}
                                    options={input.options}
                                    value={formFields[input.name]}
                                    onChange={(value) => {
                                        form.setFieldValue(input.name, value);
                                        setFormFields({ ...formFields, [input.name]: value });
                                        updated && updated({ [input.name]: value });
                                    }}
                                    mode={input?.mode}
                                    disabled={input?.disabled}
                                />
                            </div>
                        )}
                    </Form.Item>
                )}
                {input.dependent &&
                    Object.entries(input.dependent).map(([value, children], index) => {
                        if (form.getFieldValue(input.name) !== value) return <React.Fragment key={`${value}_${index}`} />;
                        return children.map((child, childIndex) => generateFormItem(form, child, childIndex, depth));
                    })}
            </div>
        );
    };

    return (
        <Form name="connector-modal" onFinish={handleFinish} autoComplete="off" form={form}>
            {keyFormFields.map(
                (input, index) => generateFormItem(form, input, 'parent_' + index, 0) // Initial depth: 0
            )}
        </Form>
    );
};

export default KeyModalForm;
