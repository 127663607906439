import ButtonsWrapper from 'components/buttonsWrapper';
import Button from 'components/button';
import CustomButtonWrapper from 'components/buttonsWrapper/CustomButtonWrapper';
import Editor from 'components/editor';
import { ReactComponent as GenerateIcon } from 'assets/images/graphView/generate.svg';
import { useState } from 'react';
import { downloadYamlFile } from 'services/genericServices';
import CopyCode from '../copyCode';

const StepThree = ({}) => {
    const [configuration, setConfiguration] = useState(`superstreamEngine:
    configMap:
      enable: true
      name: connection.conf
      fileData:
        fileName: connection.conf
        fileContent: | 
          connections:
          - name: <connection_name>
            type: <connection_type> # available types : confluent_kafka, confluent_cloud_kafka, MSK, apache_kafka
            hosts: # list of Bootstrap servers
              - <bootstrap_server_1>
              - <bootstrap_server_2>
            authentication:  # Specify one prefered method for connection
              method:
                noAuthentication:
                  enabled: <true_or_false>
                ssl:
                  enabled: <true_or_false>
                  protocol: SSL
                  ca: <path_to_ca_cert>
                  cert: <path_to_cert>
                  key: <path_to_key>
                  insecureSkipVerify: <true_or_false>
                sasl:
                  enabled: <true_or_false>  
                  mechanism: <sasl_mechanism> # sasl_mechanism options: "PLAIN", "SCRAM-SHA-512" 
                  protocol: SASL_SSL
                  username: <username>
                  password: <password>
                  tls:
                    enabled: <true_or_false>
                    ca: <path_to_ca_cert>
                    cert: <path_to_cert>
                    key: <path_to_key>
                    insecureSkipVerify: <true_or_false>`);

    return (
        <>
            <div className="add-connector-drawer-subheader">
                <div className="left">
                    <div className="add-connector-drawer-content">
                        <div className="add-connector-drawer-content-title">
                            Optional: Edit <em>config.yaml</em>
                        </div>
                        <div className="add-connector-drawer-content-subtitle">
                            Every engine is capable of establishing connections with one or several Kafka clusters simultaneously.
                        </div>
                    </div>
                </div>
                <div className="right"></div>
            </div>
            <div className="add-connector-drawer-editor" style={{ height: 'calc( 100vh - 375px)' }}>
                <div className="add-connector-drawer-editor-top">
                    <CopyCode code={configuration} hideCode={true} style={{ background: 'transparent', padding: 0 }} />
                </div>
                <Editor value={configuration} setValue={(e) => setConfiguration(e)} lang="yaml" />
            </div>
        </>
    );
};

export default StepThree;
