import '../style.scss';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Input from 'components/input';
import { FaArrowLeft } from 'react-icons/fa6';
import { FaUndo } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { ReactComponent as AIicon } from 'assets/images/ssmAI.svg';
import TooltipComponent from 'components/tooltip/tooltip';
import { Form, Select, Switch } from 'antd';
import { bytesToSize, millisecondsToTime } from 'services/valueConvertor';

const ConfigItem = forwardRef((
        {
            title,
            description,
            type,
            label,
            name,
            initialValue,
            configForm,
            min,
            max,
            defaultValue,
            placeholder,
            regex,
            updateRetentionUnits,
            updateNoRetention,
            isChild = false,
            index,
            updateFormValues,
        }, ref
    ) => {
        const [value, setValue] = useState();
        const [error, setError] = useState(null);
        const [retentionUnits, setRetentionUnits] = useState(null);

        useEffect(() => {
            if (type === 'retention') {
                const bytes = bytesToSize(initialValue?.bytes);
                const time_ms = millisecondsToTime(initialValue?.time_ms);
                configForm.setFieldsValue({bytes: bytes.size, time_ms: time_ms.time});
                setValue({bytes: bytes.size, time_ms: time_ms.time});
                setRetentionUnits({size: bytes.unit, time: time_ms.unit});
            }
        }, [initialValue]);

        const handleChange = (e) => {
            const newValue = e.target.value;
            setValue(newValue);
            configForm.setFieldsValue({[name]: newValue});
            validateValue(newValue);
        };

        const handleChangeRetention = (newValue, retentionType) => {
            setValue({...value, [retentionType]: newValue});
            configForm.setFieldValue({[name]: {...value, [retentionType]: Number(newValue) || -1}});
            validateValue(newValue);
        };

        const handletToggleChange = (e) => {
            setValue(e);
            configForm.setFieldsValue({[name]: e});
        };

        const validateValue = (value) => {
            let errorMessage = null;
            try {
                if (type === 'retention' && value < -1) {
                    errorMessage = 'Please input a valid value!';
                } else if (type === 'text' && regex && !new RegExp(value)) {
                    errorMessage = 'Please input a valid value!';
                } else if ((min !== undefined && value < min) || (max !== undefined && value > max)) {
                    errorMessage =
                        min !== undefined && max !== undefined
                            ? `Value must be between ${min} and ${max}`
                            : `Value must be ${min !== undefined ? `greater than ${min}` : `less than ${max}`}`;
                }
                setError(errorMessage);
            } catch (error) {
                errorMessage = 'Please input a valid value!';
                setError(errorMessage);
            } finally {
                return errorMessage === null;
            }
        };

        useImperativeHandle(ref, () => ({
            validate: () => validateValue(value)
        }));

        const resetToDefault = () => {
            configForm.setFieldsValue({[name]: defaultValue});
            setValue(defaultValue);
            updateFormValues({[name]: defaultValue});
            setError(null);
        };

        const selectAfterSize = (
            <Select
                value={retentionUnits?.size || 'b'}
                onChange={(e) => {
                    updateRetentionUnits(value.bytes, {...retentionUnits, size: e}, 'bytes');
                    setRetentionUnits({...retentionUnits, size: e});
                }}
                suffixIcon={<IoIosArrowDown/>}
                options={[
                    {value: 'b', label: 'Byte(s)'},
                    {value: 'kb', label: 'KB'},
                    {value: 'mb', label: 'MB'},
                    {value: 'gb', label: 'GB'},
                    {value: 'tb', label: 'TB'}
                ]}
            />
        );

        const selectAfterTime = (
            <Select
                value={retentionUnits?.time || 's'}
                onChange={(e) => {
                    updateRetentionUnits(value.time_ms, {...retentionUnits, time: e}, 'time_ms');
                    setRetentionUnits({...retentionUnits, time: e});
                }}
                suffixIcon={<IoIosArrowDown/>}
                options={[
                    {value: 's', label: 'seconds'},
                    {value: 'm', label: 'minutes'},
                    {value: 'h', label: 'hours'},
                    {value: 'd', label: 'days'}
                ]}
            />
        );

        return (
            <div className={"config-item " + (isChild && "config-item-child")}>
                <div className="inputs-section-container">
                    <span>
                        {(!index || index === 0) &&
                            <>
                                <p>{title}</p>
                                <label>{description}</label>
                            </>
                        }
                    </span>
                    <span className="inputs-section">
                        {type === 'tuggle' ? (
                            <Form.Item name={name}>
                                <Switch value={value}
                                        style={{backgroundColor: value ? 'var(--primary-color)' : 'var(--search-bg)'}}
                                        onChange={handletToggleChange}/>
                            </Form.Item>
                        ) : (
                            <>
                                {type === 'retention' && (
                                    <>
                                        <Form.Item name={'bytes'} validateStatus={error ? 'error' : 'success'}
                                                   help={error} initialValue={initialValue?.bytes}>
                                            <span className="retentiom-item">
                                                <span className="switch-section">
                                                    <label>Enable retention by size</label>
                                                </span>
                                                <Input
                                                    label={label}
                                                    type={'number'}
                                                    placeholder={placeholder || -1}
                                                    value={value?.bytes}
                                                    min={-1}
                                                    onChange={(e) => handleChangeRetention(e.target.value, 'bytes')}
                                                    addonAfter={selectAfterSize}
                                                    subLabel="For infinite retention, set the size to -1"
                                                />
                                            </span>
                                        </Form.Item>
                                        <Form.Item name={'time_ms'} validateStatus={error ? 'error' : 'success'}
                                                   help={error} initialValue={initialValue?.time_ms}>
                                            <span className="retentiom-item">
                                                <span className="switch-section">
                                                    <label>Enable retention by duration</label>
                                                </span>
                                                <Input
                                                    label={label}
                                                    type={'number'}
                                                    placeholder={placeholder || -1}
                                                    value={value?.time_ms}
                                                    min={-1}
                                                    onChange={(e) => handleChangeRetention(e.target.value, 'time_ms')}
                                                    addonAfter={selectAfterTime}
                                                    subLabel={"For infinite retention, set the duration to -1"}
                                                />
                                            </span>
                                        </Form.Item>
                                    </>
                                )}
                                {type !== 'retention' && (
                                    <Form.Item name={name} validateStatus={error ? 'error' : 'success'} help={error}
                                               initialValue={initialValue}>
                                        <Input
                                            label={label}
                                            type={type}
                                            placeholder={placeholder || initialValue}
                                            value={value}
                                            min={min || null}
                                            max={max || null}
                                            onChange={handleChange}
                                            suffix={
                                                <TooltipComponent
                                                    text={value !== defaultValue ? 'Set default value' : ''}>
                                                    <FaUndo
                                                        color="rgba(255, 255, 255, 0.7)"
                                                        fontSize="12px"
                                                        cursor={configForm.getFieldValue(name) !== defaultValue ? 'pointer' : 'not-allowed'}
                                                        onClick={() => configForm.getFieldValue(name) !== defaultValue && resetToDefault()}
                                                    />
                                                </TooltipComponent>
                                            }
                                        />
                                    </Form.Item>
                                )}
                                {type !== 'retention' && (
                                    <>
                                        <span className="accept-suggestion">
                                            <FaArrowLeft color="var(--color-white)"/>
                                        </span>
                                        <Input
                                            label={
                                                <span>
                                                    <span>Superstream suggestion</span>
                                                    <AIicon/>
                                                </span>
                                            }
                                            type="text"
                                            placeholder={initialValue}
                                            value="Soon"
                                            disabled
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </span>
                </div>
            </div>
        );
    }
);

export default ConfigItem;
