import './style.scss';
import { ConfigProvider, Table as TableComponent } from 'antd';
import { ReactComponent as NoTableData } from 'assets/images/optimization/noTableData.svg';
import SortIcons from "components/sortIcon";

const Table = ({ columns, onCustomRowClick, ...props }) => {
    const modifiedColumns = columns.map(column => {
        if (column.sorter) {
            return {
                ...column,
                title: ({ sortColumns }) => {
                    if (!column.title) return;

                    const sortedColumn = sortColumns?.find(({ column: col }) => col.key === column.key);
                    return (
                        <div className="antd-table-custom-sort">
                            <SortIcons sortOrder={sortedColumn?.order} />
                            {column.title}
                        </div>
                    );
                },
            };
        }
        return column;
    });

    return (
        <div className="optimization-table">
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            borderColor: '#EAECF0',
                            padding: '16px 24px',
                        },
                    },
                }}
            >
                <TableComponent
                    locale={{
                        emptyText: (
                            <div className="no-data">
                                <NoTableData />
                                <p>No data to display</p>
                            </div>
                        )
                    }}
                    {...props}
                    columns={modifiedColumns}
                    onRow={(record, _) => {
                        if (onCustomRowClick) {
                            return {
                                onClick: (event) => {
                                    onCustomRowClick(record);
                                }
                            };
                        }
                    }}
                    className={"custom-table"}
                />
            </ConfigProvider>
        </div>
    );
};

export default Table;
