import "./stye.scss";
import {ReactComponent as EmptyIcon} from "assets/images/autoScaler/empty.svg";
import Button from "components/button";

const EmptyStateAutoScaler = ({ onSubmit }) => {

    return (
        <div className="empty-state-auto-scaler">
            <div className="icon">
                <EmptyIcon/>
            </div>
            <div className="content">
                <h1>No rules yet</h1>
                <p>Create new rule to automatically manage your <br/> subscription. Rules can be edited or removed.</p>
            </div>
            <div className="action">
                <Button
                    placeholder={"Add New Rule"}
                    typeOfButton={"primary"}
                    onClick={onSubmit}
                />
            </div>
        </div>
    );
};

export default EmptyStateAutoScaler;