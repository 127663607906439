import './style.scss';
import OptimizationCharts from 'components/optimizationCharts';
import TodoList from 'components/todoList';
import ConnectorModal from 'components/Modals/ConnectorModal';
import {
    SESSION_STORAGE_ACCOUNT_DATA,
    SESSION_STORAGE_ACCOUNT_ID,
    SESSION_STORAGE_LOGIN_PROVIDER,
    SESSION_STORAGE_USER,
    SESSION_STORAGE_USER_NAME
} from 'utils/localStorageConsts';
import { useEffect, useState, useContext, useRef } from 'react';
import { apiEndpoints } from 'services/apiEndpoints';
import { useHttpRequest } from 'services/http';
import Loader from 'components/loader';
import AddConnectorPlaceholder from 'components/addConnectorPlaceholder';
import { ReactComponent as AddConnectorPlaceholderIcon } from 'assets/images/documents.svg';
import { calculateETA } from 'services/genericServices';
import { Context } from 'hooks/store';
import RefreshButton from 'components/refreshButton';
import InfoCards from './components/infoCards';
import CustomRangePicker from 'components/customDateRangePicker';
import Notification from "../../components/notifications";

const Dashboard = () => {
    const [state, dispatch] = useContext(Context);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [summaryData, setSummaryData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const httpRequest = useHttpRequest();
    const refreshRef = useRef(null);
    const [infoCardData, setInfoCardData] = useState(null);
    const [isFirstLogin, setIsFirstLogin] = useState(false);
    const [welcomeMessage, setWelcomeMessage] = useState('');


    useEffect(() => {
        setIsPageLoading(true);
        getConnectionSummary().catch();
        fetchInfoCard().catch();
    }, []);

    const getConnectionSummary = async (startDate, endDate) => {
        setIsLoading(true);

        let queryParams = startDate && endDate ? { startDate, endDate } : {};

        try {
            const data = await httpRequest('GET', apiEndpoints.GET_AGGREGATED_SUMMARY, {}, {}, queryParams);
            setSummaryData(data?.summary);
        } catch (error) {
            setIsPageLoading(false);
            Notification('error', 'Something went wrong, please try again', 15);
        } finally {
            setIsPageLoading(false);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCurrentUserName();
    }, []);

    const getCurrentUserName = () => {
        let userName = null,
            accountName = null;
        const user = sessionStorage.getItem(SESSION_STORAGE_USER);
        const accounts = sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_DATA);
        const currentAccountId = sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_ID);
        userName = sessionStorage.getItem(SESSION_STORAGE_USER_NAME);

        if (user) {
            const userData = JSON.parse(user);
            const isUserFirstLogin = userData.createdAt === userData.lastLoginAt;
            const greeting = isUserFirstLogin ? 'Welcome' : 'Welcome back';
            let name = '';
            if (!userName) {
                if (process.env.REACT_APP_ENVIRONMENT === 'onprem') {
                    name = userData?.name;
                } else {
                    name = sessionStorage.getItem(SESSION_STORAGE_LOGIN_PROVIDER) === 'SSO' ? userData?.name : userData?.displayName;
                }
                userName = name ? greeting + ', ' + name : greeting;
            } else {
                userName = greeting + ', ' + userName;
            }

            setIsFirstLogin(isUserFirstLogin);
        }

        if (accounts && currentAccountId) {
            const accountData = JSON.parse(accounts);
            const account = accountData?.accounts?.find((account) => account.id === parseInt(currentAccountId));
            accountName = 'Welcome back, ' + account?.name;
        }

        setWelcomeMessage(userName || accountName || '');
    };

    const handleRefresh = async () => {
        setIsLoading(true);
        await getConnectionSummary();
        await fetchInfoCard();
        refreshRef?.current?.handleRefresh();
    };

    const fetchInfoCard = async () => {
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_ACHIVEMENTS, {}, {}, { connection_id: -1 });
            setInfoCardData(data?.achievements);
        } catch (error) {
            setIsPageLoading(false);
        } finally {
            setIsPageLoading(false);
            setIsLoading(false);
        }
    };

    return isPageLoading ? (
        <Loader isFullHeight={true} background={false} />
    ) : (
        <div className="dashboard">
            <div className="dashboard-top">
                <span>
                    <h2>{welcomeMessage}</h2>
                    {summaryData?.status !== 'running' && !isFirstLogin && (
                        <span>
                            <label>{`Next sync: ${calculateETA(summaryData?.eta_next_report)}`}</label>
                        </span>
                    )}
                </span>
                <RefreshButton onClick={handleRefresh} isLoading={isLoading} />
            </div>
            <div className="dashboard-body">
                {!isPageLoading && state?.connections?.length > 0 ? (
                    <>
                        <h4>You saved so far</h4>
                        <InfoCards data={infoCardData} />
                        <div className="dashboard-body-chart">
                            <div className="dashboard-body-chart-header">
                                <h4>Potential optimizations insights</h4>
                                <CustomRangePicker onChange={getConnectionSummary} graphData={summaryData?.graph_data} />
                            </div>
                            <OptimizationCharts
                                data={summaryData?.graph_data}
                                eta={summaryData?.eta_next_report_time}
                                reportStatus={summaryData?.status}
                            />
                        </div>
                        <TodoList height="calc( 100vh - 530px )" ref={refreshRef} doneRefresh={() => setIsLoading(false)} />
                    </>
                ) : (
                    <AddConnectorPlaceholder
                        img={<AddConnectorPlaceholderIcon />}
                        text="Get Started with Superstream"
                        btnText="Connect a new cluster"
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    />
                )}
            </div>
            <ConnectorModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </div>
    );
};
export default Dashboard;
