export const modeOptions = [
    {
        label: (
            <div>
                Supervised <br/> An optimization will be created for manual apply and a notification will be sent
            </div>
        ),
        value: 'supervised',
    },
    {
        label: (
            <div className="targetExistingPlanSelectFormat">
                <span>Automatic <br/> Full automatic scaling</span>
                <span className="badge badge-soon">SOON</span>
            </div>
        ),
        value: 'transition',
        disabled: true
    }
];

export const conditionTypeOptions = {
    aiven: [
        {
            "label": "CPU usage - percentage of CPU resources utilized",
            "value": "cpu_usage",
            "unit": "percent"
        },
        {
            "label": "Memory usage - percentage of memory resources utilized",
            "value": "memory_usage",
            "unit": "percent"
        },
        {
            "label": "Disk usage - percentage of disk space utilized",
            "value": "disk_usage",
            "unit": "percent"
        },
        {
            "label": "Load average - the 5-minute average CPU load",
            "value": "load_average",
            "unit": "percent"
        },
        {
            "label": "Average storage throughput - total volume read-write operation in MiB/s",
            "value": "composite_average_storage_throughput",
            "unit": "MiB/s"
        }
    ],
    msk: [
        {
            "label": "CPU Usage - percentage of CPU resources utilized",
            "value": "CompositeCpuUsage",
            "unit": "percent"
        },
        {
            "label": "Memory usage - percentage of memory resources utilized",
            "value": "CompositeMemoryUsage",
            "unit": "percent"
        },
        {
            "label": "Disk usage (KafkaDataLogsDiskUsed) - percentage of disk space used for data logs",
            "value": "KafkaDataLogsDiskUsed",
            "unit": "percent"
        },
        {
            "label": "Average storage throughput - total volume read-write operation in MiB/s",
            "value": "CompositeAverageStorageThroughput",
            "unit": "MiB/s"
        },
        {
            "label": "HeapMemoryAfterGC - percentage of total heap memory in use after garbage collection",
            "value": "HeapMemoryAfterGC",
            "unit": "percent"
        },
        {
            "label": "UnderReplicatedPartitions - number of under-replicated partitions for the broker",
            "value": "UnderReplicatedPartitions",
            "unit": "number"
        },
        {
            "label": "UnderMinIsrPartitionCount - number of under-min In-Sync Replicas (ISR) partitions for the broker",
            "value": "UnderMinIsrPartitionCount",
            "unit": "number"
        },
        {
            "label": "ProduceTotalTimeMsMean - mean produce time in milliseconds",
            "value": "ProduceTotalTimeMsMean",
            "unit": "ms"
        },
        {
            "label": "FetchConsumerTotalTimeMsMean - mean time consumers spend fetching data from the broker in milliseconds",
            "value": "FetchConsumerTotalTimeMsMean",
            "unit": "ms"
        },
        {
            "label": "RequestTime - mean time in milliseconds spent in the broker network and I/O threads to process requests",
            "value": "RequestTime",
            "unit": "ms"
        },
        {
            "label": "SumOffsetLag - aggregated offset lag for all the partitions in a topic",
            "value": "SumOffsetLag",
            "unit": "number"
        }
    ],
};

export const conditionOptions = [
    {
        label: 'Greater than',
        value: 'gt'
    },
    {
        label: 'Less than',
        value: 'lt'
    },
    {
        label: 'Equal to',
        value: 'eq'
    },
    {
        label: 'Less or equal than',
        value: 'lte'
    },
    {
        label: 'More or equal than',
        value: 'gte'
    }
];

export const timeUnits = [
    {
        label: 'minutes',
        value: 'minutes'
    },
    {
        label: 'hours',
        value: 'hours'
    },
    {
        label: 'days',
        value: 'days'
    }
];

export const doOptions = {
    msk: [
        {
            label: 'Change instance type',
            value: 'instance_type'
        },
        /*{
            label: 'Increase amount of brokers',
            value: 'increase_brokers'
        },
        {
            label: 'Decrease amount of brokers',
            value: 'decrease_brokers'
        }*/
    ],
    aiven: [
        {
            label: 'Change Plan',
            value: 'plan_type',
        },
        /*{
            label: 'Upgrade plan',
            value: 'upgrade_plan'
        },
        {
            label: 'Downgrade plan',
            value: 'downgrade_plan'
        }*/
    ]
};

export const instanceTypesMap = {
    msk: {
        instance_type: [
            {
                label: "kafka.t3.small",
                value: "kafka.t3.small"
            },
            {
                label: "kafka.m5.large",
                value: "kafka.m5.large"
            },
            {
                label: "kafka.m7g.large",
                value: "kafka.m7g.large"
            },
            {
                label: "kafka.m5.xlarge",
                value: "kafka.m5.xlarge"
            },
            {
                label: "kafka.m7g.xlarge",
                value: "kafka.m7g.xlarge"
            },
            {
                label: "kafka.m5.2xlarge",
                value: "kafka.m5.2xlarge"
            },
            {
                label: "kafka.m7g.2xlarge",
                value: "kafka.m7g.2xlarge"
            },
            {
                label: "kafka.m5.4xlarge",
                value: "kafka.m5.4xlarge"
            },
            {
                label: "kafka.m7g.4xlarge",
                value: "kafka.m7g.4xlarge"
            },
            {
                label: "kafka.m5.8xlarge",
                value: "kafka.m5.8xlarge"
            },
            {
                label: "kafka.m7g.8xlarge",
                value: "kafka.m7g.8xlarge"
            },
            {
                label: "kafka.m5.12xlarge",
                value: "kafka.m5.12xlarge"
            },
            {
                label: "kafka.m7g.12xlarge",
                value: "kafka.m7g.12xlarge"
            },
            {
                label: "kafka.m5.16xlarge",
                value: "kafka.m5.16xlarge"
            },
            {
                label: "kafka.m7g.16xlarge",
                value: "kafka.m7g.16xlarge"
            },
            {
                label: "kafka.m5.24xlarge",
                value: "kafka.m5.24xlarge"
            }
        ]
    },
    aiven: {
        plan_type: {
            startup: [],
            business: [
                {
                    label: "Business-4 - 3 dedicated VMs, 2 CPU, 4GB RAM, 600GB Storage",
                    value: "business-4"
                },
                {
                    label: "Business-8 - 3 dedicated VMs, 2 CPU, 8GB RAM, 1200GB Storage",
                    value: "business-8"
                },
                {
                    label: "Business-16 - 3 dedicated VMs, 4 CPU, 16GB RAM, 2400GB Storage",
                    value: "business-16"
                },
                {
                    label: "Business-32 - 3 dedicated VMs, 8 CPU, 32GB RAM, 4500GB Storage",
                    value: "business-32"
                }
            ],
            premium: [
                {
                    label: "Premium-6x-8 - 6 dedicated VMs, 2 CPU, 8GB RAM, 2250GB Storage",
                    value: "premium-6x-8",
                },
                {
                    label: "Premium-6x-16 - 6 dedicated VMs, 4 CPU, 16GB RAM, 4500GB Storage",
                    value: "premium-6x-16"
                },
                {
                    label: "Premium-6x-32 - 6 dedicated VMs, 8 CPU, 32GB RAM, 9000GB Storage",
                    value: "premium-6x-32"
                },
                {
                    label: "Premium-9x-8 - 9 dedicated VMs, 2 CPU, 8GB RAM, 3375GB Storage",
                    value: "premium-9x-8"
                },
                {
                    label: "Premium-9x-16 - 9 dedicated VMs, 4 CPU, 16GB RAM, 6750GB Storage",
                    value: "premium-9x-16"
                },
                {
                    label: "Premium-9x-32 - 9 dedicated VMs, 8 CPU, 32GB RAM, 13500GB Storage",
                    value: "premium-9x-32"
                },
                {
                    label: "Premium-15x-8 - 15 dedicated VMs, 2 CPU, 8GB RAM, 5625GB Storage",
                    value: "premium-15x-8"
                },
                {
                    label: "Premium-15x-16 - 15 dedicated VMs, 4 CPU, 16GB RAM, 11250GB Storage",
                    value: "premium-15x-16"
                },
                {
                    label: "Premium-15x-32 - 15 dedicated VMs, 8 CPU, 32GB RAM, 22500GB Storage",
                    value: "premium-15x-32"
                },
                {
                    label: "Premium-30x-8 - 30 dedicated VMs, 2 CPU, 8GB RAM, 11250GB Storage",
                    value: "premium-30x-8"
                },
                {
                    label: "Premium-30x-16 - 30 dedicated VMs, 4 CPU, 16GB RAM, 22500GB Storage",
                    value: "premium-30x-16"
                },
                {
                    label: "Premium-30x-32 - 30 dedicated VMs, 8 CPU, 32GB RAM, 45000GB Storage",
                    value: "premium-30x-32"
                }
            ]
        }
    }
};

export const conditionMapping = {
    more_than: 'More than',
    if: 'IF',
    and_if: 'AND IF',
    or_if: 'OR IF',
    less_than: 'Less than',
    do: 'DO',
    and_do: 'AND DO'
};