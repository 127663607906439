import './style.scss';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from 'components/loader';
import Button from 'components/button';
import Table from 'components/table';
import RefreshButton from 'components/refreshButton';
import { apiEndpoints } from 'services/apiEndpoints';
import { parsingDate, uuidv4 } from 'services/valueConvertor';
import { useHttpRequest } from 'services/http';
import { AiOutlineInfoCircle, AiFillWarning, AiFillInfoCircle } from 'react-icons/ai';
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import useResizableColumns from 'components/table/useResizableColumns';
import SearchComponentStateless from 'components/search';

const SevirityBadge = ({ severity }) => {
    return (
        <span className={`severity-badge`}>
            {['warning', 'WARNING'].includes(severity) && (
                <>
                    <AiFillWarning className={`severity-warning`} />
                    <p className={`severity-warning`}>Warning</p>
                </>
            )}
            {['error', 'ERROR'].includes(severity) && (
                <>
                    <AiFillInfoCircle className={`severity-error`} />
                    <p className={`severity-error`}>Error</p>
                </>
            )}
            {['info', 'INFO'].includes(severity) && (
                <>
                    <AiOutlineInfoCircle className={`severity-info`} />
                    <p className={`severity-info`}>Info</p>
                </>
            )}
        </span>
    );
};

const Auditing = forwardRef(({ connection, doneRefresh, tableHeight = 'calc(100vh - 150px)' }, ref) => {
    const httpRequest = useHttpRequest();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const location = useLocation();
    const [auditingView, setAuditingView] = useState(null);
    const [connectionId, setConnectionId] = useState(null);
    const itemsPerScroll = 100;
    const [loadedItems, setLoadedItems] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    useImperativeHandle(ref, () => ({
        handleRefresh() {
            getAuditLogs(connectionId);
        }
    }));

    const columns = [
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            render: (severity) => <SevirityBadge severity={severity} />,
            sorter: (a, b) => a.severity?.localeCompare(b.severity),
            width: 120
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            sorter: (a, b) => a.severity?.localeCompare(b.severity),
            width: 150
        },
        {
            title: 'Timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp) => parsingDate(timestamp, true, true),
            sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
            width: 200
        },
        {
            title: (
                <div className="download-title">
                    <span>Message</span>
                    {dataSource?.length !== 0 && auditingView !== 'system' && <DownloadIcon style={{ color: 'var(--primary-color)' }} />}
                </div>
            ),
            dataIndex: 'message',
            key: 'message',
            render: (messagete) => messagete,
            width: 600
        }
    ];

    useEffect(() => {
        const pathTokens = decodeURI(location.pathname)?.split('/');
        const connectionId = pathTokens[pathTokens?.length - 1] === 'auditing' ? 0 : pathTokens[pathTokens?.length - 1];
        setAuditingView(connectionId === 0 ? 'system' : 'cluster');
        getAuditLogs(connectionId);
        setConnectionId(connectionId);
    }, [connection, location.pathname]);

    const getAuditLogs = async (connectionId) => {
        setIsLoading(true);
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_AUDIT_LOGS, {}, {}, { connection_id: connectionId });
            const logs = (data?.audit_logs?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) || []).map((log) => ({ ...log, key: uuidv4() }));
            setDataSource(logs);
            setInitialData(logs);
            setLoadedItems(logs.slice(0, itemsPerScroll));
        } catch (error) {
        } finally {
            setIsLoading(false);
            setIsPageLoading(false);
            connectionId && doneRefresh();
        }
    };

    const downloadLogs = async () => {
        try {
            const url = window.URL.createObjectURL(
                new Blob([dataSource?.map((log) => `${log.severity}\t\t${parsingDate(log.timestamp, true, true)} - ${log.message}`).join('\n')])
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `logs.log`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {}
    };

    const { components, resizableColumns } = useResizableColumns(columns);

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollTop + clientHeight === scrollHeight) {
            const nextLoadedItems = dataSource?.slice(0, loadedItems.length + itemsPerScroll);
            setLoadedItems(nextLoadedItems);
        }
    };

    const handleFilters = (searchValue = '') => {
        let filteredData = JSON.parse(JSON.stringify(initialData));

        if (searchValue !== '') {
            filteredData = filteredData.filter((item) => {
                return item?.message.toLowerCase().includes(searchValue?.toLowerCase());
            });
        }
        setDataSource(filteredData);
        setLoadedItems(filteredData.slice(0, itemsPerScroll));
    };

    const handleSearch = (searchValue) => {
        handleFilters(searchValue);
    };

    return isPageLoading ? (
        <Loader isFullHeight={true} background={false} />
    ) : (
        <div className={auditingView === 'system' ? 'auditing management-layout-container' : 'auditing'}>
            {auditingView === 'system' && (
                <div className="auditing-top">
                    <span className="auditing-top-left">
                        <h1>Auditing</h1>
                    </span>
                    <span className="auditing-top-right">
                        <SearchComponentStateless
                            placeholder="Search"
                            onChange={(value) => handleSearch(value)}
                            value={searchValue}
                            setSearchValue={setSearchValue}
                            style={{ border: '1px solid #E0E0E0' }}
                        />
                        <Button
                            onClick={() => downloadLogs()}
                            typeOfButton="text"
                            placeholder={
                                <div className="add-connector-placeholder-bottom-button">
                                    <DownloadIcon color="var(--color-white)" height={'16px'} />
                                    <p>Download</p>
                                </div>
                            }
                            disabled={dataSource?.length === 0}
                        />
                        <RefreshButton onClick={() => getAuditLogs()} isLoading={isLoading} />
                    </span>
                </div>
            )}
            {!isPageLoading && (
                <div className="auditing-body">
                    <Table
                        components={components}
                        columns={resizableColumns}
                        dataSource={loadedItems}
                        pagination={false}
                        bordered={false}
                        showSorterTooltip={false}
                        rowHoverBgColor="var(--table-row-even) !important"
                        scroll={{ x: 1000, y: tableHeight }}
                        onScroll={handleScroll}
                    />
                </div>
            )}
        </div>
    );
});

export default Auditing;
