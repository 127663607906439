import './style.scss';
import React, { useEffect, useState } from 'react';
import Button from 'components/button';
import { parsingDate } from 'services/valueConvertor';
import StatusBadge from './components/statusBadge';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { ReactComponent as DeleteIcon } from 'assets/images/jobs/delete.svg';
import CustomButtonWrapper from 'components/buttonsWrapper/CustomButtonWrapper';
import { useHttpRequest } from 'services/http';
import { apiEndpoints } from 'services/apiEndpoints';
import Loader from 'components/loader';
import RefreshButton from 'components/refreshButton';
import Tabs from 'components/tabs';
import StatusChart from './components/status';
import Filter from './components/filter';
import EmptyPlaceholder from './components/emptyPlaceholder';
import ConfirmationModal from './components/modals/confirmationModal';
import FixallConfirmationModal from '../../components/todoList/components/confirmationModal';
import Popover from 'components/popover';
import { PiDotsThreeOutline } from 'react-icons/pi';
import { ReactComponent as CancelIcon } from 'assets/images/cancelIcon.svg';
import { ReactComponent as RedoIcon } from 'assets/images/jobs/redoIcon.svg';
import { notification, Spin } from 'antd';
import Table from 'components/table';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { getIcon } from 'services/genericServices';
import debounce from 'lodash.debounce';
import FixNowButton from 'components/fixNowButton/fixNowButton';
import FixNow from 'components/fixNowButton';
import SearchComponentStateless from 'components/search';
import ToggleButton from './components/toggleButton';
import Notification from 'components/notifications';

const AsyncJobs = () => {
    const httpRequest = useHttpRequest();
    const [isLoading, setIsLoading] = useState(true);
    const [confirmationModalType, setConfirmationModalType] = useState('');
    const [loadingActionButton, setLoadingActionButton] = useState({});
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [currentJob, setCurrentJob] = useState(null);
    const [activeTab, setActiveTab] = useState('General');
    const [selectedFilters, setSelectedFilters] = useState({
        tagOptions: [],
        fixable: [],
        groupTypes: [],
        connection_ids: []
    });
    const [filterState, setFilterState] = useState({});
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [tabItems, setTabItems] = useState([
        { title: 'General', count: 0 },
        { title: 'Marked as fixed', count: 0 },
        { title: 'Ignored', count: 0 }
    ]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [expandedRowData, setExpandedRowData] = useState({});
    const [loadingRows, setLoadingRows] = useState({});
    const [visibleTasks, setVisibleTasks] = useState({});
    const [refreshButtonLoading, setRefreshButtonLoading] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isIntervalPaused, setIsIntervalPaused] = useState(false);
    const ITEMS_PER_LOAD = 50;
    const [toggleAllRows, setToggleAllRows] = useState(false);
    const [selectedSubTableItems, setSelectedSubTableItems] = useState([]);
    const [allSubTableItemsSelected, setAllSubTableItemsSelected] = useState(false);
    const [bulkButtonLoading, setBulkButtonLoading] = useState(false);
    const [isSelectedItemsTheSameType, setIsSelectedItemsTheSameType] = useState(false);
    const [confirmFixallModal, setconfirmFixallModal] = useState(false);

    const handleClearSelection = () => {
        setSelectedSubTableItems([]);
        setAllSubTableItemsSelected(false);
    };

    useEffect(() => {
        setIsSelectedItemsTheSameType(selectedSubTableItems.length === 0 ? false : selectedSubTableItems.every((item) => item.type === selectedSubTableItems[0]?.type));
    }, [selectedSubTableItems]);

    const fixSelectedItemsPlaceholder = selectedSubTableItems.length > 0 ? `Fix selected (${selectedSubTableItems.length})` : 'Retry failed/canceled job(s)';

    const tabsMapping = {
        General: 'general',
        'Marked as fixed': 'marked_as_fixed',
        Ignored: 'ignored'
    };

    useEffect(() => {
        const totalFilters = Object.values(selectedFilters).reduce((acc, curr) => acc + curr.length, 0);
        setIsIntervalPaused(!(totalFilters === 0 && searchValue === ''));
    }, [selectedFilters, searchValue]);

    useEffect(() => {
        if (!isIntervalPaused) {
            fetchData(tabsMapping[activeTab]);
            const interval = setInterval(() => {
                handleRefresh();
            }, 30000);
            return () => clearInterval(interval);
        }
    }, [activeTab, expandedKeys, isIntervalPaused]);

    useEffect(() => {
        setSelectedSubTableItems([]);
    }, [activeTab]);

    const fetchData = debounce(async (status) => {
        let query = {};
        if (status === 'general') query = {};
        else query = { status };

        setSearchValue('');

        try {
            const response = await httpRequest('GET', apiEndpoints.JOBS_GET_ALL, {}, {}, query);
            const data = response?.jobs;

            const sortedData = data?.jobs?.sort((a, b) => a?.created_at - b?.created_at);
            setTabItems([
                { title: 'General', count: data?.general },
                { title: 'Marked as fixed', count: data?.mark_as_fixed },
                { title: 'Ignored', count: data?.ignored }
            ]);

            setData(sortedData || []);
            setInitialData(sortedData || []);
        } catch (error) {
            setIsLoading(false);
            setIsTableLoading(false);
        } finally {
            setIsLoading(false);
            setIsTableLoading(false);
        }
    }, 300);

    const arrangeData = (data) => {
        let chartData = [];
        if (data?.num_completed_tasks > 0) {
            chartData.push({
                label: 'Completed tasks',
                bg: '#A6F4C5',
                color: '#027A48',
                value: data?.num_completed_tasks
            });
        }
        if (data?.num_failed_tasks > 0) {
            chartData.push({
                label: 'Failed tasks',
                bg: '#FECDCA',
                color: '#D92D20',
                value: data?.num_failed_tasks
            });
        }
        if (data?.num_running_tasks > 0) {
            chartData.push({
                label: 'Running tasks',
                bg: '#C3C5FC',
                color: '#4A3FE1',
                value: data?.num_running_tasks
            });
        }
        if (data?.num_queued_tasks > 0) {
            chartData.push({
                label: 'Pending tasks',
                bg: '#EAECF0',
                color: '#101828',
                value: data?.num_queued_tasks
            });
        }

        if (data?.num_canceled_tasks > 0) {
            chartData.push({
                label: 'Canceled tasks',
                bg: '#F2F4F7',
                color: '#667085',
                value: data?.num_canceled_tasks
            });
        }

        return chartData;
    };

    const handleFix = debounce(async (record, buttonKey) => {
        setLoadingActionButton((prevState) => ({ ...prevState, [buttonKey]: true }));
        try {
            await httpRequest('POST', apiEndpoints.TASKS_FIX, [
                {
                    task_id: record.id,
                    type: record?.type,
                    connection_id: record?.connection_id
                }
            ]);
            await fetchData(tabsMapping[activeTab]);
            await fetchExpandedRowData(record, true);

            setVisibleTasks((prevData) => {
                const jobTasks = prevData[record.job_id] || [];
                return {
                    ...prevData,
                    [record.job_id]: jobTasks.map((task) =>
                        task.id === record.id
                            ? {
                                  ...task,
                                  status: 'running'
                              }
                            : task
                    )
                };
            });
        } catch (error) {
        } finally {
            setLoadingActionButton((prevState) => ({ ...prevState, [buttonKey]: false }));
        }
    }, 300);

    const handleCancel = debounce(async (record, buttonKey) => {
        setLoadingActionButton((prevState) => ({ ...prevState, [buttonKey]: true }));
        try {
            await httpRequest('POST', apiEndpoints.TASKS_CANCEL, { task_id: record.id });
            await fetchData(tabsMapping[activeTab]);

            setVisibleTasks((prevData) => {
                const jobTasks = prevData[record.job_id] || [];
                return {
                    ...prevData,
                    [record.job_id]: jobTasks.map((task) =>
                        task.id === record.id
                            ? {
                                  ...task,
                                  status: 'canceled'
                              }
                            : task
                    )
                };
            });
        } catch (error) {
        } finally {
            setLoadingActionButton((prevState) => ({ ...prevState, [buttonKey]: false }));
        }
    }, 300);

    const handleCancelJob = async () => {
        try {
            await httpRequest('POST', apiEndpoints.JOB_CANCEL, { job_id: currentJob.id }, {}, {});
            fetchData(tabsMapping[activeTab]);
        } catch (error) {}
    };

    const handleDeleteJob = async () => {
        try {
            await httpRequest('POST', apiEndpoints.JOB_DELETE, { job_id: currentJob.id }, {}, {});
            await fetchData(tabsMapping[activeTab]);

            setData((prevData) => prevData.filter((job) => job.id !== currentJob.id));
            setInitialData((prevData) => prevData.filter((job) => job.id !== currentJob.id));
        } catch (error) {}
    };

    const fetchExpandedRowData = async (record, isTask = false) => {
        const id = isTask ? record.job_id : record.id;

        setLoadingRows((prev) => ({ ...prev, [id]: true }));
        try {
            const response = await httpRequest('GET', apiEndpoints.JOB_GET_BY_ID, {}, {}, { job_id: id });
            const tasks = response?.tasks?.sort((a, b) => a?.created_at - b?.created_at);

            setExpandedRowData((prev) => ({ ...prev, [id]: tasks }));
            setVisibleTasks((prev) => ({ ...prev, [id]: tasks?.slice(0, ITEMS_PER_LOAD) }));
        } catch (error) {
            setLoadingRows((prev) => ({ ...prev, [id]: false }));
        } finally {
            setLoadingRows((prev) => ({ ...prev, [id]: false }));
        }
    };

    const expandedRowRender = (parentRecord) => {
        if (loadingRows[parentRecord.key] && !expandedRowData[parentRecord.key]) {
            return (
                <div style={{ minHeight: 150, position: 'relative' }}>
                    <div style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'absolute' }}>
                        <Spin />
                    </div>
                </div>
            );
        }

        const expandedData = visibleTasks[parentRecord.key] || [];

        const handleScroll = (event) => {
            const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
            if (scrollTop + clientHeight >= scrollHeight - 700) {
                const currentTasks = visibleTasks[parentRecord.key] || [];
                const newTasks = expandedRowData[parentRecord.key]?.slice(currentTasks.length, currentTasks.length + ITEMS_PER_LOAD);

                if (newTasks?.length > 0) {
                    setVisibleTasks((prevData) => ({
                        ...prevData,
                        [parentRecord.key]: [...currentTasks, ...newTasks]
                    }));
                }
            }
        };

        const handleJobsTaskFixCompleted = async (record) => {
            await fetchData(tabsMapping[activeTab]);
            await fetchExpandedRowData(record, true);

            setVisibleTasks((prevData) => {
                const jobTasks = prevData[record.job_id] || [];
                return {
                    ...prevData,
                    [record.job_id]: jobTasks.map((task) =>
                        task.id === record.id
                            ? {
                                  ...task,
                                  status: 'running'
                              }
                            : task
                    )
                };
            });
        };

        const columns = [
            {
                title: 'Name',
                key: 'jobName',
                render: (text, record) => <span style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>{record?.data}</span>,
                width: '80%'
            },
            {
                title: 'Status',
                key: 'status',
                render: (text, record) => (
                    <div className="table-action-wrapper">
                        <StatusBadge type={record?.status} reason={record?.failed_reason} />
                    </div>
                )
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (text, record) => {
                    const status = record?.status;
                    const buttonKey = `${parentRecord.id}-${record.id}`;

                    return (
                        <div className="table-action-wrapper">
                            {status === 'queued' && (
                                <Button
                                    placeholder={<CustomButtonWrapper icon={<CancelIcon />} text="Cancel" withArrow={false} textColor={'#A75353'} />}
                                    onClick={() => handleCancel(record, buttonKey + '-cancel')}
                                    customClassName="table-action-btn handle-cancel"
                                    loading={loadingActionButton[buttonKey + '-cancel']}
                                />
                            )}
                            {status === 'failed' && (
                                <Button
                                    placeholder={<CustomButtonWrapper icon={<RedoIcon />} text="Retry" withArrow={false} textColor={'#6358FA'} />}
                                    onClick={() => handleFix(record, buttonKey + '-redo')}
                                    customClassName="table-action-btn handle-redo"
                                    loading={loadingActionButton[buttonKey + '-redo']}
                                />
                            )}
                            {status === 'canceled' && (
                                <FixNow
                                    item={record}
                                    type={'subTask'}
                                    setPending={setLoading}
                                    onComplete={(item) => handleJobsTaskFixCompleted(item)}
                                    slot={({ handleRecreateTopicsModalOpen, handleConfirmationModalOpen, handleRecreateTopicRetentionModalOpen }) => {
                                        return (
                                            <FixNowButton
                                                item={record}
                                                triggerConfirmation={handleConfirmationModalOpen}
                                                triggerRecreateTopicsModal={handleRecreateTopicsModalOpen}
                                                triggerChangeRetentionModal={handleRecreateTopicRetentionModalOpen}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </div>
                    );
                }
            }
        ];

        const handleSubTableItemSelection = (record, selected) => {
            if (['canceled', 'failed'].includes(record?.status)) {
                setSelectedSubTableItems((prevState) => (selected ? [...prevState, record] : prevState.filter((item) => item.id !== record.id)));
            }
        };

        const handleSelectAllSubTableItems = (expandedData, selected) => {
            const selectableItems = expandedData.filter((item) => ['canceled', 'failed'].includes(item?.status));
            setAllSubTableItemsSelected(selected);
            setSelectedSubTableItems(selected ? selectableItems : []);
        };

        return (
            <div onScroll={handleScroll} style={{ maxHeight: 400, overflowY: 'auto' }}>
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        onSelect: (record, selected) => handleSubTableItemSelection(record, selected),
                        onSelectAll: (selected) => handleSelectAllSubTableItems(expandedData, selected),
                        getCheckboxProps: (record) => ({
                            disabled: !['canceled', 'failed'].includes(record?.status)
                        }),
                        selectedRowKeys: selectedSubTableItems.map((item) => item.id)
                    }}
                    columns={columns.filter((column) => !(tabsMapping[activeTab] !== 'general' && ['status', 'preflight', 'actions'].includes(column.key)))}
                    dataSource={expandedData.map((task) => ({ ...task, key: task?.id }))}
                    pagination={false}
                    bordered={true}
                    className="inner-table"
                    scroll={{ x: '100%' }}
                />
            </div>
        );
    };

    const onExpand = (expanded, record) => {
        const keys = expanded ? [...expandedKeys, record.key] : expandedKeys.filter((key) => key !== record.key);
        setExpandedKeys(keys);

        if (expanded) {
            fetchExpandedRowData(record);
        }
    };

    const handleConfirmationModal = (type, job) => {
        setCurrentJob(job);
        setConfirmationModalType(type);
        setConfirmationModalIsOpen(true);
    };

    const overlayStyles = {
        borderRadius: '8px',
        width: '150px',
        padding: '8px',
        zIndex: 99999,
        background: 'red',
        position: 'relative'
    };

    const TaskActionsPopover = ({ record }) => {
        const [contextMenuOpenId, setContextMenuOpenId] = useState(false);
        const cancelled = record?.num_canceled_tasks || 0;
        const completed = record?.num_completed_tasks || 0;
        const failed = record?.num_failed_tasks || 0;
        const running = record?.num_running_tasks || 0;
        const queued = record?.num_queued_tasks || 0;

        if (cancelled + completed + failed + running + queued === 0) return;

        const isDeleteButtonEnabled = queued + running <= 0;

        const isCancelButtonEnabled = queued > 0;

        if (!isDeleteButtonEnabled && !isCancelButtonEnabled) return null;

        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Popover
                    overlayInnerStyle={overlayStyles}
                    placement={'bottom'}
                    content={
                        <>
                            {isCancelButtonEnabled && (
                                <div className="sidebar-support-popover-item popover-item" onClick={() => handleConfirmationModal('cancel', record)}>
                                    <IoCloseCircleOutline /> Cancel
                                </div>
                            )}
                            {isDeleteButtonEnabled && (
                                <div
                                    className="sidebar-support-popover-item popover-item"
                                    onClick={() => {
                                        handleConfirmationModal('delete', record);
                                    }}
                                >
                                    <DeleteIcon className="icons-sidebar" /> Delete
                                </div>
                            )}
                        </>
                    }
                    trigger="click"
                    onOpenChange={() => setContextMenuOpenId((prev) => !prev)}
                    open={contextMenuOpenId}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <PiDotsThreeOutline fontSize={20} />
                    </div>
                </Popover>
            </div>
        );
    };

    const toggleExpandedKeys = (key) => {
        setExpandedKeys((prev) => {
            const outArr = [...prev];
            if (outArr.includes(key)) {
                return outArr.filter((e) => e !== key);
            } else {
                outArr.push(key);
                return outArr;
            }
        });
    };

    const handleJobFixCompleted = async (currentJob) => {
        await fetchData(tabsMapping[activeTab]);

        setData((prevData) => prevData.filter((job) => job.id !== currentJob.id));
        setInitialData((prevData) => prevData.filter((job) => job.id !== currentJob.id));
    };

    const columns = [
        {
            title: (
                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                    <span>ID</span>
                    <ToggleButton toggleAllRows={toggleAllRows} setToggleAllRows={setToggleAllRows} />
                </div>
            ),
            key: 'id',
            render: (text, record) => (
                <div className={`custom-expandable-cell ${expandedKeys.includes(record.key) ? 'expanded-row' : ''}`}>
                    <>
                        <span className={`expand-icon ${expandedKeys.includes(record.key) ? 'expanded' : ''}`} onClick={() => toggleExpandedKeys(record.key)}>
                            {expandedKeys.includes(record.key) ? <FiMinus size={16} /> : <FiPlus size={16} />}
                        </span>
                        {record.id}
                    </>
                </div>
            ),
            sorter: (a, b) => a.id - b.id,
            width: 110
        },
        {
            title: 'Job description',
            key: 'job_title',
            render: (text, record) => record?.job_title
        },
        {
            title: 'Kafka cluster',
            key: 'cluster',
            render: (text, record) => {
                return (
                    <div className="async-jobs-cluster-title">
                        {getIcon(record?.type, false)}
                        {record.cluster_name}
                    </div>
                );
            },
            sorter: (a, b) => a.cluster_name.localeCompare(b.cluster_name)
        },
        {
            title: 'Created at',
            key: 'created_at',
            render: (text, record) => <span className={'eclipse-text'}>{parsingDate(record.created_at)}</span>,
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at)
        },
        {
            title: 'Owner',
            key: 'owner_name',
            render: (text, record) => <span className={'eclipse-text'}>{record.created_by}</span>,
            sorter: (a, b) => a.created_by.localeCompare(b.created_by)
        },
        {
            title: 'Status',
            key: 'status',
            render: (text, record) => {
                const data = arrangeData(record);
                return <StatusChart data={data} />;
            },
            className: 'status-column'
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return tabsMapping[activeTab] === 'general' ? (
                    <TaskActionsPopover record={record} />
                ) : (
                    <div className="table-action-wrapper" style={{ justifyContent: 'center', maxHeight: 20 }} onClick={(e) => e.stopPropagation()}>
                        <FixNow
                            item={record}
                            type={'job'}
                            queryParams={{ status: tabsMapping[activeTab] }}
                            setPending={setLoading}
                            onComplete={(item) => handleJobFixCompleted(item)}
                            slot={({ handleRecreateTopicsModalOpen, handleConfirmationModalOpen, handleRecreateTopicRetentionModalOpen }) => {
                                return (
                                    <FixNowButton
                                        item={record}
                                        triggerConfirmation={handleConfirmationModalOpen}
                                        triggerRecreateTopicsModal={handleRecreateTopicsModalOpen}
                                        triggerChangeRetentionModal={handleRecreateTopicRetentionModalOpen}
                                    />
                                );
                            }}
                        />
                    </div>
                );
            },
            width: 120
        }
    ];

    const handleFilters = (searchValue = '') => {
        setFilterState(selectedFilters);
        let filteredData = JSON.parse(JSON.stringify(initialData));

        if (selectedFilters?.progressStatuses) {
            filteredData = filteredData.filter((group) => {
                const statusMatches = {
                    running: group?.num_running_tasks > 0,
                    completed: group?.num_completed_tasks > 0,
                    failed: group?.num_failed_tasks > 0,
                    pending: group?.num_queued_tasks > 0,
                    cancelled: group?.num_canceled_tasks > 0,
                    queued: group?.num_queued_tasks > 0
                };

                return selectedFilters?.progressStatuses?.length === 0 || selectedFilters?.progressStatuses?.some((status) => statusMatches[status]);
            });
        }

        if (selectedFilters?.connections) {
            filteredData = filteredData.filter((group) => selectedFilters?.connections?.length === 0 || selectedFilters?.connections?.includes(group?.cluster_name));
        }

        if (searchValue !== '') {
            filteredData = filteredData.filter((item) => {
                return item?.job_title.toLowerCase().includes(searchValue?.toLowerCase());
            });
        }

        setData(filteredData);
    };

    const handleSearch = (searchValue) => {
        handleFilters(searchValue);
    };

    const resetFilters = () => {
        setSelectedFilters({
            tagOptions: [],
            fixable: [],
            groupTypes: [],
            connection_ids: []
        });
        setFilterState({});
        setSearchValue('');
        setData(initialData);
    };

    const handleRefresh = async () => {
        setRefreshButtonLoading(true);

        let query = {};
        if (tabsMapping[activeTab] === 'general') query = {};
        else query = { status: tabsMapping[activeTab] };

        try {
            const response = await httpRequest('GET', apiEndpoints.JOBS_GET_ALL, {}, {}, query);
            const newData = response?.jobs?.jobs;
            const sortedNewData = newData?.sort((a, b) => a?.created_at - b?.created_at);

            setData((prevData) => {
                return sortedNewData.map((newJob) => {
                    const existingJob = prevData.find((job) => job.id === newJob.id);
                    return existingJob ? { ...existingJob, ...newJob } : newJob;
                });
            });

            setInitialData((prevInitialData) => {
                return sortedNewData.map((newJob) => {
                    const existingJob = prevInitialData.find((job) => job.id === newJob.id);
                    return existingJob ? { ...existingJob, ...newJob } : newJob;
                });
            });

            await Promise.all(
                expandedKeys.map(async (key) => {
                    const record = sortedNewData.find((item) => item.id === key);
                    if (record) {
                        await fetchExpandedRowData(record);
                    }
                })
            );
        } catch (error) {
        } finally {
            setRefreshButtonLoading(false);
        }
    };

    useEffect(() => {
        if (toggleAllRows) {
            const allKeys = data.map((item) => item.id);
            setExpandedKeys(allKeys);

            const fetchAllRowsData = async () => {
                await Promise.all(
                    allKeys.map(async (key) => {
                        const record = data.find((item) => item.id === key);
                        if (record) {
                            await fetchExpandedRowData(record);
                        }
                    })
                );
            };

            fetchAllRowsData();
        } else {
            setExpandedKeys([]);
        }
    }, [toggleAllRows]);

    const fetchAllDataAndExpandedRows = async () => {
        await fetchData(tabsMapping[activeTab]);
        await Promise.all(
            expandedKeys.map(async (key) => {
                const record = data.find((item) => item.id === key);
                if (record) {
                    await fetchExpandedRowData(record);
                }
            })
        );
    };

    const handleBulkFix = async () => {
        const isRetryAll = selectedSubTableItems?.length === 0;
        setBulkButtonLoading(true);
        if (isRetryAll) {
            try {
                await httpRequest('POST', apiEndpoints.JOB_RETRY_ALL, {}, {}, {});
                Notification('success', 'The job(s) have been successfully triggered.');
                await fetchAllDataAndExpandedRows();
            } catch (err) {
            } finally {
                setBulkButtonLoading(false);
            }
        } else {
            try {
                const data = selectedSubTableItems.map((item) => {
                    return {
                        task_id: item.id,
                        type: item?.type,
                        connection_id: item?.connection_id
                    };
                });
                await httpRequest('POST', apiEndpoints.TASKS_FIX, data);
                await fetchAllDataAndExpandedRows();
                Notification('success', 'The job(s) have been successfully triggered.');
            } catch (err) {
            } finally {
                setBulkButtonLoading(false);
            }
        }
    };

    const handleOnFixItems = async () => {
        Notification('success', 'The job(s) have been successfully triggered.');
        await fetchAllDataAndExpandedRows();
    };

    return isLoading ? (
        <Loader isFullHeight={true} background={false} />
    ) : tabItems.reduce((sum, item) => sum + item.count, 0) === 0 ? (
        <EmptyPlaceholder />
    ) : (
        <div className="async-jobs management-layout-container">
            <div className="async-jobs-heading">
                <div className="async-jobs-heading-left">
                    <h1>Jobs</h1>
                </div>
                <div className="async-jobs-heading-right">
                    {tabsMapping[activeTab] === 'general' &&
                        <>
                            {selectedSubTableItems.length > 0 && (
                                <Button
                                    typeOfButton={'text'}
                                    placeholder={'Clear selection'}
                                    onClick={handleClearSelection}
                                    customClassName={'btn-outline btn-outline-secondary'}
                                />
                            )}

                            {!isSelectedItemsTheSameType ? (
                                <Button
                                    typeOfButton={'text'}
                                    placeholder={fixSelectedItemsPlaceholder}
                                    customClassName={'btn-outline btn-outline-primary'}
                                    onClick={() => setconfirmFixallModal(true)}
                                    loading={bulkButtonLoading}
                                />
                            ) : (
                                <FixNow
                                    item={selectedSubTableItems}
                                    type={'subTask'}
                                    setPending={() => {}}
                                    onComplete={(items) => handleOnFixItems(items)}
                                    slot={({ handleRecreateTopicsModalOpen, handleConfirmationModalOpen, handleRecreateTopicRetentionModalOpen }) => {
                                        return (
                                            <FixNowButton
                                                item={selectedSubTableItems[0]}
                                                triggerConfirmation={handleConfirmationModalOpen}
                                                triggerRecreateTopicsModal={handleRecreateTopicsModalOpen}
                                                triggerChangeRetentionModal={handleRecreateTopicRetentionModalOpen}
                                                buttonText={`Fix selected (${selectedSubTableItems?.length})`}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </>
                    }

                    <SearchComponentStateless
                        placeholder="Search"
                        onChange={(value) => handleSearch(value)}
                        value={searchValue}
                        setSearchValue={setSearchValue}
                        style={{ border: '1px solid #E0E0E0' }}
                    />

                    <Filter
                        data={initialData}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        loading={isLoading}
                        applyFilters={() => handleFilters()}
                        filterState={filterState}
                        resetFilters={() => resetFilters()}
                    />

                    <RefreshButton onClick={() => handleRefresh()} isLoading={refreshButtonLoading} />
                </div>
            </div>

            <div className="async-jobs-tabs">
                <Tabs
                    items={tabItems}
                    activeItem={activeTab}
                    handleClickItem={(item) => {
                        setActiveTab(item.title);
                        setExpandedKeys([]);
                        setIsIntervalPaused(false);
                        setIsTableLoading(true);
                    }}
                />
            </div>

            <div className="async-jobs-body">
                {initialData.length === 0 && !isTableLoading ? (
                    <div className="async-jobs-body-title">No {tabsMapping[activeTab]} jobs yet.</div>
                ) : (
                    <Table
                        columns={columns.filter((column) => !(tabsMapping[activeTab] !== 'general' && column.key === 'status'))}
                        expandable={{
                            onExpand,
                            expandedRowRender,
                            expandIconColumnIndex: -1,
                            expandedRowKeys: expandedKeys,
                            expandRowByClick: true
                        }}
                        rowClassName={(record) => (expandedKeys.includes(record.key) ? 'expanded-row' : '')}
                        dataSource={data.map((item) => ({ ...item, key: item?.id }))}
                        pagination={false}
                        bordered
                        scroll={{ x: '100%', y: 'calc(100vh - 190px)' }}
                        loading={isTableLoading}
                    />
                )}
            </div>

            <ConfirmationModal
                isModalOpen={confirmationModalIsOpen}
                setModalOpen={setConfirmationModalIsOpen}
                type={confirmationModalType}
                onCancelJob={() => handleCancelJob()}
                onDeleteJob={() => handleDeleteJob()}
                data={currentJob}
            />
            <FixallConfirmationModal openModal={confirmFixallModal} type={'fix_all'} onSubmit={handleBulkFix} closeModal={() => setconfirmFixallModal(false)} />
        </div>
    );
};

export default AsyncJobs;
