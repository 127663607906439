import './style.scss';
import { useCallback, useMemo, useState } from 'react';
import Modal from 'components/modal';
import Input from 'components/input';
import Button from 'components/button';
import { IoMdCheckboxOutline } from 'react-icons/io';

const modalContent = {
    markAsIgnored: {
        title: 'Ignore',
        desc: 'We would love to understand why you chose to ignore this task. It helps us to be more accurate for your business logic.',
        placeholder: 'I ignored this one because...'
    },
    markAsCompleted: {
        title: 'Marked as fixed',
        desc: 'We would love to understand why you chose to fix it yourself instead of using Superstream.',
        placeholder: 'I fixed it myself because...'
    },
    fix: {
        title: 'Fix now',
        desc: 'You are about to fix this task. Are you sure you want to proceed?',
        placeholder: 'Type "confirm"'
    },
    fix_all: {
        title: 'Fix all',
        desc: 'You are about to retry fixing all failed/canceled jobs. Are you sure you want to proceed?',
        placeholder: 'Type "confirm"'
    }
};

const ConfirmationModal = ({ openModal, type, onSubmit, closeModal }) => {
    const [loadingState, setLoadingState] = useState(false);
    const [reason, setReason] = useState('');

    const submitButtonDisabled = useMemo(() => {
        return type === 'fix' || type === 'fix_all' ? reason !== 'confirm' : reason < 5;
    }, [reason, type]);

    const handleSubmit = useCallback(() => {
        setLoadingState(true);
        onSubmit(reason)
            .then(() => {
                setReason('');
                closeModal();
            })
            .finally(() => setLoadingState(false));
    }, [onSubmit, reason, closeModal]);

    return (
        <Modal isModalOpen={openModal} clickOutside={closeModal}>
            <div className="todo-list-confirmation-modal">
                <div className="body">
                    <div className="icon">
                        <IoMdCheckboxOutline color="var(--primary-color)" size={20} />
                    </div>
                    <h2>{modalContent[type]?.title}</h2>
                    <p>{modalContent[type]?.desc}</p>
                    <Input
                        placeholder={modalContent[type]?.placeholder}
                        onChange={(e) => {
                            setReason(e.target.value);
                        }}
                    />
                </div>
                <div className="footer">
                    <Button customClassName="modal-btn modal-btn-secondary" placeholder="Close" onClick={closeModal} />
                    <Button
                        customClassName="modal-btn modal-btn-primary"
                        placeholder="Go"
                        onClick={handleSubmit}
                        loading={loadingState}
                        disabled={submitButtonDisabled}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
