import Button from 'components/button';
import { FiMinus, FiPlus } from "react-icons/fi";

const ToggleButton = ({ toggleAllRows, setToggleAllRows }) => {

    return (
        <Button
            typeOfButton={"text"}
            size={"large"}
            placeholder={toggleAllRows ? <FiMinus color={"#667085"} /> : <FiPlus color={"#667085"} />}
            onClick={() => setToggleAllRows(!toggleAllRows)}
            style={{display: "flex", alignItems: "center"}}
        />
    );
};

export default ToggleButton;

