import './style.scss';
import Drawer from 'components/drawer';
import Button from 'components/button';
import { IoClose } from 'react-icons/io5';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import Input from 'components/input';
import { GoPlus } from 'react-icons/go';
import { IoIosArrowDown } from 'react-icons/io';
import Popover from 'components/popover';
import {
    conditionMapping,
    conditionOptions,
    conditionTypeOptions,
    doOptions,
    instanceTypesMap,
    modeOptions,
    timeUnits
} from "./selectOptions";
import { Context } from "hooks/store";

const RuleDrawer = forwardRef(({open, setDrawerOpen, onSubmit, clusterId, currentItem, loading }, ref) => {
    const [conditions, setConditions] = useState([{type: undefined, condition: undefined, value: undefined}]);
    const [actions, setActions] = useState([{target_type: undefined, instance_types: undefined}]);
    const [conditionPopoverVisible, setConditionPopoverVisible] = useState(false);
    const [actionPopoverVisible, setActionPopoverVisible] = useState(false);
    const [instanceTypes, setInstanceTypes] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [suffixes, setSuffixes] = useState(['%']);
    const acceptablePlans = ['premium', 'business'];
    const [state, dispatch] = useContext(Context);
    const currentPlan = acceptablePlans.find(plan => state?.currentPlan?.includes(plan)) || state?.currentPlan;
    const connectionType = state?.connections.find(connection => connection.id === parseInt(clusterId))?.type;
    const [destinationPlans, setDestinationPlans] = useState(null);
    const [selectedSource, setSelectedSource] = useState(null);
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [form] = Form.useForm();

    const targetType = connectionType === 'aiven' ? 'plan_type' : 'instance_type';
    let initialValuesOfForm = {
        monitorIntervalUnit: 'hours',
        mode: 'supervised',
        'actions[0].target_type': targetType,
    };

    useEffect(() => {
        if (currentItem) {
            const { rule_name, rule } = currentItem;
            const { mode, monitor_window, source, target, thresholds } = rule;

            let monitorIntervalUnit = 'minutes';
            let monitorIntervalValue = monitor_window;

            if (monitor_window % 1440 === 0) {
                monitorIntervalUnit = 'days';
                monitorIntervalValue = monitor_window / 1440;
            } else if (monitor_window % 60 === 0) {
                monitorIntervalUnit = 'hours';
                monitorIntervalValue = monitor_window / 60;
            }

            const initialValues = {
                name: rule_name,
                mode,
                monitor_window: monitorIntervalValue,
                monitorIntervalUnit,
                'actions[0].source': source,
                'actions[0].target': target,
            };

            thresholds?.forEach((threshold, index) => {
                const [operator] = Object.keys(threshold).filter(key => key !== 'label');
                initialValues[`conditions[${index}].label`] = threshold.label;
                initialValues[`conditions[${index}].operator`] = operator;
                initialValues[`conditions[${index}].value`] = threshold[operator];
            });

            const type = connectionType === 'aiven' ? 'plan_type' : 'instance_type';
            initialValues[`actions[${0}].target_type`] = type;
            handleOnChangeInstanceTypes(type);
            form.setFieldsValue(initialValues);

            const conditions = thresholds?.map((threshold, index) => {
                const [operator] = Object.keys(threshold).filter(key => key !== 'label');
                return {
                    type: undefined,
                    condition: operator,
                    value: threshold[operator],
                    conditionType: index === 0 ? 'and_if' : 'or_if',
                };
            });
            setConditions(conditions);

            const initialSelectedConditions = thresholds?.map(threshold => threshold.label);
            setSelectedConditions(initialSelectedConditions);
            setSelectedSource(source);
        } else {
            form.resetFields();
            setConditions([{type: undefined, condition: undefined, value: undefined}]);
            setActions([{target_type: undefined, instance_types: undefined}]);
            setDestinationPlans(null);
            setSelectedSource(null);
            setSelectedConditions([]);
            setInstanceTypes([]);
            handleOnChangeInstanceTypes(targetType);
        }
    }, [currentItem, form]);

    const onFinish = async (values) => {
        try {
            const name = values.name || '';
            const source = values['actions[0].source'] || '';
            const target = values['actions[0].target'] || '';
            const operator = conditions.length > 1 ? (conditions[1]?.conditionType === 'and_if' ? 'and' : 'or') : 'and';
            const mode = values.mode || '';
            let monitorInterval = parseInt(values.monitor_window, 10) || 0;
            const monitorIntervalUnit = values.monitorIntervalUnit;

            switch (monitorIntervalUnit) {
                case 'hours':
                    monitorInterval *= 60;
                    break;
                case 'days':
                    monitorInterval *= 1440;
                    break;
                default:
                    break;
            }

            const thresholds = conditions.map((_, index) => {
                const label = values[`conditions[${index}].label`];
                const operator = values[`conditions[${index}].operator`];
                const value = parseInt(values[`conditions[${index}].value`], 10);

                if (!label || !operator || isNaN(value)) {
                    throw new Error(`Invalid condition at index ${index}`);
                }

                return {
                    label: label,
                    [operator]: value
                };
            });

            const formattedData = {
                connection_ids: [parseInt(clusterId)],
                rule_name: name,
                source,
                target,
                operator,
                mode,
                monitor_window: monitorInterval,
                thresholds
            };

            onSubmit(formattedData, currentItem ? 'update' : 'create');
        } catch (error) {}
    };

    const handleAddCondition = (conditionType) => {
        setConditions([
            ...conditions,
            {
                type: undefined,
                condition: 'lt',
                value: undefined,
                conditionType: conditionType?.toLowerCase().replace(' ', '_')
            }
        ]);
        setConditionPopoverVisible(false);
    };

    /*const handleAddAction = (actionType) => {
        form.setFieldsValue({[`actions[${actions.length}].do_type`]: actionType});
        setActions([...actions, {
            do_type: actionType,
            instance_types: undefined,
            actionType: actionType?.toLowerCase().replace(' ', '_')
        }]);
        setActionPopoverVisible(false);
    };*/

    const handleRemoveCondition = (index) => {
        const newConditions = conditions.filter((_, i) => i !== index);
        setConditions(newConditions);
    };

    /*const handleRemoveAction = (index) => {
        const newActions = actions.filter((_, i) => i !== index);
        setActions(newActions);
        setActionPopoverVisible(false);
    };*/

    const onFieldsChange = (_, allFields) => {
        const errors = form.getFieldsError();
        const allFieldsNotEmpty = allFields.every((field) => field.value);
        setIsFormValid(allFieldsNotEmpty && errors.filter(({errors}) => errors.length).length === 0);
    };

    const conditionPopoverContent = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
                style={{ display: 'flex', justifyContent: 'flex-start', padding: '3px 12px', fontWeight: 500 }}
                typeOfButton={'text'}
                placeholder="AND IF"
                onClick={() => handleAddCondition('AND IF')}
                disabled={conditions[conditions.length - 1]?.conditionType === 'or_if'}
            />
            <Button
                style={{ display: 'flex', justifyContent: 'flex-start', padding: '3px 12px', fontWeight: 500 }}
                typeOfButton={'text'}
                placeholder="OR IF"
                onClick={() => handleAddCondition('OR IF')}
                disabled={conditions[conditions.length - 1]?.conditionType === 'and_if'}
            />
        </div>
    );

    /*const actionPopoverContent = (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {/!*<Button style={{display: "flex", justifyContent: "flex-start", padding: "3px 12px", fontWeight: 500}} typeOfButton={"text"} placeholder="DO" onClick={() => handleAddAction("DO")}/>*!/}
            <Button
                style={{display: 'flex', justifyContent: 'flex-start', padding: '3px 12px', fontWeight: 500}}
                typeOfButton={'text'}
                placeholder="AND DO"
                onClick={() => handleAddAction('AND DO')}
            />
        </div>
    );*/

    const popoverStyles = {
        borderRadius: '8px',
        width: '150px'
    };

    const handleOnChangeInstanceTypes = (value) => {
        if (!value) {
            setInstanceTypes([]);
            return;
        }

        const allPlans = [...instanceTypesMap['aiven']['plan_type']['business'], ...instanceTypesMap['aiven']['plan_type']['premium']];

        if (connectionType === 'aiven') {
            setInstanceTypes(!acceptablePlans.includes(currentPlan) ? allPlans : instanceTypesMap[connectionType]['plan_type'][currentPlan]);
        } else {
            const instanceTypes = instanceTypesMap[connectionType][value];
            setInstanceTypes(instanceTypes);
        }
    };

    const getPlanTypeKey = (value) => {
        for (const planTypeKey in instanceTypesMap.aiven.plan_type) {
            const plans = instanceTypesMap.aiven.plan_type[planTypeKey];
            const matchedPlan = plans.find(plan => plan.value === value);
            if (matchedPlan) {
                return planTypeKey;
            }
        }
        return undefined;
    };

    const handleOnChangeSourcePlans = (value) => {
        setSelectedSource(value);

        if (!value) {
            return setDestinationPlans(null);
        }

        form.setFieldsValue({
            [`actions[0].target`]: undefined
        });

        if (connectionType === 'msk') {
            const instanceTypes = instanceTypesMap[connectionType]['instance_type'];
            setDestinationPlans(instanceTypes);
            return;
        }
        const plans = instanceTypesMap[connectionType]['plan_type'][getPlanTypeKey(value)];
        setDestinationPlans(plans);
    };

    const handleChangeConditionTypeOptions = (value, index) => {
        const conditionType = conditionTypeOptions[connectionType].find((option) => option.value === value)?.unit;
        const unitMapping = {
            percent: '%',
            'MiB/s': 'MiB/s',
            number: '',
            ms: 'ms',
        };

        const newSuffixes = [...suffixes];
        newSuffixes[index] = unitMapping[conditionType] || '';
        setSuffixes(newSuffixes);

        const newSelectedConditions = [...selectedConditions];
        newSelectedConditions[index] = value;
        setSelectedConditions(newSelectedConditions);
    };

    const getConditionOptionsWithDisabled = (index) => {
        return conditionTypeOptions[connectionType].map(option => ({
            ...option,
            disabled: selectedConditions.includes(option.value) && selectedConditions[index] !== option.value
        }));
    };

    const getTargetExistingPlanSelectFormat = (option) => {
        return (
            <div className="targetExistingPlanSelectFormat">
                <span>{option?.label}</span>
                <span className="badge">Existing Type</span>
            </div>
        );
    }

    const getTargetOptions = (type) => {
        const source = type === 'source' ? instanceTypes : (destinationPlans || instanceTypes);
        return source?.map(option => ({
            label: option.label,
            value: option.value,
            disabled: type === 'destination' && option.value === selectedSource
        }));
    };

    const onCloseDrawer = () => {
        form.resetFields();
        setConditions([{type: undefined, condition: undefined, value: undefined}]);
        setActions([{target_type: undefined, instance_types: undefined}]);
        setDrawerOpen(false);
    }

    return (
        <Drawer
            open={open}
            destroyOnClose={true}
            placement="bottom"
            onClose={() => setDrawerOpen(false)}
            className="rule-drawer-container"
            getContainer={'#main-layout-body'}
            closeIcon={null}
            rootStyle={{position: 'absolute'}}
            mask={false}
            styles={{wrapper: {boxShadow: 'none', background: '#fff'}}}
            height={'400px'}
        >
            <div className="rule-drawer">
                <div className="top">
                    <div className="rule-drawer-title">Add New Rule</div>
                    <div className="rule-drawer-actions">
                        <Button
                            typeOfButton={''}
                            placeholder={<IoClose size={16} color={'#475467'}/>}
                            onClick={() => onCloseDrawer()}
                            style={{padding: '10px 7px'}}
                            disabled={loading}
                        />
                        <Button
                            typeOfButton="primary"
                            placeholder={'Save'}
                            onClick={() => form.submit()}
                            loading={loading}
                            //disabled={!isFormValid}
                        />
                    </div>
                </div>
                <Spin delay={500} spinning={loading}>
                    <div className="main">
                        <Form
                            className="auto-scaler-rule-form"
                            name="auto-scaler-rule-form"
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}
                            onFieldsChange={onFieldsChange}
                            layout="vertical"
                            initialValues={initialValuesOfForm}
                        >
                            <div className="header">
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter rule name!'
                                        }
                                    ]}
                                    label={'Rule name'}
                                    layout="horizontal"
                                    style={{marginBottom: 10}}
                                >
                                    <Input
                                        type={'text'}
                                        placeholder="Enter rule name"
                                        disabled={currentItem}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="mode"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select the mode!'
                                        }
                                    ]}
                                    label={'Mode'}
                                    style={{marginBottom: 10}}
                                >
                                    <Select
                                        suffixIcon={<IoIosArrowDown size={18} color={'#475467'}/>}
                                        placeholder="Select mode"
                                        options={modeOptions}
                                        optionLabelProp={'value'}
                                        className={'capitalize'}
                                        allowClear={true}
                                    />
                                </Form.Item>
                            </div>

                            <div className="footer">
                                {conditions.map((condition, index) => (
                                    <div className="rule-container" key={index}>
                                        <div className="left">
                                            <div
                                                className={'rule-container-label'}>{index === 0 ? 'IF' : (conditionMapping[condition.conditionType] || <span style={{textTransform: "uppercase"}}>{currentItem?.rule?.operator}</span> ) }</div>
                                            <Form.Item
                                                name={`conditions[${index}].label`}
                                                /*rules={[{ required: true, message: 'Please select the condition!' }]}*/
                                            >
                                                <Select
                                                    className={"custom-select"}
                                                    suffixIcon={<IoIosArrowDown size={18} color={'#475467'} />}
                                                    placeholder="Select condition"
                                                    options={getConditionOptionsWithDisabled(index)}
                                                    allowClear={true}
                                                    onChange={(value) => handleChangeConditionTypeOptions(value, index)}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="right">
                                            <div className="rule-container-right-inputs">
                                                <Form.Item name={`conditions[${index}].operator`} rules={[{
                                                    required: true,
                                                    message: 'Please select the condition!'
                                                }]}>
                                                    <Select
                                                        suffixIcon={<IoIosArrowDown size={18} color={'#475467'}/>}
                                                        options={conditionOptions}
                                                        placeholder={'Select condition unit'}
                                                        allowClear={true}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={`conditions[${index}].value`}
                                                >
                                                    <Input
                                                        type={'number'}
                                                        placeholder="Enter value"
                                                        suffix={suffixes[index]}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="rule-container-right-buttons">
                                                {index > 0 ? (
                                                    <Button placeholder={'Delete Condition'}
                                                            onClick={() => handleRemoveCondition(index)}/>
                                                ) : (
                                                    <Popover
                                                        content={conditionPopoverContent}
                                                        trigger="click"
                                                        overlayInnerStyle={popoverStyles}
                                                        open={conditionPopoverVisible}
                                                        onOpenChange={() => setConditionPopoverVisible(!conditionPopoverVisible)}
                                                    >
                                                        <Button
                                                            placeholder={
                                                                <div
                                                                    style={{display: 'flex', alignItems: 'center', gap: 5}}>
                                                                    <GoPlus/>
                                                                    Add Condition
                                                                </div>
                                                            }
                                                        />
                                                    </Popover>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="rule-container">
                                    <div className="left">
                                        <div className="rule-container-label">More than</div>
                                        <Form.Item
                                            name="monitor_window"
                                            rules={[
                                                {
                                                    required: true,
                                                    validator: (_, value) => {
                                                        if (value <= 0) {
                                                            return Promise.reject('Please enter valid value!');
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                type="number"
                                                placeholder={'Enter time window'}
                                                addonAfter={
                                                    <Form.Item name="monitorIntervalUnit" noStyle>
                                                        <Select
                                                            suffixIcon={<IoIosArrowDown size={18} color={'#475467'}/>}
                                                            options={timeUnits}
                                                        />
                                                    </Form.Item>
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="right"></div>
                                </div>

                                <div className="footer">
                                    {actions.map((action, index) => (
                                        <div className="rule-container" key={index}>
                                            <div className="left1" style={{display: "flex", width: "1000%", gap: 12}}>
                                                <div className="rule-container-label">{index === 0 ? 'DO' : conditionMapping[action.actionType]}</div>
                                                <div className="rule-container-select-wrapper">
                                                    <Form.Item name={`actions[${index}].target_type`}>
                                                        <Select
                                                            className={"custom-select"}
                                                            suffixIcon={<IoIosArrowDown size={18} color={'#475467'}/>}
                                                            placeholder="Select action"
                                                            options={doOptions[connectionType]}
                                                            onChange={(value) => handleOnChangeInstanceTypes(value)}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={`actions[${index}].source`}
                                                        label={"From"}
                                                    >
                                                        <Select
                                                            className={"custom-select"}
                                                            suffixIcon={<IoIosArrowDown size={18} color={'#475467'}/>}
                                                            placeholder={'Select instance type from'}
                                                            options={getTargetOptions('source')}
                                                            disabled={instanceTypes.length === 0}
                                                            onChange={(value) => handleOnChangeSourcePlans(value)}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={`actions[${index}].target`}
                                                        label={"To"}
                                                    >
                                                        <Select
                                                            className={"custom-select"}
                                                            suffixIcon={<IoIosArrowDown size={18} color={'#475467'}/>}
                                                            placeholder={'Select instance type to'}
                                                            options={getTargetOptions('destination')}
                                                            disabled={instanceTypes.length === 0}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="rule-container-right-inputs">

                                                </div>
                                                {/*<div className="rule-container-right-buttons">
                                                    {index > 0 ? (
                                                        <Button placeholder={'Delete Action'} onClick={() => handleRemoveAction(index)} />
                                                    ) : (
                                                        <Button
                                                            onClick={() => handleAddAction('AND DO')}
                                                            placeholder={
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: 5
                                                                    }}
                                                                >
                                                                    <GoPlus />
                                                                    Add Action
                                                                </div>
                                                            }
                                                        />
                                                    )}
                                                </div>*/}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Form>
                    </div>
                </Spin>
            </div>
        </Drawer>
    );
});

export default RuleDrawer;
