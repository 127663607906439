import "./style.scss";
import {Popover as AntPopover} from "antd";

const Popover = (
    {
        children,
        overlayInnerStyle,
        placement,
        content,
        trigger="click",
        onOpenChange,
        open,
        ...props
    }
) => {

    return (
        <AntPopover
            overlayInnerStyle={overlayInnerStyle}
            placement={placement}
            content={content}
            trigger={trigger}
            onOpenChange={onOpenChange}
            open={open}
            {...props}
        >
            {children}
        </AntPopover>
    );
}

export default Popover;
