export const apiEndpoints = {
    //Connections
    CREATE_CONNECTION: '/connections/createConnection',
    GET_ALL_CONNECTIONS: '/connections/getAllConnections',
    UPDATE_CONNECTION: '/connections/updateConnection',
    DELETE_CONNECTION: '/connections/deleteConnection',
    GET_CONNECTIONS_BY_ID: '/connections/getById',
    GET_AGGREGATED_SUMMARY: '/connections/getAggregatedSummary',
    GET_CONNECTION_SETTINGS: '/connections/getConnectionSettings',
    SET_CONNECTION_SETTINGS: '/connections/setConnectionSettings',
    GET_ACHIVEMENTS: '/connections/getAchivements',

    //Environments
    GET_ALL_ENVIRONMENTS: '/environments/getAllEngines',

    //GRAPH
    GET_GRAPH_DATA: '/environments/getGraphData',
    GET_ORPHAN_CLIENTS: '/clients/getOrphanClients',

    //AUTH
    SIGN_IN: '/auth/signin',
    RESET_PASSWORD: '/auth/reset-password',
    CHECK_USER: '/auth/checkUser',

    //Auditing
    GET_AUDIT_LOGS: '/audit/getAllAudit',
    GET_AUDIT_AUTO_SCALER: '/audit/getAllAutoscalerAudit',

    //Tasks
    TASKS_CREATE_GROUP: '/tasks/createTasksGroup',
    TASKS_START_GROUP: '/tasks/startGroup',
    TASKS_CANCEL_GROUP: '/tasks/cancelGroup',
    TASKS_START: '/tasks/startTask',
    TASKS_CANCEL: '/tasks/cancel',
    TASKS_FIX_BY_SSM: '/tasks/fixTasksByJobID',

    TASKS_GET_ALL_GROUPS: '/tasks/getAllGroups',
    TASKS_GET_BY_GROUP: '/tasks/getTasksByGroup',
    TASKS_GET_BY_ID: '/tasks/getTaskById',
    TASKS_FIX: '/tasks/fix',
    TASKS_IGNORE: '/tasks/ignore',
    TASKS_MARK_AS_COMPLETE: '/tasks/markAsCompleted',

    //Clients
    CLIENTS_TOGGLE_REDUCTION: '/clients/toggleClientReduction',
    CLIENTS_TOGGLE_COMPRESSION: '/clients/switchCompression',
    CLIENTS_GET_ALL: '/clients/getAllClients',

    //Keys
    GET_ALL_KEYS: '/keys/getAllKeys',
    CREATE_KEY: '/keys/createKey',
    UPDATE_KEY: '/keys/updateKey',
    DELETE_KEY: '/keys/deleteKey',

    //Jobs
    JOBS_GET_ALL: '/jobs/getAllJobs',
    JOB_GET_BY_ID: '/tasks/getTasksByJobId',
    JOB_CANCEL: '/jobs/cancel',
    JOB_DELETE: '/jobs/delete',
    JOB_RETRY_ALL: '/jobs/retryAll',

    //AutoScaler
    AUTO_SCALER_RULE_REMOVE: '/connections/removeAutoScalerRules',
    AUTO_SCALER_RULE_EDIT: '/connections/editAutoScalerRule',
    AUTO_SCALER_RULES_GET: '/connections/getAutoScalerRules',
    AUTO_SCALER_RULES_SET: '/connections/setAutoScalerRule',
    GET_CURRENT_PLAN: '/connections/getCurrentPlan',
    AUTO_SCALER_PROGRESS: '/connections/getSwitchPlanProgress',

    //Notifications
    NOTIFICATIONS_GET_ALL: '/notifications/getAllNotificationsSettings',
    NOTIFICATION_UPDATE: '/notifications/updateNotificationSettings',
};
