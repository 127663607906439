import './style.scss';
import Chart from 'components/optimizationCharts/chart';
import { useEffect, useState, useMemo } from 'react';
import { uuidv4 } from '../../services/valueConvertor';
import { FiInfo } from 'react-icons/fi';
import TooltipComponent from 'components/tooltip/tooltip';

const typeLabel = {
    storage: 'Storage',
    performance: 'CPU',
    compute: 'Memory',
    read: 'Reads',
    write: 'Writes',
    partitions: 'Partitions'
};

const OptimizationCharts = ({ data, reportStatus, showTitle = false }) => {
    const [conditionalData, setConditionalData] = useState([]);

    useEffect(() => {
        const parsedData = data?.map((chart) => {
            if (chart?.data?.length === 0) {
                return { ...chart, data: generateMockGraphData(12), isMockData: true };
            }
            return {...chart, isMockData: false};
        })
        setConditionalData(parsedData);
    }, [data]);


    const sortedData = useMemo(() => {
        return conditionalData?.sort((a, b) => {
            const order = ['storage', 'partitions', 'write', 'read'];
            return order.indexOf(a?.type) - order.indexOf(b?.type);
        });
    }, [conditionalData]);

    const generateMockGraphData = (days) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const mockGraphData = [];

        for (let i = 0; i < days; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);

            const superstream = Math.floor(Math.random() * 990000000);
            const withoutSuperstream = Math.floor(Math.random() * 990000000 * 3.5);

            mockGraphData.push({
                date: date.getTime(),
                superstream: superstream,
                without_superstream: withoutSuperstream
            });
        }

        return mockGraphData;
    };

    function formatTimeDifference(dateString) {
        const givenDate = new Date(dateString);
        const currentDate = new Date();
        const timeDifference = currentDate - givenDate;

        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        if (daysDifference < 30) {
            return `${daysDifference} days ago`;
        }

        const monthsDifference = Math.floor(daysDifference / 30);
        if (daysDifference < 366) {
            return `${monthsDifference} months ago`;
        }

        const yearsDifference = Math.floor(daysDifference / 365);
        return `${yearsDifference} years ago`;
    }

    return (
        <div className="optimization-charts-container">
            <div className="optimization-charts">
                {sortedData?.map((chart, index) => {
                    return (
                        <div className="optimization-chart-wrapper" key={index}>
                            {chart?.isMockData && <p className="loading">Calculating...</p>}
                            <div className={'optimization-chart'}>
                                <span className="calculating-section">
                                    <div className={'optimization-chart-header'}>
                                        <p style={{ display: 'flex', gap: 3 }}>
                                            <span style={{ color: '#564AFA' }}>Current</span> vs
                                            <span style={{ color: '#37D2BC' }}>Potential</span>
                                            <b>{typeLabel[chart?.type]}</b>
                                            Status
                                            {(chart?.type === 'partitions' || chart?.type === 'storage') && (
                                                <TooltipComponent text="This calculation considers the replication factor." className={'info-icon'}>
                                                    <FiInfo />
                                                </TooltipComponent>
                                            )}
                                        </p>
                                    </div>
                                </span>
                                <div className={chart?.isMockData ? 'placeholder' : ''}>
                                    <Chart
                                        key={uuidv4() + index}
                                        data={chart?.data}
                                        isDataAvailable={true}
                                        type={chart?.type}
                                        showLegend={false}
                                        startDate={chart?.start_date}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default OptimizationCharts;
