import Modal from "components/modal";
import Button from "components/button";
import { useState } from "react";

const ConfirmationModal = ({rule_name, isModalOpen, setModalOpen, onDelete }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = () => {
        setIsLoading(true);
        onDelete().finally(() => {setIsLoading(false); setModalOpen(false)});
    };

    return (
        <Modal
            clickOutside={() => setModalOpen(false)}
            isModalOpen={isModalOpen}
            className={"confirmation-modal confirmation-rule-modal"}
            closeIcon={true}
            width={480}
        >
            <div className="top">
                <div className="title" style={{fontSize: 16}}>Are you sure you want to delete <b>{rule_name}</b> rule?</div>
                <div className="close">
                </div>
            </div>
            <div className="middle">
                <div className="content"></div>
            </div>
            <div className="bottom">
                <div className="right">
                </div>
                <div className="left">
                    <Button
                        placeholder={"No, go back"}
                        onClick={() => setModalOpen(false)}
                        customClassName={"btn btn-sp-secondary"}
                    />
                    <Button
                        placeholder={"Delete"}
                        onClick={() => handleDelete()}
                        customClassName={"btn btn-sp-primary"}
                        loading={isLoading}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
