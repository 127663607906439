import "./style.scss";
import Modal from "components/modal";
import Button from "components/button";

const content = {
    cancel: {
        title: "Are you sure you want to cancel this job?",
        content: (
            <>
                In case you confirm the cancellation, you will have to re-select the topics you want to delete.
            </>
        ),
        btnText: "Yes, cancel"
    },
    delete: {
        title: "Are you sure you want to delete this job?",
        content: (
            <>
                In case you confirm the deletion, you will have to re-select the topics you want to delete.
            </>
        ),
        btnText: "Yes, delete"
    }
}

const ConfirmationModal = ({data, isModalOpen, setModalOpen, type = 'cancel', onCancelJob, onDeleteJob }) => {

    if (!data) return;

    const statusesCancel = {
        num_queued_tasks: 'Queued: ',
    }

    const statusesDelete = {
        num_canceled_tasks: 'Cancelled: ',
        num_completed_tasks: 'Completed: ',
        num_failed_tasks: 'Failed: ',
    }

    const statuses = type === 'cancel' ? statusesCancel : statusesDelete;

    return (
        <Modal
            clickOutside={() => setModalOpen(false)}
            isModalOpen={isModalOpen}
            className={"confirmation-modal"}
            closeIcon={true}
            width={480}
        >
            <div className="top">
                <div className="title">{content[type]?.title}</div>
                <div className="close">
                </div>
            </div>
            <div className="middle">
                <div className="content">
                    {content[type]?.content}
                </div>
                <div className="content">
                    <br/>
                    Following tasks will be affected:
                    {
                        <ul style={{paddingLeft: 16}}>
                            {Object.keys(statuses).map(key => (
                                data.hasOwnProperty(key) && data[key] !== 0 ? (
                                    <li key={key}>
                                        {statuses[key]} {data[key]}
                                    </li>
                                ) : null
                            ))}
                        </ul>
                    }
                </div>
            </div>
            <div className="bottom">
                <div className="right">
                    {/* <Button
                        typeOfButton={"text"}
                        placeholder={
                            <div style={{ display: "flex", alignItems: "center", padding: 4, gap: 8 }}>
                                <HelpIcon size={18} color={"#475467"} />
                                Get help
                            </div>
                        }
                        onClick={() => {}}
                        customClassName={"btn-sp-help"}
                    /> */}
                </div>
                <div className="left">
                    <Button
                        placeholder={"No, go back"}
                        onClick={() => setModalOpen(false)}
                        customClassName={"btn btn-sp-secondary"}
                    />
                    <Button
                        placeholder={content[type]?.btnText}
                        onClick={() => {
                            if (type === 'cancel') {
                                onCancelJob();
                            } else if (type === 'delete') {
                                onDeleteJob();
                            }
                            setModalOpen(false);
                        }}
                        customClassName={"btn btn-sp-primary"}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
