import './style.scss';

import React, { useEffect, useState } from 'react';
import Button from 'components/button';
import Input from 'components/input';

const DeleteItemsModal = ({ title, desc, handleDeleteSelected, buttontxt, textToConfirm, loader = false, disableInputConfirmation = false }) => {
    const [confirm, setConfirm] = useState('');

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter' && confirm === (textToConfirm || 'delete')) {
                handleDeleteSelected();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [confirm]);

    return (
        <div className="delete-modal-wrapper">
            <p className="title">{title}</p>
            <p className="desc">{desc}</p>
            {!disableInputConfirmation && (
                <div className="confirm-section">
                    <p>
                        Please type <b>{textToConfirm || 'delete'}</b> to confirm.
                    </p>
                    <Input
                        placeholder={textToConfirm || 'delete'}
                        autoFocus={true}
                        type="text"
                        radiusType="semi-round"
                        colorType="black"
                        backgroundColorType="none"
                        borderColorType="gray-light"
                        height="48px"
                        onBlur={(e) => setConfirm(e.target.value)}
                        onChange={(e) => setConfirm(e.target.value)}
                        value={confirm}
                    />
                </div>
            )}

            <div className="buttons">
                <Button
                    width="100%"
                    height="34px"
                    placeholder={buttontxt}
                    colorType="white"
                    radiusType="circle"
                    backgroundColorType="purple"
                    fontSize="12px"
                    fontFamily="Inter"
                    disabled={(confirm !== (textToConfirm || 'delete') || loader) && !disableInputConfirmation}
                    loading={loader}
                    onClick={() => handleDeleteSelected()}
                    customClassName="btn btn-primary"
                />
            </div>
        </div>
    );
};

export default DeleteItemsModal;
