import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Heading from 'components/heading';
import SocialLogin from 'components/socialLogin';
import Divider from 'components/divider';
import Input from 'components/input';
import Button from 'components/button';
import { ReactComponent as GoogleIcon } from 'assets/images/google.svg';
import { RxLockClosed } from 'react-icons/rx';
import { Checkbox, Form } from 'antd';
import FormServerMessage from 'components/formServerMessage';
import { useHttpRequest } from 'services/http';
import axios from 'axios';
import errorMessages from 'utils/firebaseMessages';
import { apiEndpoints } from 'services/apiEndpoints';
import {
    SESSION_STORAGE_ACCOUNT_DATA,
    SESSION_STORAGE_ACCOUNT_ID,
    SESSION_STORAGE_ACTIVATION_TOKEN,
    SESSION_STORAGE_ERROR_MESSAGE,
    SESSION_STORAGE_JWT,
    SESSION_STORAGE_LOGIN_PROVIDER,
    SESSION_STORAGE_USER,
    SESSION_STORAGE_USER_NAME,
    SESSION_STORAGE_USER_UNDER_ASSESSMENT
} from 'utils/localStorageConsts';
import router from 'router';
import { Context } from 'hooks/store';
import { auth } from 'services/firebase';
import {
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    setPersistence,
    browserSessionPersistence,
    browserLocalPersistence,
    signInWithPopup,
    createUserWithEmailAndPassword
} from 'firebase/auth';
import { useAuth0 } from '@auth0/auth0-react';

const SignInForm = () => {
    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [, dispatch] = useContext(Context);
    const [errorMessage, setErrorMessage] = useState(sessionStorage.getItem(SESSION_STORAGE_ERROR_MESSAGE) || '');
    const [isFormValid, setIsFormValid] = useState(false);
    const [form] = Form.useForm();
    const location = useLocation();
    const [step, setStep] = useState(1);
    const [currentForm, setCurrentForm] = useState('initial');
    const [rememberMe, setRememberMe] = useState(false);
    const httpRequest = useHttpRequest();

    const formDetails = {
        initial: {
            title: 'Hi there!',
            socialLoginTitle: 'Continue with Google',
            buttonText: 'Continue'
        },
        signIn: {
            title: 'Welcome back!',
            socialLoginTitle: 'Sign in with Google',
            buttonText: 'Sign In'
        },
        signUp: {
            title: 'Setup your account!',
            socialLoginTitle: 'Sign up with Google',
            buttonText: 'Sign Up'
        }
    };

    const getQueryParam = (paramName) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(paramName);
    };

    useEffect(() => {
        const errorMessage = getQueryParam('errorMessage');
        if (errorMessage) {
            setErrorMessage(errorMessage);
        }
    }, [location]);

    const onFinish = async (values) => {
        const { email, password, user_name } = values;
        setLoading(true);

        if (process.env.REACT_APP_ENVIRONMENT !== 'onprem') {
            if (rememberMe) {
                await setPersistence(auth, browserLocalPersistence);
            } else {
                await setPersistence(auth, browserSessionPersistence);
            }
        }

        if (step === 1) {
            await httpRequest('POST', apiEndpoints.CHECK_USER, process.env.REACT_APP_ENVIRONMENT !== 'onprem' ? { user_name: email } : { user_name })
                .then((res) => {
                    if (res.status === 200) setCurrentForm('signIn');
                    if (res.status === 201) setCurrentForm('signUp');
                    setStep(2);
                    setErrorMessage('');
                })
                .catch(async (error) => {
                    if (error?.status === parseInt(process.env.REACT_APP_AUTHENTICATION_ERROR_STATUS_CODE)) {
                        setErrorMessage('Unfortunately, we were unable to locate your account in our system!');
                    } else if (error.status === parseInt(process.env.REACT_APP_AUTO_SERVER_STATUS_CODE)) {
                        setErrorMessage(error.data.message);
                    } else {
                        setErrorMessage('Server error, please contact with support!');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (step === 2) {
            try {
                if (process.env.REACT_APP_ENVIRONMENT !== 'onprem') {
                    if (currentForm === 'signIn') {
                        await signInWithEmailAndPassword(auth, email, password);
                    } else if (currentForm === 'signUp') {
                        await createUserWithEmailAndPassword(auth, email, password);
                    }
                    setErrorMessage('');
                    navigate('/');
                } else {
                    async function fetchFromAPI() {
                        await onpremSignin(user_name, password);
                    }

                    fetchFromAPI().catch();
                }
            } catch (error) {
                setErrorMessage(errorMessages(error.code));
            } finally {
                setLoading(false);
            }
        }
    };

    const onpremSignin = async (user_name, password) => {
        setLoading(true);
        await httpRequest('POST', apiEndpoints.SIGN_IN, { user_name: user_name, password: password })
            .then(async (res) => {
                if (res?.data && res.data?.jwt) {
                    const userObject = { name: res?.data?.name };
                    sessionStorage.setItem(SESSION_STORAGE_JWT, res?.data?.jwt);
                    sessionStorage.setItem(SESSION_STORAGE_ACTIVATION_TOKEN, res?.data?.activation_token);
                    sessionStorage.setItem(SESSION_STORAGE_ACCOUNT_ID, res?.data?.account_id);
                    sessionStorage.setItem(SESSION_STORAGE_USER_UNDER_ASSESSMENT, res?.data?.under_assessment?.toString());
                    sessionStorage.setItem(SESSION_STORAGE_USER, JSON.stringify(userObject));
                    sessionStorage.setItem(SESSION_STORAGE_USER_NAME, res?.data?.name);

                    dispatch({ type: 'SET_AUTHENTICATION', payload: true });
                    dispatch({ type: 'SET_USER_DATA', payload: userObject });

                    setLoading(false);
                    navigate(router.dashboard);
                }
                if (res?.status === 201) {
                    setLoading(false);
                    sessionStorage.setItem(SESSION_STORAGE_ACCOUNT_DATA, JSON.stringify(res.data));
                    navigate(router.selectAccount, { replace: true });
                }
            })
            .catch(async (error) => {
                if (error?.status === process.env.REACT_APP_AUTHENTICATION_ERROR_STATUS_CODE) {
                    setErrorMessage('Unfortunately, we were unable to locate your account in our system!');
                } else if (error.status === process.env.REACT_APP_AUTO_SERVER_STATUS_CODE) {
                    setErrorMessage(error.data.message);
                } else {
                    setErrorMessage('Server error, please contact with support!');
                }
            });
    };

    const onFieldsChange = (_, allFields) => {
        const errors = form.getFieldsError();
        const allFieldsNotEmpty = allFields.every((field) => field.value);
        setIsFormValid(allFieldsNotEmpty && errors.filter(({ errors }) => errors.length).length === 0);
    };

    return (
        <div className="ms-form-inner">
            <div className="header">
                <Heading fontWeight={600} children={formDetails[currentForm]?.title} desc={step === 3 && formDetails[currentForm]?.ssoDescription}></Heading>
            </div>
            {process.env.REACT_APP_ENVIRONMENT !== 'onprem' && (
                <div className="social-login">
                    <SocialLogin
                        icon={GoogleIcon}
                        title={formDetails[currentForm]?.socialLoginTitle}
                        onClick={() => {
                            sessionStorage.setItem(SESSION_STORAGE_LOGIN_PROVIDER, 'Google');
                            signInWithPopup(auth, new GoogleAuthProvider());
                        }}
                    />
                    <SocialLogin
                        icon={RxLockClosed}
                        iconStyle={{ width: '18px', height: '18px' }}
                        title={'Continue with SSO'}
                        onClick={() => {
                            sessionStorage.setItem(SESSION_STORAGE_LOGIN_PROVIDER, 'SSO');
                            loginWithRedirect();
                        }}
                    />
                </div>
            )}
            {process.env.REACT_APP_ENVIRONMENT !== 'onprem' && <Divider />}
            <Form className="form" name="sign-in" onFinish={onFinish} autoComplete="off" form={form} onFieldsChange={onFieldsChange}>
                {process.env.REACT_APP_ENVIRONMENT !== 'onprem' ? (
                    <div className="email input">
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!'
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address!'
                                }
                            ]}
                        >
                            <Input type={'email'} label="Email" placeholder="Your email" customClass="rounded" disabled={step === 2} />
                        </Form.Item>
                    </div>
                ) : (
                    <div className="email input">
                        <Form.Item
                            name="user_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!'
                                }
                            ]}
                        >
                            <Input type={'text'} label="Username" placeholder="Your username" customClass="rounded" disabled={step === 2} />
                        </Form.Item>
                    </div>
                )}

                {step === 2 && (
                    <>
                        <div className="password">
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!'
                                    }
                                ]}
                            >
                                <Input label="Password" type={'password'} placeholder="*********" customClass="rounded" />
                            </Form.Item>
                        </div>

                        <div className="forget-password-wrapper">
                            <div className="ms-checkbox single-line">
                                <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}>
                                    Remember me
                                </Checkbox>
                            </div>
                            {currentForm === 'signIn' && process.env.REACT_APP_ENVIRONMENT !== 'onprem' && (
                                <div className="forget-password">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/forget-password');
                                        }}
                                    >
                                        Forgot password?
                                    </a>
                                </div>
                            )}
                        </div>
                    </>
                )}

                <div className="buttons-container">
                    <Button
                        htmlType="submit"
                        customClassName="btn-primary"
                        loading={loading}
                        placeholder={formDetails[currentForm]?.buttonText}
                        disabled={!isFormValid}
                    />
                </div>
                <FormServerMessage text={errorMessage} />
            </Form>
        </div>
    );
};

export default SignInForm;
