import Button from '../button';
import Tooltip from '../tooltip/tooltip';
import { Popover } from 'antd';
import CustomButtonWrapper from '../buttonsWrapper/CustomButtonWrapper';
import { ReactComponent as DeleteIcon } from 'assets/images/customDeleteIcon.svg';
import { ReactComponent as RecreateIcon } from 'assets/images/recreateIcon.svg';
import { FaPlay } from 'react-icons/fa';

const FixNowButton = ({ item, triggerConfirmation, triggerRecreateTopicsModal, triggerChangeRetentionModal, buttonStyle, buttonText = 'Fix now' }) => {
    if (['change_topic_retention'].includes(item?.task_type || item?.type || item?.job_type)) {
        return buttonStyle === 'subTask' ? (
            <Button
                shape="circle"
                placeholder={<FaPlay color={'var(--ready-text-color)'} />}
                disabled={item?.status === 'fixed' || item?.status === 'ignored' || item?.fixable === false}
                onClick={(e) => {
                    e.stopPropagation();
                    triggerChangeRetentionModal();
                }}
            />
        ) : (
            <Button
                style={{ color: '#6358FA', fontSize: 14, fontWeight: 500 }}
                placeholder={<CustomButtonWrapper text={buttonText} withArrow={false} textColor={'#6358FA'} textStyle={{ fontWeight: 500, fontSize: 14 }} />}
                disabled={item?.status === 'completed' || item?.status === 'in_progress' || item?.fixable === false}
                customClassName={'list-item-custom-btn'}
                onClick={(e) => {
                    e.stopPropagation();
                    triggerChangeRetentionModal();
                }}
            />
        );
    } else if (!['remove_inactive_topic', 'remove_inactive_empty_topic'].includes(item?.task_type || item?.type || item?.job_type)) {
        return buttonStyle === 'subTask' ? (
            <Button
                shape="circle"
                placeholder={<FaPlay color={'var(--ready-text-color)'} />}
                disabled={item?.status === 'fixed' || item?.status === 'ignored' || item?.fixable === false}
                onClick={(e) => {
                    e.stopPropagation();
                    triggerConfirmation();
                }}
            />
        ) : (
            <Button
                style={{ color: '#6358FA', fontSize: 14, fontWeight: 500 }}
                placeholder={<CustomButtonWrapper text={buttonText} withArrow={false} textColor={'#6358FA'} textStyle={{ fontWeight: 500, fontSize: 14 }} />}
                disabled={item?.status === 'completed' || item?.status === 'in_progress' || item?.fixable === false}
                customClassName={'list-item-custom-btn'}
                onClick={(e) => {
                    e.stopPropagation();
                    triggerConfirmation();
                }}
            />
        );
    }

    const isRecreateButtonDisabled = buttonStyle === 'subTask' && item?.metadata?.partitions?.value === 1;

    const content = (
        <div className="custom-popover-content">
            <div
                className="popover-item"
                onClick={(e) => {
                    e.stopPropagation();
                    triggerConfirmation();
                }}
            >
                <DeleteIcon />
                <span className={'popover-item-label'}>Delete topic</span>
            </div>
            <Tooltip
                text={isRecreateButtonDisabled ? 'Since this topic has only one partition, recreating it won’t make a difference' : null}
                disabled={!isRecreateButtonDisabled}
                placement={'top'}
            >
                <div
                    className={`popover-item ${isRecreateButtonDisabled ? 'disabled' : ''}`}
                    onClick={(e) => {
                        if (!isRecreateButtonDisabled) {
                            e.stopPropagation();
                            triggerRecreateTopicsModal();
                        }
                    }}
                >
                    <RecreateIcon />
                    <span className={'popover-item-label'}>Recreate topic</span>
                </div>
            </Tooltip>
        </div>
    );

    return (
        <Tooltip
            text={item?.fixable === false ? (item?.task_type === 'reduce_payload' ? 'Not supported at the moment' : item?.fixable_reason) : null}
            disabled={item?.fixable !== false}
            placement={'top'}
        >
            <Popover content={content} trigger="click" placement="bottom" onClick={(e) => e.stopPropagation()}>
                <>
                    {buttonStyle === 'subTask' ? (
                        <Button
                            shape="circle"
                            placeholder={<FaPlay color={'var(--ready-text-color)'} />}
                            disabled={item?.status === 'fixed' || item?.status === 'ignored' || item?.fixable === false}
                        />
                    ) : (
                        <Button
                            style={{ color: '#6358FA', fontSize: 14, fontWeight: 500 }}
                            placeholder={<CustomButtonWrapper text={buttonText} withArrow={false} textColor={'#6358FA'} textStyle={{ fontWeight: 500, fontSize: 14 }} />}
                            disabled={item?.status === 'completed' || item?.status === 'in_progress' || item?.fixable === false}
                            customClassName={'list-item-custom-btn'}
                        />
                    )}
                </>
            </Popover>
        </Tooltip>
    );
};

export default FixNowButton;
