import './style.scss';
import Table from 'components/table';
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useHttpRequest } from 'services/http';
import { apiEndpoints } from 'services/apiEndpoints';
import { BiError } from 'react-icons/bi';
import { Select } from 'antd';
import Modal from 'components/modal';
import DeleteItemsModal from 'components/deleteItemsModal';
import Badge from './components/badge';
import Drawer from 'components/drawer';
import { ReactComponent as ClientsIcon } from 'assets/images/sidebar/client.svg';
import { RxArrowTopRight } from 'react-icons/rx';
import Button from 'components/button';
import Loader from 'components/loader';
import ClientLogs from './components/clientLogs';
import TooltipComponent from 'components/tooltip/tooltip';
import { getIconByLang } from 'services/genericServices';
import Notification from 'components/notifications';
import { IoIosArrowDown } from 'react-icons/io';

const Clients = forwardRef(({ connection, doneRefresh }, ref) => {
    const httpRequest = useHttpRequest();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [loadingReductionSwitch, setLoadingReductionSwitch] = useState({});
    const [loadingCompressionSwitch, setLoadingCompressionSwitch] = useState({});
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [selectedModal, setSelectedModal] = useState('compression');
    const [selectedFilters, setSelectedFilters] = useState({
        listOfDataPlanes: [],
        listOfClusters: [],
        type: []
    });
    const [selectedConnectionID, setSelectedConnectionID] = useState(null);
    const [filterState, setFilterState] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedClientCompression, setSelectedClientCompression] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [logModalOpen, flipLogModal] = useState(false);
    const itemsPerScroll = 100;
    const [loadedItems, setLoadedItems] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        handleRefresh() {
            fetchData().catch();
        }
    }));

    const modalContent = {
        compression: {
            title: 'Payload compression',
            desc: 'Payload compression will efficiently transform JSON payloads into binary format, decreasing their size by as much as 75%.'
        }
    };

    useEffect(() => {
        const pathTokens = decodeURI(location.pathname)?.split('/');
        const connectionId = pathTokens[pathTokens?.length - 1];
        setSelectedConnectionID(connectionId);
    }, [connection, location.pathname]);

    useEffect(() => {
        fetchData().catch();
    }, [selectedConnectionID]);

    useEffect(() => {
        handleSelectedFilters(false);
    }, [searchParams, initialData]);

    useEffect(() => {
        const engines = searchParams.getAll('engine');
        const clusters = searchParams.getAll('connection');
        const types = searchParams.getAll('type');

        const params = {
            listOfDataPlanes: engines.length > 0 ? engines : [],
            listOfClusters: clusters.length > 0 ? clusters : [],
            type: types.length > 0 ? types : []
        };

        setSelectedFilters(params);
        setFilterState(params);
    }, [searchParams]);

    const fetchData = async () => {
        if (!selectedConnectionID) return;
        setLoading(true);
        try {
            const response = await httpRequest('GET', apiEndpoints.CLIENTS_GET_ALL, {}, {}, selectedConnectionID ? { id: selectedConnectionID } : {});
            setInitialData(response);
            setData(response);
            setLoadedItems(response?.slice(0, itemsPerScroll));
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
            doneRefresh();
        }
    };

    const onReductionChanged = async (item, toggle) => {
        setLoadingReductionSwitch((prev) => ({ ...prev, [item?.id]: true }));
        try {
            await httpRequest('POST', apiEndpoints.CLIENTS_TOGGLE_REDUCTION, {
                all_clients: false,
                enable_reduction: toggle,
                client_ids: [item?.id]
            }).then(async () => {
                const updatedClients = data.map((client) => {
                    if (client.id === item?.id) {
                        return { ...client, reduction_enabled: !client.reduction_enabled };
                    }
                    return client;
                });
                setData(updatedClients);
            });
        } catch (error) {
            await fetchData();
            setLoadingReductionSwitch((prev) => ({ ...prev, [item?.id]: false }));
        } finally {
            setLoadingReductionSwitch((prev) => ({ ...prev, [item?.id]: false }));
            setConfirmationModalOpen(false);
            setDrawerOpen(false);
        }
    };

    const onCompressionChanged = async (item, toggle) => {
        setLoadingCompressionSwitch((prev) => ({ ...prev, [item?.id]: true }));
        try {
            await httpRequest('POST', apiEndpoints.CLIENTS_TOGGLE_COMPRESSION, {
                all_clients: false,
                enable_compression: !!toggle,
                client_ids: [item?.id]
            }).then(async () => {
                const updatedClients = data.map((client) => {
                    if (client.id === item?.id) {
                        return { ...client, compression_enabled: !client.compression_enabled };
                    }
                    return client;
                });
                setData(updatedClients);
            });
        } catch (error) {
            await fetchData();
            setLoadingCompressionSwitch((prev) => ({ ...prev, [item?.id]: false }));
        } finally {
            Notification('success', toggle ? 'Compression has been successfully turned on' : 'Compression has been successfully turned off', 5);
            setLoadingCompressionSwitch((prev) => ({ ...prev, [item?.id]: false }));
            setConfirmationModalOpen(false);
            setDrawerOpen(false);
        }
    };

    function countKeys(obj) {
        return Object.keys(obj || {}).length;
    }

    const columns = [
        {
            dataIndex: 'type',
            title: 'Type',
            key: 'type',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        {record?.is_consumer && <Badge type="consumer">Consumer</Badge>}
                        {record?.is_producer && <Badge type="producer">Producer</Badge>}
                        {record?.is_admin && <Badge type="admin">Admin</Badge>}
                    </div>
                );
            },
            sorter: (a, b) => {
                const getTypeValue = (record) => {
                    if (record.is_consumer) return 'consumer';
                    if (record.is_producer) return 'producer';
                    if (record.is_admin) return 'admin';
                    return 'none';
                };

                const aValue = getTypeValue(a);
                const bValue = getTypeValue(b);
                return aValue.localeCompare(bValue);
            },
            width: 130
        },
        {
            dataIndex: 'hostaname',
            title: 'Hostname / IP',
            key: 'hostaname',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                        {getIconByLang(record?.language)}
                        <div>{record?.client_host !== '' ? record?.client_host : record?.client_ip !== '' ? record?.client_ip : '-'}</div>
                        {record?.errors && (
                            <TooltipComponent
                                text={
                                    <div className="client-errors-tooltip" onClick={(e) => e.stopPropagation()}>
                                        <p>This client contains errors.</p>
                                        <Button
                                            placeholder="Display activity logs"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                flipLogModal(true);
                                            }}
                                        />
                                    </div>
                                }
                            >
                                <BiError color="var(--failed-color)" />
                            </TooltipComponent>
                        )}
                    </div>
                );
            },
            sorter: (a, b) => {
                const hostA = a.client_host || a.client_ip || '';
                const hostB = b.client_host || b.client_ip || '';
                return hostA.localeCompare(hostB);
            }
        },
        {
            dataIndex: 'client_id',
            title: 'Client ID',
            key: 'client_id',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                        <div style={{ wordBreak: 'break-all' }}>
                            {record?.config?.['client.id'] ? record.config['client.id'] : '-'}
                        </div>
                    </div>
                );
            },
            sorter: (a, b) => {
                const clientA = (a.config && a.config['client.id']) || '';
                const clientB = (b.config && b.config['client.id']) || '';
                return clientA.localeCompare(clientB);
            }
        },
        {
            dataIndex: 'topics',
            title: 'Topics',
            key: 'topics',
            render: (text, record) => {
                const producerTopicCount = record?.producer_topics_partitions ? countKeys(record.producer_topics_partitions) : 0;
                const consumerTopicCount = record?.consumer_group_topics_partitions ? countKeys(record.consumer_group_topics_partitions) : 0;

                const producerTopics = record?.producer_topics_partitions
                    ? Object.keys(record.producer_topics_partitions).map((item, index) => (
                          <div key={`producer-topic-${record.id}-${index}`} className="text-ellipse">
                              {item}
                          </div>
                      ))
                    : [];

                const consumerTopics = record?.consumer_group_topics_partitions
                    ? Object.keys(record.consumer_group_topics_partitions).map((item, index) => (
                          <div key={`consumer-topic-${record.id}-${index}`} className="text-ellipse">
                              {item}
                          </div>
                      ))
                    : [];

                if (producerTopicCount > 1 || consumerTopicCount > 1) {
                    return (
                        <Button
                            customClassName="client-topics-button"
                            placeholder={
                                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                    {`${producerTopicCount + consumerTopicCount} connected topics`} <RxArrowTopRight />
                                </div>
                            }
                            onClick={() => openDrawer(record)}
                        />
                    );
                } else if (producerTopicCount > 0 || consumerTopicCount > 0) {
                    return (
                        <div style={{ display: 'flex', gap: 4 }}>
                            <span>{producerTopics}</span>
                            <span>{consumerTopics}</span>
                        </div>
                    );
                } else {
                    return '-';
                }
            },
            sorter: (a, b) => {
                const aTopics = countKeys(a.producer_topics_partitions) + countKeys(a.consumer_group_topics_partitions);
                const bTopics = countKeys(b.producer_topics_partitions) + countKeys(b.consumer_group_topics_partitions);
                return aTopics - bTopics;
            },
            defaultSortOrder: 'descend'
        },
        {
            dataIndex: 'tags',
            title: 'Tags',
            key: 'tags',
            render: (text, record) => getFormattedTags(record)
        },
        {
            dataIndex: 'version',
            title: 'Version',
            key: 'version',
            render: (text, record) => {
                return <span>{record?.version}</span>;
            },
            width: 130
        }
    ];

    const getFormattedTags = (record) => {
        const tags = record?.tags && record?.tags.length > 0 ? record?.tags : [];
        const tagsV1 = record?.tags_v1 && Object.keys(record?.tags_v1).length > 0 ? Object.entries(record?.tags_v1).map(([key, value]) => `${key}: ${value}`) : [];

        const allTags = [...tags, ...tagsV1];

        const tagsWrap = allTags.map((tag, index) => {
            return (
                <TooltipComponent key={`tag-${tag}-${index}`} disabled={tag?.length < 50} text={tag}>
                    <Badge type="light-gray" eclipse={tag?.length > 50} index={index}>
                        {tag}
                    </Badge>
                </TooltipComponent>
            );
        });

        return tagsWrap.length > 0 ? <div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 4 }}>{tagsWrap}</div> : '-';
    };

    const handleConfirmationModal = (record, e, type) => {
        setSelectedModal(type);
        setModalData({ record, e });
        setConfirmationModalOpen(true);
    };

    const handleSearch = (value) => {
        if (value.length === 0) {
            setData(initialData);
        } else {
            const lowercasedValue = value.toLowerCase();
            const filteredData = initialData.filter((item) => {
                const matchesConnectionName = item?.connection_name.toLowerCase().includes(lowercasedValue);
                const matchesEnvName = item?.env_name.toLowerCase().includes(lowercasedValue);
                const matchesTags = item?.tags && item.tags.some((tag) => tag.toLowerCase().includes(lowercasedValue));

                const matchesProducerTopicsPartitions =
                    item?.producer_topics_partitions && Object.keys(item.producer_topics_partitions).some((key) => key.toLowerCase().includes(lowercasedValue));

                const matchesConsumerGroupTopicsPartitions =
                    item?.consumer_group_topics_partitions &&
                    Object.keys(item.consumer_group_topics_partitions).some((key) => key.toLowerCase().includes(lowercasedValue));

                return matchesConnectionName || matchesEnvName || matchesTags || matchesProducerTopicsPartitions || matchesConsumerGroupTopicsPartitions;
            });

            setData(filteredData);
        }
    };

    const handleSelectedFilters = (urlParams = false, action = null) => {
        const filteredData = initialData.filter((item) => {
            const itemType = item.is_producer ? 'producer' : item.is_consumer ? 'consumer' : item.is_admin ? 'admin' : undefined;
            const isValidCluster =
                selectedFilters.listOfClusters.length === 0 ||
                selectedFilters.listOfClusters.some((cluster) => (cluster === 'orphan_clients' ? '' : cluster) === item?.connection_name);
            return (
                (selectedFilters.listOfDataPlanes.length === 0 || selectedFilters.listOfDataPlanes.includes(item?.env_name)) &&
                isValidCluster &&
                (selectedFilters.type.length === 0 || selectedFilters.type.includes(itemType))
            );
        });
        setData(filteredData);
        setFilterState(selectedFilters);

        if (urlParams) {
            if (action === 'reset') {
                setData(initialData);
                setFilterState({});
            }

            const searchParams = new URLSearchParams();
            selectedFilters.listOfDataPlanes.forEach((plane) => searchParams.append('engine', plane));
            selectedFilters.listOfClusters.forEach((cluster) => searchParams.append('connection', cluster));
            selectedFilters.type.forEach((type) => searchParams.append('type', type));
            navigate({ search: action === 'reset' ? '' : searchParams.toString() }, { replace: true });
        }
    };

    const openDrawer = (record) => {
        setDrawerOpen(true);
        setSelectedClient(record);
        setSelectedClientCompression(record?.compression_enabled);
    };

    const drawerInfoData = () => {
        const data = [
            {
                title: 'Type',
                value: (
                    <div style={{ display: 'inline-flex' }}>
                        {selectedClient?.is_consumer && <span style={{ color: 'var(--primary-color)' }}>Consumer</span>}
                        {selectedClient?.is_producer && selectedClient?.is_consumer && <span style={{ marginRight: 3 }}>,</span>}
                        {selectedClient?.is_producer && <span style={{ color: 'var(--primary-color)' }}>Producer</span>}
                        {selectedClient?.is_admin && <span style={{ color: 'var(--primary-color)' }}>Admin</span>}
                    </div>
                )
            },
            {
                title: 'Hostname',
                value: selectedClient?.client_host || '-'
            },
            {
                title: 'IP',
                value: selectedClient?.client_ip || '-'
            },
            {
                title: 'Client ID',
                value: selectedClient?.config && selectedClient?.config['client.id']
            },
            {
                title: 'Engine',
                value: selectedClient?.env_name || '-'
            },
            {
                title: 'Connection name',
                value: selectedClient?.connection_name || '-'
            },
            {
                title: 'Language',
                value: getIconByLang(selectedClient?.language) || '-'
            },
            {
                title: 'Version',
                value: selectedClient?.version || '-'
            },
            {
                title: 'Tags',
                value: getFormattedTags(selectedClient)
            }
        ];
        return data.map((item, index) => {
            return (
                <div className="client-drawer-body-info-item" key={index}>
                    <div className="left">{item.title}</div>
                    <div className="right">{item.value}</div>
                </div>
            );
        });
    };

    const formatValue = (value, key, recordType) => {
        const excludedConfigs = {
            consumer: ['batch.size', 'linger.ms', 'compression.type'],
            admin: ['batch.size', 'linger.ms', 'compression.type'],
        };
        if (excludedConfigs[recordType]?.includes(key)) {
            return '-';
        }
        if (Array.isArray(value)) {
            return value.length > 0 ? value.join(', ') : '0';
        }
        if (typeof value === 'object' && value !== null) {
            return Object.keys(value).length > 0 ? JSON.stringify(value) : '-';
        }
        if (typeof value === 'boolean') {
            return value ? 'true' : 'false';
        }
        if (value === 0 && ['linger.ms', 'partitioner.availability.timeout.ms'].includes(key)) {
            return '0';
        }
        if (value === '' && key === 'client.id' && recordType !== 'producer') {
            return '-';
        }
        if (Array.isArray(value) && key === 'metric.reporters' && recordType !== 'producer') {
            return '0';
        }
        return value ? value : '-';
    };

    const processConfig = (config, optimized_configuration, recordType) => {
        if (!config) return;

        const topKeys = {
            'bootstrap.servers': 'bootstrap.servers',
            'client.id': 'client.id',
            'batch.size': 'batch.size',
            'linger.ms': 'linger.ms',
            'compression.type': 'compression.type',
            'metric.reporters': 'metric.reporters',
            'partitioner.availability.timeout.ms': 'partitioner.availability.timeout.ms',
        };

        const dataSource = Object.entries(topKeys)
            .filter(([key]) => key in config)
            .map(([key, label], index) => ({
                key: String(index + 1),
                value: label,
                current: formatValue(config[key], key, recordType),
                overwrite: formatValue(optimized_configuration[key], key, recordType),
            }));

        const additionalConfig = Object.keys(config)
            .filter((key) => !Object.keys(topKeys).includes(key))
            .map((key, index) => ({
                key: String(dataSource.length + index + 1),
                value: key,
                current: formatValue(config[key], key, recordType),
                overwrite: formatValue(optimized_configuration[key], key, recordType),
            }));

        return [...dataSource, ...additionalConfig];
    };

    const columnsOfDrawer = [
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value'
        },
        {
            title: 'Current',
            dataIndex: 'current',
            key: 'current'
        },
        {
            title: 'Overwrite',
            dataIndex: 'overwrite',
            key: 'overwrite'
        }
    ];

    const compressionOptions = [
        /*{ label: 'gzip', value: 'gzip' },
        { label: 'lz4', value: 'lz4' },
        { label: 'snappy', value: 'snappy' },*/
        { label: 'none', value: '' },
        { label: 'zstd', value: 'zstd' }
    ];

    const dataSource =
        selectedClient?.config &&
        processConfig(selectedClient?.config, selectedClient?.optimized_configuration).map((item) => {
            if (item?.value === 'compression.type') {
                return {
                    ...item,
                    overwrite: (
                        <>
                            <Select
                                defaultValue={item?.overwrite}
                                value={selectedClientCompression}
                                onChange={(value) => setSelectedClientCompression(value)}
                                loading={loadingCompressionSwitch[selectedClient?.id]}
                                style={{ width: '100%', minWidth: 100 }}
                                options={compressionOptions}
                                allowClear={true}
                                suffixIcon={<IoIosArrowDown />}
                            />
                        </>
                    )
                };
            }
            return item;
        });

    const onChange = (pagination, filters, sorter, extra) => {
        setTableLoading(true);
        const timeout = Math.ceil(initialData.length / 1000) * 100;

        new Promise((resolve) => {
            setTimeout(() => {
                const { order, field } = sorter;

                const sortedData = [...initialData].sort((a, b) => {
                    let compare = 0;
                    if (field && columns.find((col) => col.dataIndex === field)?.sorter) {
                        compare = columns.find((col) => col.dataIndex === field)?.sorter(a, b);
                    }
                    return order === 'ascend' ? compare : -compare;
                });

                resolve(sortedData);
            }, timeout);
        }).then((sortedData) => {
            setData(sortedData);
            setInitialData(sortedData);
            setLoadedItems(sortedData?.slice(0, itemsPerScroll));
        }).finally(() => {
            setTableLoading(false);
        });
    };

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

        if (scrollPercentage >= 0.75 && loadedItems.length < data.length) {
            const nextLoadedItems = data?.slice(0, loadedItems.length + itemsPerScroll);
            setLoadedItems(nextLoadedItems);
        }
    };

    return loading ? (
        <Loader background={false} isFullHeight={true} />
    ) : (
        <div className="clients-page">
            <div className="clients-page-table">
                <Table
                    columns={columns}
                    dataSource={loadedItems}
                    pagination={false}
                    loading={tableLoading}
                    rowKey={(record) => record.id}
                    onCustomRowClick={(record) => openDrawer(record)}
                    scroll={{ x: 1000, y: 'calc(100vh - 200px)' }}
                    onScroll={handleScroll}
                    onChange={onChange}
                />
            </div>

            <Drawer
                title={
                    <div className="client-drawer-title">
                        <span>
                            <ClientsIcon className="client-icon" />
                            Client #{selectedClient?.id}
                            {selectedClient?.errors && <BiError className="error-icon" />}
                        </span>
                        {selectedClient.errors && <Button placeholder="Activity logs" onClick={() => flipLogModal(true)} />}
                    </div>
                }
                placement="right"
                width={'560px'}
                destroyOnClose={true}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                className="client-drawer"
                closeIcon={false}
            >
                <div className="client-drawer-body">
                    <div className="client-drawer-body-top">
                        <div className="client-drawer-body-info">{drawerInfoData()}</div>
                        <div className="client-drawer-body-options">
                            {selectedClient?.config && (
                                <div className="configuration-options">
                                    <div className="options-title">Configurations</div>
                                    <div className="configuration-options-main">
                                        <Table
                                            dataSource={dataSource}
                                            columns={columnsOfDrawer}
                                            pagination={false}
                                            bordered={false}
                                            style={{
                                                borderRadius: '6px',
                                                overflow: 'hidden'
                                            }}
                                            scroll={{ y: '220px' }}
                                        />
                                    </div>
                                </div>
                            )}
                            {(countKeys(selectedClient?.producer_topics_partitions) > 0 || countKeys(selectedClient?.consumer_group_topics_partitions)) > 0 && (
                                <div className="topic-options">
                                    <div className="options-title">Connected topics</div>
                                    <div className="topic-options-main">
                                        {countKeys(selectedClient?.producer_topics_partitions) > 0 && (
                                            <div className="topic-options-main-column">
                                                <div className="topic-option-label option-desc">Produce to</div>
                                                <div className="topic-options-option option-label">
                                                    {Object.keys(selectedClient?.producer_topics_partitions)?.map((item) => (
                                                        <div key={`produce-${item}`}>{item}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {countKeys(selectedClient?.consumer_group_topics_partitions) > 0 && (
                                            <div className="topic-options-main-column">
                                                <div className="topic-option-label option-desc">Consume from</div>
                                                <div className="topic-options-option option-label">
                                                    {Object.keys(selectedClient?.consumer_group_topics_partitions)?.map((item) => (
                                                        <div key={`consume-${item}`}>{item}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="client-drawer-body-bottom">
                        <div className="client-drawer-body-footer">
                            <Button customClassName="modal-btn modal-btn-secondary" placeholder="Close" onClick={() => setDrawerOpen(false)} />
                            <Button
                                customClassName="modal-btn modal-btn-primary"
                                placeholder="Save"
                                disabled={selectedClient?.compression_enabled === selectedClientCompression}
                                onClick={(e) => onCompressionChanged(selectedClient, selectedClientCompression)}
                                loading={loadingCompressionSwitch[selectedClient?.id]}
                            />
                        </div>
                    </div>
                </div>
            </Drawer>

            <ClientLogs open={logModalOpen} clickOutside={() => flipLogModal(false)} clientId={selectedClient?.id} />

            <Modal header="" width="520px" height="240px" displayButtons={false} clickOutside={() => setConfirmationModalOpen(false)} isModalOpen={confirmationModalOpen}>
                <DeleteItemsModal
                    title={modalContent[selectedModal]?.title}
                    desc={modalContent[selectedModal]?.desc}
                    buttontxt={<>I understand, {modalData?.e ? 'Turn on' : 'Turn off'}</>}
                    handleDeleteSelected={() =>
                        selectedModal === 'reduction' ? onReductionChanged(modalData?.record, modalData?.e) : onCompressionChanged(modalData?.record, modalData?.e)
                    }
                    textToConfirm={modalData.e ? 'Turn on' : 'Turn off'}
                    loader={loadingReductionSwitch[modalData?.record?.id]}
                    disableInputConfirmation={true}
                />
            </Modal>
        </div>
    );
});

export default Clients;
