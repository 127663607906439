import "./style.scss";
import Modal from "components/modal";
import Button from "components/button";

const ConfirmationModal = (
    {
        isModalOpen,
        setModalOpen,
        title,
        desc,
        onSubmit
    }
) => {

    return (
        <Modal
            clickOutside={() => setModalOpen(false)}
            isModalOpen={isModalOpen}
            className={"confirmation-modal"}
            closeIcon={true}
            width={380}
        >
            <div className="top">
                <div className="title">{title}</div>
                <div className="close">
                </div>
            </div>
            <div className="middle">
                <div className="content">
                    {desc}
                </div>
            </div>
            <div className="bottom">
                <div className="right">
                </div>
                <div className="left">
                    <Button
                        placeholder={"Cancel"}
                        onClick={() => setModalOpen(false)}
                        customClassName={"btn btn-sp-secondary"}
                    />
                    <Button
                        placeholder={'Delete'}
                        onClick={() => {
                            onSubmit();
                            setModalOpen(false);
                        }}
                        customClassName={"btn btn-sp-primary"}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
