import React, { useState } from "react";
import { Tag } from "antd";
import { FaRegCircleCheck } from "react-icons/fa6";
import Button from "components/button";
import { LuPlus } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import Input from "components/input";
import ConfirmationModal from "components/deleteItemsModal/styled";
import { ReactComponent as EditIcon } from "assets/images/editIcon2.svg";

const EmailInput = ({section, initialState, onSubmit}) => {
    const [items, setItems] = useState(initialState || []);
    const [inputValue, setInputValue] = useState('');
    const [inputVisible, setInputVisible] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null);
    const [showError, setShowError] = useState(false);

    const validateInput = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    };

    const handleAddItem = () => {
        if (inputValue && validateInput(inputValue)) {
            let newItems;
            if (editingIndex !== null) {
                newItems = [...items];
                newItems[editingIndex] = inputValue;
                setEditingIndex(null);
            } else {
                if (!items.includes(inputValue)) {
                    newItems = [...items, inputValue];
                } else {
                    return;
                }
            }
            setItems(newItems);
            setInputValue('');
            setInputVisible(true);
            onSubmit(section, newItems);
            setShowError(false);
        } else {
            inputValue && setShowError(true);
        }
    };

    const handleRemoveItem = (item) => {
        setItemToDelete(item);
        setDeleteModalOpen(true);
    };

    const confirmRemoveItem = () => {
        const newItems = items.filter(i => i !== itemToDelete);
        setItems(newItems);
        setDeleteModalOpen(false);
        setItemToDelete(null);
        setEditingIndex(null);
        setInputValue('');
        onSubmit(section, newItems);
    };

    const handleEditItem = (index) => {
        setInputValue(items[index]);
        setEditingIndex(index);
        setInputVisible(true);
    };

    const handleCancelEdit = () => {
        setInputValue('');
        setEditingIndex(null);
        setInputVisible(false);
    };

    return (
        <>
            <div className="input-chips">
                <div className="input-chips-top">
                    {items.map((item, index) => (
                        <Tag key={index} onClick={() => handleEditItem(index)}>
                            <div className="icon-check">
                                <FaRegCircleCheck size={16}/>
                            </div>

                            <div className="icon-edit">
                                <EditIcon size={16}/>
                            </div>

                            {item}

                            <div className="icon-close" onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveItem(item)
                            }}>
                                <IoClose size={16}/>
                            </div>
                        </Tag>
                    ))}

                    {items.length > 0 && !inputVisible &&
                        <Button
                            shape={"circle"}
                            placeholder={<LuPlus size={18} color={'#475467'}/>}
                            onClick={() => setInputVisible(true)}
                        />
                    }
                </div>

                {(items.length === 0 || inputVisible) && (
                    <div className="input-chips-add">
                        <div className="input-chips-add-top">
                            <div className="input-chips-add-close">
                                <Button
                                    typeOfButton={"text"}
                                    placeholder={<IoClose color={"#98A2B3"} size={14}/>}
                                    onClick={handleCancelEdit}
                                    disabled={items.length === 0}
                                />
                            </div>
                            <div style={{width: "100%"}}>
                                <Input
                                    type='email'
                                    placeholder={"e.g. janedou@gmail.com"}
                                    value={inputValue}
                                    onChange={(e) => {
                                        setInputValue(e.target.value);
                                        setShowError(false);
                                    }}
                                    onBlur={() => {
                                        if (!validateInput(inputValue)) {
                                            inputValue && setShowError(true);
                                        }
                                    }}
                                    onPressEnter={handleAddItem}
                                    subLabel={"Connect an email"}
                                />
                                <div className="error-message">
                                    {showError && !validateInput(inputValue) && 'Wrong email format. Please try again'}
                                </div>
                            </div>
                            {editingIndex === null && (
                                <Button
                                    size={'large'}
                                    placeholder={"Submit"}
                                    onClick={handleAddItem}
                                    disabled={!validateInput(inputValue)}
                                />
                            )}
                        </div>
                        {editingIndex !== null && (
                            <div className="input-chips-add-bottom">
                                <div className="input-chips-add-bottom-left">
                                    <Button
                                        typeOfButton={"text"}
                                        placeholder={"Delete"}
                                        style={{color: "#F04438"}}
                                        onClick={() => handleRemoveItem(items[editingIndex])}
                                    />
                                </div>
                                <div className="input-chips-add-bottom-right">
                                    <Button
                                        placeholder={"Cancel"}
                                        style={{color: "#475467"}}
                                        onClick={handleCancelEdit}
                                    />
                                    <Button
                                        typeOfButton={"primary"}
                                        placeholder={"Save changes"}
                                        onClick={handleAddItem}
                                        disabled={!validateInput(inputValue)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <ConfirmationModal
                title={`Are you sure you want to delete the email ${itemToDelete}?`}
                desc={`You will not receive notifications via this email.`}
                onSubmit={confirmRemoveItem}
                isModalOpen={deleteModalOpen}
                setModalOpen={setDeleteModalOpen}
            />
        </>
    );
};

export default EmailInput;