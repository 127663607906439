import React, { useState } from "react";
import Tooltip from "components/tooltip/tooltip";
import { Tag } from "antd";
import { FaRegCircleCheck } from "react-icons/fa6";
import Button from "components/button";
import { LuPlus } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import Input from "components/input";
import { HiOutlinePlus } from "react-icons/hi";
import ConfirmationModal from "components/deleteItemsModal/styled";
import { ReactComponent as DeleteIcon } from 'assets/images/customDeleteIcon.svg';
import { ReactComponent as EditIcon } from "assets/images/editIcon2.svg";

const WebhookInput = ({ section, initialState, onSubmit }) => {
    const defaultWebhook = { url: '', headers: [{ key: '', value: '' }] };

    const [localWebhook, setLocalWebhook] = useState(initialState || []);
    const [inputVisible, setInputVisible] = useState(localWebhook.length === 0);
    const [currentWebhook, setCurrentWebhook] = useState(defaultWebhook);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null);
    const [showError, setShowError] = useState(false);

    const validateInput = (value) => {
        const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        return urlRegex.test(value);
    };

    const isOnlyEmptyWebhook = () => {
        return localWebhook.length === 1 && JSON.stringify(localWebhook[0]) === JSON.stringify(defaultWebhook);
    };

    const handleAddItem = () => {
        if (validateInput(currentWebhook.url)) {
            let newItems;
            if (editingIndex !== null) {
                newItems = [...localWebhook];
                newItems[editingIndex] = currentWebhook;
                setEditingIndex(null);
            } else {
                newItems = [...localWebhook, currentWebhook];
            }
            setLocalWebhook(newItems);
            setInputVisible(false);
            setCurrentWebhook(defaultWebhook);
            setShowError(false);
            onSubmit(section, newItems);
        } else {
            setShowError(true);
        }
    };

    const handleInputChange = (value) => {
        setCurrentWebhook(prevState => ({
            ...prevState,
            url: value
        }));
    };

    const handleHeaderChange = (headerIndex, key, value) => {
        setCurrentWebhook(prevState => {
            const updatedHeaders = [...prevState.headers];
            updatedHeaders[headerIndex][key] = value;
            return {
                ...prevState,
                headers: updatedHeaders
            };
        });
    };

    const handleAddHeader = () => {
        setCurrentWebhook(prevState => ({
            ...prevState,
            headers: [...prevState.headers, { key: '', value: '' }]
        }));
    };

    const handleRemoveHeader = (headerIndex) => {
        setCurrentWebhook(prevState => ({
            ...prevState,
            headers: prevState.headers.filter((_, index) => index !== headerIndex)
        }));
    };

    const toggleInputVisible = () => {
        setInputVisible(!inputVisible);
        setCurrentWebhook(defaultWebhook);
        setEditingIndex(null);
    };

    const handleRemoveItem = (index) => {
        setItemToDelete(index);
        setDeleteModalOpen(true);
    };

    const confirmRemoveItem = () => {
        const newItems = localWebhook.filter((_, i) => i !== itemToDelete);
        setLocalWebhook(newItems);
        setDeleteModalOpen(false);
        setItemToDelete(null);
        setEditingIndex(null);
        setCurrentWebhook(defaultWebhook);
        setInputVisible(newItems.length === 0);
        onSubmit(section, newItems);
    };

    const handleEditItem = (index) => {
        setCurrentWebhook(localWebhook[index]);
        setEditingIndex(index);
        setInputVisible(true);
    };

    const handleCancelEdit = () => {
        setCurrentWebhook(defaultWebhook);
        setEditingIndex(null);
        setInputVisible(false);
    };

    return (
        <div className="input-chips webhook">
            <div className="input-chips-top">
                <div className="input-chips-top-tags">
                    {localWebhook.filter(item => item.url).map((item, index) => (
                        <Tooltip text={item.url} disabled={item?.url?.length < 70} key={index}>
                            <Tag onClick={() => handleEditItem(index)}>
                                <div className="icon-check">
                                    <FaRegCircleCheck size={16}/>
                                </div>
                                <div className="icon-edit">
                                    <EditIcon size={16}/>
                                </div>
                                <div style={{width: 200}} className="eclipse-text">
                                    {item.url}
                                </div>
                                <div className="icon-close" onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveItem(index)
                                }}>
                                    <IoClose size={16}/>
                                </div>
                            </Tag>
                        </Tooltip>
                    ))}

                    {!inputVisible && !isOnlyEmptyWebhook() && (
                        <Button
                            shape={"circle"}
                            placeholder={<LuPlus size={18} color={'#475467'}/>}
                            onClick={toggleInputVisible}
                        />
                    )}
                </div>
            </div>

            {(inputVisible || isOnlyEmptyWebhook()) && (
                <div className="input-chips-add">
                    <div className="input-chips-add-close">
                        <Button
                            typeOfButton={"text"}
                            placeholder={<IoClose color={"#98A2B3"} size={14}/>}
                            onClick={handleCancelEdit}
                        />
                    </div>
                    <div className="input-chips-add-link">
                        <Input
                            type="url"
                            placeholder="e.g. https://example.com"
                            value={currentWebhook.url}
                            onChange={(e) => {
                                handleInputChange(e.target.value);
                                setShowError(false);
                            }}
                            onBlur={(e) => {
                                if (!validateInput(currentWebhook.url)) {
                                    e.target.value && setShowError(true);
                                }
                            }}
                            onPressEnter={handleAddItem}
                            subLabel="Connect a webhook"
                        />
                        <div className="error-message">
                            {showError && !validateInput(currentWebhook.url) && 'Wrong webhook URL format. Please try again.'}
                        </div>

                        {editingIndex === null &&
                            <Button
                                size="large"
                                placeholder={'Submit'}
                                onClick={handleAddItem}
                                disabled={!validateInput(currentWebhook.url)}
                            />
                        }
                    </div>

                    <div className="input-chips-add-header-item-wrapper">
                        {currentWebhook.headers.map((header, headerIndex) => (
                            <div key={headerIndex} className="input-chips-add-header-item">
                                <Input
                                    placeholder="key"
                                    value={header.key}
                                    onChange={(e) => handleHeaderChange(headerIndex, 'key', e.target.value)}
                                />
                                <Input
                                    placeholder="value"
                                    value={header.value}
                                    onChange={(e) => handleHeaderChange(headerIndex, 'value', e.target.value)}
                                />
                                {headerIndex === 0 ? (
                                    <Button
                                        size="large"
                                        placeholder={<HiOutlinePlus color={"#475467"}/>}
                                        onClick={handleAddHeader}
                                    />
                                ) : (
                                    <Button
                                        size="large"
                                        placeholder={<DeleteIcon/>}
                                        onClick={() => handleRemoveHeader(headerIndex)}
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    {editingIndex !== null && (
                        <div className="input-chips-add-bottom" style={{marginTop: 16}}>
                            <div className="input-chips-add-bottom-left">
                                <Button
                                    typeOfButton={"text"}
                                    placeholder={"Delete"}
                                    style={{color: "#F04438"}}
                                    onClick={() => handleRemoveItem(editingIndex)}
                                />
                            </div>
                            <div className="input-chips-add-bottom-right">
                                <Button
                                    placeholder={"Cancel"}
                                    style={{color: "#475467"}}
                                    onClick={handleCancelEdit}
                                />
                                <Button
                                    typeOfButton={"primary"}
                                    placeholder={"Save changes"}
                                    onClick={handleAddItem}
                                    disabled={!validateInput(currentWebhook.url)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}

            <ConfirmationModal
                title={`Are you sure you want to delete this webhook URL?`}
                desc={`This action will permanently remove the webhook URL from your notifications.`}
                onSubmit={confirmRemoveItem}
                isModalOpen={deleteModalOpen}
                setModalOpen={setDeleteModalOpen}
            />
        </div>
    );
}
export default WebhookInput;