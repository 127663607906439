import './style.scss';
import Modal from 'components/modal';
import Button from 'components/button';
import { useCallback, useEffect, useState } from 'react';
import Input from 'components/input';
import TooltipComponent from 'components/tooltip/tooltip';
import { IoIosArrowDown } from 'react-icons/io';
import { FiInfo } from 'react-icons/fi';
import { Form, Select } from 'antd';
import { timeToMilliseconds, sizeToByes } from 'services/valueConvertor';
import { useHttpRequest } from 'services/http';
import { apiEndpoints } from 'services/apiEndpoints';
import { bytesToSize, millisecondsToTime, retentionToDifferentUnits } from 'services/valueConvertor';

const timeUnits = {
    s: 'second(s)',
    m: 'minute(s)',
    h: 'hour(s)',
    d: 'day(s)'
};

const sizeUnits = {
    b: 'byte(s)',
    kb: 'KB',
    mb: 'MB',
    gb: 'GB',
    tb: 'TB'
};

const CustomRecreateRetentionModal = ({ openModal, onSubmit, closeModal, connection, retention }) => {
    const [inputValue, setInputValue] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [form] = Form.useForm();
    const [retentionUnits, setRetentionUnits] = useState({ size: 'b', time: 's' });
    const [maxAllowedInputValue, setMaxAllowedInputValue] = useState({});
    const httpRequest = useHttpRequest();

    useEffect(() => {
        openModal && fetchData();
    }, [connection && openModal]);

    const fetchData = async () => {
        try {
            const response = await httpRequest('GET', apiEndpoints.GET_CONNECTION_SETTINGS, {}, {}, { connection_id: connection });
            const time = millisecondsToTime(response?.topic_retention_setting?.time_ms);
            const bytes = bytesToSize(response?.topic_retention_setting?.bytes);
            setMaxAllowedInputValue(retentionToDifferentUnits(response?.topic_retention_setting));
            setInputValue({ bytes: bytes.size, time_ms: time.time });
            form.setFieldsValue({ bytes: bytes.size, time_ms: time.time });
            setRetentionUnits({ size: bytes.unit, time: time.unit });
        } catch (error) {
            console.error('Failed to fetch connection settings:', error);
        }
    };

    const handleSubmit = useCallback(
        (e) => {
            setLoadingState(true);
            onSubmit({
                bytes: sizeToByes(e?.bytes, retentionUnits?.size),
                time_ms: timeToMilliseconds(e?.time_ms, retentionUnits?.time)
            })
                .then(() => {
                    setInputValue('');
                    closeModal();
                })
                .finally(() => {
                    setLoadingState(false);
                });
        },
        [onSubmit, closeModal, inputValue]
    );
    useEffect(() => {
        if (openModal) {
            form.resetFields();
        }
    }, [openModal]);

    const selectAfterSize = (
        <Select
            value={retentionUnits?.size || 'b'}
            onChange={(e) => setRetentionUnits({ ...retentionUnits, size: e })}
            suffixIcon={<IoIosArrowDown />}
            options={[
                { value: 'b', label: 'Byte(s)' },
                { value: 'kb', label: 'KB' },
                { value: 'mb', label: 'MB' },
                { value: 'gb', label: 'GB' },
                { value: 'tb', label: 'TB' }
            ]}
        />
    );

    const selectAfterTime = (
        <Select
            value={retentionUnits?.time || 'ms'}
            onChange={(e) => setRetentionUnits({ ...retentionUnits, time: e })}
            suffixIcon={<IoIosArrowDown />}
            options={[
                { value: 's', label: 'seconds' },
                { value: 'm', label: 'minutes' },
                { value: 'h', label: 'hours' },
                { value: 'd', label: 'days' }
            ]}
        />
    );

    const onFieldsChange = (_, allFields) => {
        const errors = form.getFieldsError();
        const someFields = allFields?.some((field) => field.value);
        setIsFormValid(someFields && errors?.filter(({ errors }) => errors?.length)?.length === 0);
    };

    const handleChangeRetention = (value, type) => {
        setInputValue({ ...inputValue, type: value });
    };

    return (
        <Modal isModalOpen={openModal} clickOutside={closeModal} className={'custom-retention-modal'}>
            <Form name="retention-form" onFinish={handleSubmit} autoComplete="off" validateTrigger={'onChange'} form={form} onFieldsChange={onFieldsChange}>
                <div className="top">
                    <div className="title">Change retention policy</div>
                </div>
                <div className="middle">
                    <div className="content">Please enter the desired retention policy you wish to apply to the topic(s).</div>
                    <div className="applied-policy">
                        <span className="applied-policy-values">
                            <label>Cluster policy: </label>
                            <label className="value">
                                {maxAllowedInputValue.bytes
                                    ? `${bytesToSize(maxAllowedInputValue.bytes).size} ${sizeUnits[bytesToSize(maxAllowedInputValue.bytes).unit]}`
                                    : undefined}
                                {maxAllowedInputValue.s
                                    ? `${millisecondsToTime(maxAllowedInputValue.s * 1000).time} ${timeUnits[millisecondsToTime(maxAllowedInputValue.s * 1000).unit]}`
                                    : undefined}
                            </label>{' '}
                        </span>
                        {retention && <p>Current retention: {retention}</p>}
                    </div>
                    <div className="retention-section">
                        <span className="switch-section">
                            <label className="content">By size</label>
                            <TooltipComponent text="For infinite retention, set the size to -1" className={'info-icon'}>
                                <FiInfo />
                            </TooltipComponent>
                        </span>
                        <Form.Item
                            name={'bytes'}
                            validateTrigger="onChange"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (isNaN(value) || value === 0 || value < -1) {
                                            return Promise.reject(new Error('Please enter a valid number'));
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <Input
                                type={'number'}
                                placeholder={-1}
                                min={-1}
                                max={maxAllowedInputValue[retentionUnits['size']]}
                                onChange={(e) => {
                                    form.setFieldsValue({ bytes: e.target.value });
                                    handleChangeRetention(e.target.value, 'bytes');
                                }}
                                addonAfter={selectAfterSize}
                            />
                        </Form.Item>
                    </div>
                    <div className="retention-section">
                        <span className="switch-section">
                            <label className="content">By duration</label>
                            <TooltipComponent text="For infinite retention, set the duration to -1" className={'info-icon'}>
                                <FiInfo />
                            </TooltipComponent>
                        </span>
                        <Form.Item
                            name={'time_ms'}
                            validateTrigger="onChange"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (isNaN(value) || value === 0 || value < -1) {
                                            return Promise.reject(new Error('Please enter a valid number'));
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <Input
                                type={'number'}
                                placeholder={-1}
                                min={-1}
                                max={maxAllowedInputValue[retentionUnits['time']]}
                                onChange={(e) => {
                                    form.setFieldsValue({ time_ms: e.target.value });
                                    handleChangeRetention(e.target.value, 'time_ms');
                                }}
                                addonAfter={selectAfterTime}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="bottom">
                    <Button customClassName="modal-btn modal-btn-secondary" placeholder="Cancel" onClick={closeModal} />
                    <Button htmlType={'submit'} customClassName="modal-btn modal-btn-primary" placeholder="Apply" loading={loadingState} disabled={!isFormValid} />
                </div>
            </Form>
        </Modal>
    );
};

export default CustomRecreateRetentionModal;
