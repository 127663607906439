import { useState } from 'react';

import { ReactComponent as GenerateIcon } from 'assets/images/graphView/generate.svg';
import CustomButtonWrapper from 'components/buttonsWrapper/CustomButtonWrapper';
import ButtonsWrapper from 'components/buttonsWrapper';
import Button from 'components/button';
import Editor from 'components/editor';
import { downloadYamlFile } from 'services/genericServices';
import CopyCode from '../copyCode';
import { SESSION_STORAGE_ACCOUNT_ID, SESSION_STORAGE_ACTIVATION_TOKEN } from 'utils/localStorageConsts';

const StepTwo = ({}) => {
    const [configuration, setConfiguration] = useState(`############################################################
# GLOBAL configuration for Superstream Engine
############################################################
global:
engineName: ""               # Define the superstream engine name within 32 characters, excluding '.', and using only lowercase letters, numbers, '-', and '_'.
superstreamAccountId: ${sessionStorage.getItem(
        SESSION_STORAGE_ACCOUNT_ID
    )}     # Provide the account ID associated with the deployment, which could be used for identifying resources or configurations tied to a specific account.
superstreamActivationToken: ${
        sessionStorage.getItem(SESSION_STORAGE_ACTIVATION_TOKEN) || `""`
    }           # Enter the activation token required for services or resources that need an initial token for activation or authentication.
skipLocalAuthentication: true
############################################################
# NATS config
############################################################
# NATS HA Deployment. Default "true"
nats:
    config:
        cluster:
            enabled: true
# NATS storageClass configuration. Default is blank "".
 jetstream:
    fileStore:
        pvc:
            storageClassName: ""`);

    return (
        <>
            <div className="add-connector-drawer-subheader">
                <div className="left">
                    <div className="add-connector-drawer-content">
                        <div className="add-connector-drawer-content-title">
                            Create a <em>custom_values.yaml</em>
                        </div>
                        <div className="add-connector-drawer-content-subtitle">Please modify the file below to include your account's credentials.</div>
                    </div>
                </div>
                <div className="right"></div>
            </div>
            <div className="add-connector-drawer-editor" style={{ height: 'calc( 100vh - 375px)' }}>
                <div className="add-connector-drawer-editor-top">
                    <CopyCode code={configuration} hideCode={true} style={{ background: 'transparent', padding: 0 }} />
                </div>
                <Editor value={configuration} setValue={(e) => setConfiguration(e)} lang="yaml" />
            </div>
        </>
    );
};

export default StepTwo;
