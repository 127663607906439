import './style.scss';

import React, { useContext, useEffect } from 'react';
import { apiEndpoints } from 'services/apiEndpoints';
import { Context } from 'hooks/store';
import { Outlet, useOutlet } from 'react-router-dom';
import { useHttpRequest } from 'services/http';
import Sidebar from './components/sidebar';
import Dashboard from 'pages/dashboard';

const MainLayout = ({}) => {
    const httpRequest = useHttpRequest();
    const outlet = useOutlet();
    const [, dispatch] = useContext(Context);

    useEffect(() => {
        getAllEnvironments();
        getAllKeys();
        getAllConnections();
    }, []);

    const getAllEnvironments = async () => {
        let data;
        try {
            data = await httpRequest('GET', apiEndpoints.GET_ALL_ENVIRONMENTS);
        } catch (error) {}

        dispatch({ type: 'SET_ENVIRONMENTS', payload: data?.environments || [] });
    };

    const getAllConnections = async () => {
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_ALL_CONNECTIONS);
            dispatch({ type: 'SET_CONNECTIONS', payload: data?.connections || [] });
        } catch (error) {
        } finally {
            dispatch({ type: 'SET_LOADING_CONNECTIONS', payload: false });
        }
    };

    const getAllKeys = async () => {
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_ALL_KEYS);
            dispatch({ type: 'SET_KEYS_LIST', payload: data?.keys });
        } catch (error) {}
    };

    return (
        <div className="main-layout">
            <div className="main-layout-left">
                <Sidebar />
            </div>
            <div className="main-layout-right">
                <div className="main-layout-body" id="main-layout-body">{outlet ? <Outlet /> : <Dashboard />}</div>
            </div>
        </div>
    );
};

export default MainLayout;
