import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useHttpRequest } from 'services/http';
import { apiEndpoints } from 'services/apiEndpoints';
import { Form } from 'antd';
import Notification from 'components/notifications';
import Modal from 'components/modal';
import Button from 'components/button';
import Loader from 'components/loader';
import ConfigItem from './components/configItem';
import ApplyClustersModal from './components/applyClustersModal';
import { ReactComponent as KafkaIcon } from 'assets/images/sidebar/kafka.svg';
import { Context } from 'hooks/store';
import { sizeToByes, timeToMilliseconds } from 'services/valueConvertor';
import debounce from "lodash.debounce";

const configurationOptimizationItems = [
    {
        name: 'topic_protection_rules',
        title: 'Topic protection',
        description: 'Topic names that follow one of the specified patterns (case sensitive) will be excluded by the platform, ensuring that no actions are applied to them.',
        children: [
            {
                name: 'topic_protection_rules.contain',
                type: 'text',
                label: 'Contain (multiple values)',
                defaultValue: '',
                regex: false,
                placeholder: 'example_1, example_2'
            },
            {
                name: 'topic_protection_rules.prefix',
                type: 'text',
                label: 'Prefix (multiple values)',
                defaultValue: '',
                regex: false,
                placeholder: 'ex, on, em'
            },
            {
                name: 'topic_protection_rules.suffix',
                type: 'text',
                label: 'Suffix (multiple values)',
                defaultValue: '',
                regex: false,
                placeholder: 'en, ex',
            },
            {
                name: 'topic_protection_rules.equal_to',
                type: 'text',
                label: 'Equal to (multiple values)',
                defaultValue: '',
                regex: false,
                placeholder: 'topic_name, topic_name_2'
            }
        ]
    },
    {
        name: 'inactive_skewed_topics_setting',
        title: 'Topic inactivity detection',
        description:
            'Set the time after which a topic is considered inactive if no reads/writes are made to it. The default is 3 days, but you can adjust this period to your business logic.',
        type: 'number',
        label: 'Needed days to declare inactive (2-25)',
        min: 2,
        max: 25,
        defaultValue: 3
    },
    {
        name: 'inactive_cgs_setting_days',
        title: 'Consumer Groups inactivity detection',
        description:
            'Set the time after which a consumer group is considered inactive if no message has been consumed. The default is 3 days, but you can adjust this period according to your business logic.',
        type: 'number',
        label: 'Needed days to declare inactive (2 - 25)',
        min: 2,
        max: 25,
        defaultValue: 3
    },
    {
        name: 'inactive_cgs_setting_ignore_non_empty_groups',
        title: 'Inactive Consumer Groups: filter out non-empty groups',
        type: 'tuggle',
        defaultValue: false
    },
    {
        name: 'json_uncompressed_topics_setting',
        title: 'Uncompressed topic / Topics with JSON events detection',
        description:
            'Set the time period in which it can be determined whether the topic contains JSONs or uncompressed messages. The default is 3 days, but you can adjust this period according to your business logic.',
        type: 'number',
        label: 'Needed days to declare (2 - 25)',
        min: 2,
        max: 25,
        defaultValue: 3
    }
];

const configurationRetentionItems = [
    {
        name: 'topic_retention_setting',
        title: 'Maximum Permitted Retention Policy',
        description: 'Superstream will detect and highlight topics that exceed the retention policy.',
        defaultValue: {
            bytes: -1,
            time_ms: -1
        },
        type: 'retention',
    }
];

const Configuration = forwardRef((
    {
        connectionId,
        doneRefresh,
        setActionButtonTitle,
        setActionButtonDisabled,
        onSubmit,
        page = 'optimizations'
    }, ref
) => {
    const httpRequest = useHttpRequest();
    const [state] = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [connectionSettings, setConnectionSettings] = useState(null);
    const [formValues, setFormValues] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [connectionModal, setConnectionModal] = useState(false);
    const configurationsType = page;
    const configurationItems= configurationOptimizationItems;
    const [retentionUnits, setRetentionUnits] = useState({size: 'b', time: 'ms'});
    const [configForm] = Form.useForm();
    const configItemRefs = useRef([]);

    useImperativeHandle(ref, () => ({
        handleRefresh() {
            fetchData();
        }
    }));

    useEffect(() => {
        setActionButtonTitle('Apply');
    }, [connectionId]);

    useEffect(() => {
        fetchData();
    }, [connectionId]);


    const fetchData = debounce(async () => {
        if (!connectionId) return;

        setLoading(true);
        try {
            const response = await httpRequest('GET', apiEndpoints.GET_CONNECTION_SETTINGS, {}, {}, { connection_id: connectionId });
            setConnectionSettings(reverseTransformStructure(response));
            setFormValues(reverseTransformStructure(response));
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
            doneRefresh();
        }
    }, 300);

    const updateFormValues = (value) => {
        if (!value) return;

        const isNumber = configurationItems?.find((item) => item?.name === Object.keys(value)[0])?.type === 'number';
        if (Object?.keys(value)[0] === 'bytes') {
            setFormValues({
                ...formValues,
                topic_retention_setting: {
                    ...formValues.topic_retention_setting,
                    [Object.keys(value)[0]]: sizeToByes(Object.values(value)[0], retentionUnits?.size)
                }
            });
        } else if (Object?.keys(value)[0] === 'time_ms') {
            setFormValues({
                ...formValues,
                topic_retention_setting: {
                    ...formValues.topic_retention_setting,
                    [Object.keys(value)[0]]: timeToMilliseconds(Object.values(value)[0], retentionUnits?.time)
                }
            });
        } else setFormValues({
            ...formValues,
            [Object.keys(value)[0]]: isNumber ? Number(Object.values(value)[0]) : Object.values(value)[0]
        });
    };

    const updateRetentionFormValues = (value, units, type) => {
        if (type === 'bytes') {
            setFormValues({
                ...formValues,
                topic_retention_setting: {...formValues.topic_retention_setting, bytes: sizeToByes(value, units?.size)}
            });
        } else {
            setFormValues({
                ...formValues,
                topic_retention_setting: {
                    ...formValues.topic_retention_setting,
                    time_ms: timeToMilliseconds(value, units?.time)
                }
            });
        }
    };

    const validateDisabled = () => {
        if (!formValues || !connectionSettings) return;
        const isUpdated =
            formValues['topic_protection_rules.contain'] !== connectionSettings?.['topic_protection_rules.contain'] ||
            formValues['topic_protection_rules.prefix'] !== connectionSettings?.['topic_protection_rules.prefix'] ||
            formValues['topic_protection_rules.suffix'] !== connectionSettings?.['topic_protection_rules.suffix'] ||
            formValues['topic_protection_rules.equal_to'] !== connectionSettings?.['topic_protection_rules.equal_to'] ||
            configurationItems?.some((item) => formValues[item?.name] !== connectionSettings[item?.name]) ||
            formValues?.topic_retention_setting?.bytes !== connectionSettings?.topic_retention_setting?.bytes ||
            formValues?.topic_retention_setting?.time_ms !== connectionSettings?.topic_retention_setting?.time_ms;
        const allValid = !configItemRefs?.current?.every((ref) => {
            ref && ref?.validate();
        });
        setActionButtonDisabled(!isUpdated || !allValid);
    };

    useEffect(() => {
        validateDisabled();
    }, [formValues, connectionSettings]);

    const transformStructure = (data) => {
        const transformedData = {...data};

        transformedData.topic_protection_rules = transformedData.topic_protection_rules || {};

        Object.keys(data).forEach((key) => {
            if (key.startsWith("topic_protection_rules.")) {
                const subKey = key.split("topic_protection_rules.")[1];
                if (!transformedData.topic_protection_rules) {
                    transformedData.topic_protection_rules = {};
                }
                transformedData.topic_protection_rules[subKey] = data[key];
                delete transformedData[key];
            }
        });

        return transformedData;
    };

    const reverseTransformStructure = (data) => {
        const transformedData = {...data};

        if (transformedData.topic_protection_rules) {
            Object.keys(transformedData.topic_protection_rules).forEach((subKey) => {
                transformedData[`topic_protection_rules.${subKey}`] = transformedData.topic_protection_rules[subKey];
            });
            delete transformedData.topic_protection_rules;
        }

        return transformedData;
    };

    const updateConnectionSettings = async (connectionsList) => {
        if (!connectionsList) return;

        setLoadingUpdate(true);
        formValues.cg_lag_setting = 500;
        const body = {...transformStructure(formValues), connection_ids: connectionsList};
        try {
            await httpRequest('POST', apiEndpoints.SET_CONNECTION_SETTINGS, body, {}, {});
            Notification('success', 'The configuration parameters have been successfully updated. ', 15);
        } catch (error) {
            Notification('error', 'Failed updating configuration. ', 15);
            setLoadingUpdate(false);
        } finally {
            setLoadingUpdate(false);
            setConnectionModal(false);
        }
    };

    const handleOptimizationLogic = useCallback(() => {
        state?.connections?.length > 1 ? setOpenModal(true) : updateConnectionSettings([Number(connectionId)]);
    }, [connectionId, state?.connections]);

    useEffect(() => {
        onSubmit(handleOptimizationLogic);
    }, [onSubmit]);

    return loading ? (
        <Loader background={false} isFullHeight={true}/>
    ) : (
        <div className="configuration-page">
            <div className="configuration-page-content">
                <Form form={configForm} validateTrigger={'onChange'}
                      onValuesChange={(value) => updateFormValues(value)}>
                    {configurationsType === 'optimizations' &&
                        configurationItems?.map((item, index) => (
                            <div key={item.name} style={{display: "flex", flexDirection: "column", gap: 16}}>
                                {item.children ? (
                                    item.children.map((child, childIndex) => (
                                        <ConfigItem
                                            key={`${item.name}-${child?.name}`}
                                            {...child}
                                            initialValue={connectionSettings[child?.name] || null}
                                            configForm={configForm}
                                            ref={(el) => (configItemRefs.current[index] = el)}
                                            title={item.title}
                                            description={item.description}
                                            index={childIndex}
                                            updateFormValues={(value) => updateFormValues(value)}
                                        />
                                    ))
                                ) : (
                                    <ConfigItem
                                        key={item.name}
                                        {...item}
                                        initialValue={connectionSettings[item?.name] || null}
                                        configForm={configForm}
                                        ref={(el) => (configItemRefs.current[index] = el)}
                                    />
                                )}
                            </div>
                        ))}
                    {configurationsType === 'retention' &&
                        configurationRetentionItems?.map((item, index) => (
                            <ConfigItem
                                key={item.name}
                                {...item}
                                initialValue={connectionSettings[item?.name] || null}
                                configForm={configForm}
                                ref={(el) => (configItemRefs.current[index] = el)}
                                updateNoRetention={(value) => updateFormValues(value)}
                                updateRetentionUnits={(value, units, type) => {
                                    setRetentionUnits(units);
                                    updateRetentionFormValues(value, units, type);
                                }}
                            />)
                        )
                    }
                </Form>
            </div>
            <div className="configuration-page-bottom">
                {/*<Button
                    onClick={() => (state?.connections?.length > 1 ? setOpenModal(true) : updateConnectionSettings([Number(connectionId)]))}
                    typeOfButton="text"
                    customClassName={'add-connector-placeholder-bottom-button'}
                    placeholder="Apply"
                    loading={loadingUpdate}
                    disabled={isDisabled}
                />*/}
            </div>
            <Modal isModalOpen={openModal} clickOutside={() => setOpenModal(false)}>
                <div className="todo-list-confirmation-modal">
                    <div className="body">
                        <div className="icon">
                            <KafkaIcon size={20}/>
                        </div>
                        <h2>Apply configuration</h2>
                        <p>Do you want to apply this to other clusters?</p>
                    </div>
                    <div className="footer">
                        <Button
                            customClassName="modal-btn modal-btn-secondary"
                            placeholder="No"
                            onClick={() => {
                                setOpenModal(false);
                                updateConnectionSettings([Number(connectionId)]);
                            }}
                        />
                        <Button
                            customClassName="modal-btn modal-btn-primary"
                            placeholder="Yes"
                            onClick={() => {
                                setOpenModal(false);
                                setConnectionModal(true);
                            }}
                            loading={loadingUpdate}
                        />
                    </div>
                </div>
            </Modal>
            <ApplyClustersModal
                isModalOpen={connectionModal}
                closeModal={() => setConnectionModal(false)}
                icon={<KafkaIcon size={20}/>}
                applyConfiguration={(connectionsList) => updateConnectionSettings(connectionsList)}
                loading={loadingUpdate}
                connection={connectionId}
            />
        </div>
    );
});

export default Configuration;
