import "./style.scss";
import dayjs from 'dayjs';
import { ConfigProvider, DatePicker } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SESSION_STORAGE_USER } from "utils/localStorageConsts";
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const getCurrentUserCreationDate = () => {
    const user = sessionStorage.getItem(SESSION_STORAGE_USER);
    if (user) {
        const userData = JSON.parse(user);
        const createdAt = parseInt(userData?.createdAt);
        return dayjs(new Date(createdAt)).format(dateFormat);
    }
    return null;
};

const customRangePicker = ({onChange, graphData}) => {
    const { RangePicker } = DatePicker;

    const getDefaultDates = () => {
        if (!graphData || graphData.length === 0) {
            return [dayjs().subtract(7, 'days'), dayjs()];
        }

        const dates = graphData.flatMap(item => item.data?.map(dataPoint => dayjs(dataPoint.date)).filter(date => date.isValid()) || []);

        if (dates.length === 0) {
            return [dayjs().subtract(7, 'days'), dayjs()];
        }

        const minDate = dates.reduce((min, current) => current.isBefore(min) ? current : min, dates[0]);
        const maxDate = dates.reduce((max, current) => current.isAfter(max) ? current : max, dates[0]);
        return [minDate, maxDate];
    };

    const defaultDates = getDefaultDates();

    return (
        <ConfigProvider
            theme={{
                components: {
                    DatePicker: {
                        colorPrimary: 'var(--primary-color)',
                        activeBorderColor: 'var(--primary-color)',
                        hoverBorderColor: 'var(--primary-color)',
                        colorPrimaryBorder: 'var(--primary-color)',
                        cellActiveWithRangeBg: 'rgba(90,79,229,0.1)',
                    },
                },
            }}
        >
            <RangePicker
                onChange={(date, dateString) => onChange(dateString[0], dateString[1])}
                minDate={getCurrentUserCreationDate() ? dayjs(getCurrentUserCreationDate(), dateFormat) : dayjs().subtract(1, 'year')}
                maxDate={dayjs()}
                style={{ width: 260 }}
                defaultValue={defaultDates}
            />
        </ConfigProvider>
    );
};

export default customRangePicker;