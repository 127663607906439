import './style.scss';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceDot, Legend } from 'recharts';
import { useEffect, useState } from 'react';
import { convertReportValue, convertStorage } from 'services/genericServices';
import { FaArrowDown } from 'react-icons/fa';

const Chart = ({ data, type, isDataAvailable, showLegend = false, startDate }) => {
    const [highlightedArea, setHighlightedArea] = useState(null);
    const [formattedData, setFormattedData] = useState([]);
    const [intersections, setIntersections] = useState([]);

    const isGraphEmpty = (data) => {
        const totalWithoutSuperstream = data.reduce((total, item) => total + item.without_superstream, 0);
        const totalSuperstream = data.reduce((total, item) => total + item.superstream, 0);
        return totalWithoutSuperstream === 0 && totalSuperstream === 0;
    };

    const getIntersections = (data) => {
        return data?.filter((item) =>
            type === 'partitions'
                ? item.superstream === item.without_superstream
                : convertStorage(item.superstream, false, false, false) === convertStorage(item.without_superstream, false, false, false)
        );
    };

    const CustomIcon = (props) => {
        const [isHovered, setIsHovered] = useState(false);

        return (
            <g
                transform={`translate(${props.cx - 6.5},${props.cy - 9})`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{ transition: 'all 0.3s ease-in-out', cursor: 'pointer' }}
            >
                <svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.8" y="2.7" width="6" height="6" rx="3" fill="white" stroke={isHovered ? '#37D2BC' : '#B9EFE5'} strokeWidth="1" />
                    <path
                        d="M5 4.233L5.293 4.82C5.333 4.9 5.44 4.98 5.528 4.993L6.06 5.082C6.4 5.14 6.48 5.385 6.235 5.629L5.822 6.045C5.753 6.114 5.713 6.249 5.735 6.347L5.853 6.861C5.947 7.265 5.731 7.423 5.374 7.212L4.876 6.916C4.785 6.863 4.638 6.863 4.547 6.916L4.047 7.21C3.69 7.421 3.474 7.264 3.567 6.859L3.685 6.346C3.707 6.249 3.667 6.114 3.598 6.043L3.187 5.631C2.942 5.386 3.022 5.14 3.362 5.084L3.894 4.995C3.983 4.98 4.089 4.902 4.129 4.822L4.422 4.235C4.579 3.915 4.842 3.915 5 4.233Z"
                        fill="#6358FA"
                    />
                </svg>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload, label, type }) => {
        if (!active || !payload || !payload.length) return null;

        const filteredPayload = payload.filter((entry) => entry.dataKey === 'superstream' || entry.dataKey === 'without_superstream');

        const formatLabel = (date) => new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });

        const formatDifference = (superstream, withoutSuperstream) => {
            if (superstream >= withoutSuperstream) return null;
            const formattedDiff = (100 - (superstream * 100) / withoutSuperstream).toFixed(0) + '%';
            return (
                <div style={{ backgroundColor: '#4D9387', borderRadius: 4, display: 'flex', alignItems: 'center', padding: '0 3px', gap: 4 }}>
                    <FaArrowDown />
                    <div>{formattedDiff}</div>
                </div>
            );
        };
        return (
            <div style={{ backgroundColor: '#141D2DCC', border: 'none', borderRadius: '10px', padding: '12px', color: '#FFFFFF', fontSize: '10px' }}>
                <p style={{ color: '#fff' }}>{formatLabel(label)}</p>
                {(type === 'partitions' && payload[0].payload.without_superstream === payload[1].payload.superstream) ||
                (type !== 'partitions' &&
                    convertStorage(payload[0].payload.without_superstream, false, false, false) ===
                        convertStorage(payload[1].payload.superstream, false, false, false)) ? (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                        <span>Hurray! You're optimized!</span>
                        <span>
                            Current status:{' '}
                            {type === 'partitions'
                                ? payload[0].payload.without_superstream?.toLocaleString()
                                : convertStorage(payload[0].payload.without_superstream, false, false)}
                        </span>
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {filteredPayload.map((entry, index) => {
                            const labelText = entry.dataKey === 'superstream' ? 'With Superstream: ' : 'Current status: ';
                            const value = type === 'partitions' ? entry?.value?.toLocaleString() : convertStorage(entry.value, false, false);
                            const difference = entry.dataKey === 'superstream' ? formatDifference(entry.payload.superstream, entry.payload.without_superstream) : null;

                            return (
                                <div key={index} style={{ color: '#fff', display: 'flex', alignItems: 'center', gap: 8, justifyContent: 'space-between' }}>
                                    <div>{labelText}</div>
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        {difference}
                                        {value}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };

    useEffect(() => {
        const formatData = data
            .map((item) => {
                const unixTimestamp = new Date(item.date).getTime();

                return {
                    ...item,
                    date: unixTimestamp,
                    superstream: item?.superstream,
                    without_superstream: item?.without_superstream
                };
            })
            .sort((a, b) => a.date - b.date);

        setFormattedData(formatData);
        setIntersections(getIntersections(formatData));
    }, [data]);

    return (
        formattedData.length > 0 && (
            <ResponsiveContainer minWidth={150} minHeight={200} maxWidth="100%" maxHeight="100%">
                <AreaChart data={formattedData} margin={{ top: 20, right: 16, left: 0, bottom: 0 }}>
                    <CartesianGrid strokeDasharray="10 0" vertical={false} strokeOpacity={0.4} stroke="#E8ECFF" />

                    {isDataAvailable ? (
                        <XAxis
                            dataKey="date"
                            tickFormatter={(date, index) => {
                                if (index === 0) return new Date(formattedData[0].date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                                if (index === formattedData.length - 1)
                                    return new Date(formattedData[formattedData.length - 1].date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                                return '';
                            }}
                            tickLine={true}
                            axisLine={true}
                            tick={{ fontSize: '10px', fill: '#98A2B3' }}
                            tickMargin={10}
                            tickSize={0.1}
                            scale="time"
                            type="number"
                            domain={['dataMin', 'dataMax']}
                            interval={0}
                            includeHidden
                        />
                    ) : (
                        <XAxis
                            dataKey={0}
                            ticks={null}
                            tickLine={false}
                            axisLine={false}
                            tick={{ fontSize: '10px', fill: '#98A2B3', display: 'none' }}
                            tickMargin={0}
                            includeHidden
                            width={'100px'}
                        />
                    )}
                    {isGraphEmpty(data) ? (
                        <YAxis
                            ticks={[0, 25, 50, 75, 100]}
                            tickLine={false}
                            axisLine={!isDataAvailable}
                            tick={{ fontSize: '10px', fill: '#98A2B3', width: 200 }}
                            tickMargin={5}
                            domain={[0, 'dataMax']}
                            tickFormatter={(value) => convertReportValue(type === 'partitions' ? 'patitionsAxis' : type, value, true)}
                        />
                    ) : (
                        <YAxis
                            tickLine={false}
                            axisLine={!isDataAvailable}
                            tick={{ fontSize: '10px', fill: '#98A2B3', width: 200 }}
                            tickMargin={5}
                            domain={[0, 'dataMax']}
                            tickFormatter={(value) => convertReportValue(type === 'partitions' ? 'patitionsAxis' : type, value, true)}
                        />
                    )}
                    {isDataAvailable && (
                        <>
                            <Tooltip
                                offset={30}
                                cursor={{ stroke: '#E8ECFF', strokeWidth: 2, strokeDasharray: '5 4', cursor: 'pointer' }}
                                content={<CustomTooltip type={type} />}
                            />
                            {showLegend && (
                                <Legend
                                    verticalAlign="bottom"
                                    align="center"
                                    iconSize={10}
                                    wrapperStyle={{ color: '#98A2B3', fontSize: '14px', width: '100%' }}
                                    onMouseEnter={(e) => setHighlightedArea(e.dataKey)}
                                    onMouseLeave={() => setHighlightedArea(null)}
                                    content={() => {
                                        return (
                                            <ul style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                <li
                                                    style={{
                                                        color: '#564AFA',
                                                        cursor: 'pointer',
                                                        listStyle: 'inside',
                                                        marginRight: '10px',
                                                        fontSize: '10px'
                                                    }}
                                                    onMouseEnter={() => setHighlightedArea('without_superstream')}
                                                    onMouseLeave={() => setHighlightedArea(null)}
                                                >
                                                    Current status
                                                </li>
                                                <li
                                                    style={{
                                                        color: '#37D2BC',
                                                        cursor: 'pointer',
                                                        listStyle: 'inside',
                                                        marginLeft: '10px',
                                                        fontSize: '10px'
                                                    }}
                                                    onMouseOver={() => setHighlightedArea('superstream')}
                                                    onMouseLeave={() => setHighlightedArea(null)}
                                                >
                                                    With Superstream
                                                </li>
                                            </ul>
                                        );
                                    }}
                                />
                            )}

                            <Area
                                type="monotone"
                                dataKey="without_superstream"
                                stroke="#564AFA"
                                fill="rgba(86, 74, 250, 0.06)"
                                strokeWidth={highlightedArea === 'without_superstream' ? 2.8 : 2.5}
                                dot={{ r: 1.2, strokeWidth: highlightedArea === 'without_superstream' ? 3.2 : 3, stroke: '#564AFA' }}
                                activeDot={{ r: 3, strokeWidth: 2, stroke: '#B1ABFF', cursor: 'pointer' }}
                                opacity={highlightedArea && highlightedArea !== 'without_superstream' ? 0.1 : 1}
                                animationDuration={1200}
                            />
                            <Area
                                type="monotone"
                                dataKey="superstream"
                                stroke="#37D2BC"
                                fill="rgba(55, 210, 188, 0.078)"
                                strokeWidth={highlightedArea === 'superstream' ? 2.8 : 2.5}
                                dot={{ r: 1.2, strokeWidth: highlightedArea === 'superstream' ? 3.2 : 3, stroke: '#37D2BC' }}
                                activeDot={{ r: 3, strokeWidth: 2, stroke: '#E0FFED', cursor: 'pointer' }}
                                opacity={highlightedArea && highlightedArea !== 'superstream' ? 0.1 : 1}
                                animationDuration={1200}
                            />

                            {intersections?.map((point, index) => (
                                <ReferenceDot key={index} x={point.date} y={point.superstream} r={8} shape={<CustomIcon />} />
                            ))}
                        </>
                    )}
                </AreaChart>
            </ResponsiveContainer>
        )
    );
};

export default Chart;
