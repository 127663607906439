import './style.scss';
import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, Spin, Switch } from "antd";
import Button from "components/button";
import { useHttpRequest } from "services/http";
import { apiEndpoints } from "services/apiEndpoints";
import debounce from "lodash.debounce";
import Loader from "components/loader";
import Notification from "components/notifications";
import { defaultTriggers, defaultWebhook, defaultNotificationChannels, staticData } from "./staticData";
import EmailInput from "./components/emailInput";
import WebhookInput from "./components/webhookInput";

const Notifications = () => {
    const httpRequest = useHttpRequest();
    const [isFetching, setIsFetching] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [initialNotificationState, setInitialNotificationState] = useState(null);
    const [notificationState, setNotificationState] = useState({});

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = debounce(async () => {
        setIsFetching(true);
        try {
            const response = await httpRequest('GET', apiEndpoints.NOTIFICATIONS_GET_ALL);
            const { job_triggered, ...rest } = response;

            job_triggered.triggers = defaultTriggers.map(defaultTrigger => {
                const correspondingBackendTrigger = job_triggered.triggers.find(trigger => trigger.type === defaultTrigger.type);
                return {
                    ...defaultTrigger,
                    is_active: correspondingBackendTrigger ? correspondingBackendTrigger.is_active : defaultTrigger.is_active,
                };
            });

            const processWebhook = (section) => {
                return section.webhook && section.webhook.length > 0 ? section.webhook : defaultWebhook;
            };

            const processNotificationChannels = (section) => {
                return section.notification_channels?.length > 0 ? section.notification_channels : defaultNotificationChannels;
            };

            const fetchedState = {
                ...rest,
                job_triggered: {
                    ...job_triggered,
                    webhook: processWebhook(job_triggered),
                    triggers: job_triggered.triggers,
                    notification_channels: processNotificationChannels(job_triggered)
                },
                auto_scaler: {
                    ...rest.auto_scaler,
                    webhook: processWebhook(rest.auto_scaler),
                    notification_channels: processNotificationChannels(rest.auto_scaler)
                },
            };

            setNotificationState(fetchedState);
            setInitialNotificationState(fetchedState);
        } catch (error) { } finally {
            setIsFetching(false);
        }
    }, 300);

    const toggleNotificationChannel = (section, channelType) => {
        setNotificationState(prevState => {
            const isChannelActive = prevState[section].notification_channels.find(channel => channel.type === channelType).is_active;

            let newState = {
                ...prevState,
                [section]: {
                    ...prevState[section],
                    notification_channels: prevState[section].notification_channels.map(channel =>
                        channel.type === channelType
                            ? { ...channel, is_active: !channel.is_active }
                            : channel
                    )
                }
            };

            if (section === 'auto_scaler') {
                const shouldAddAutoScalerTrigger = !isChannelActive || prevState[section].notification_channels.some(channel => channel.type !== channelType && channel.is_active);

                if (shouldAddAutoScalerTrigger) {
                    newState = {
                        ...newState,
                        [section]: {
                            ...newState[section],
                            triggers: [{ type: 'auto_scaler', is_active: true }]
                        }
                    };
                } else {
                    newState = {
                        ...newState,
                        [section]: {
                            ...newState[section],
                            triggers: []
                        }
                    };
                }
            }

            return newState;
        });
    };

    const toggleTrigger = (section, triggerType) => {
        setNotificationState(prevState => {
            return {
                ...prevState,
                [section]: {
                    ...prevState[section],
                    triggers: prevState[section].triggers.map(trigger =>
                        trigger.type === triggerType
                            ? { ...trigger, is_active: !trigger.is_active }
                            : trigger
                    )
                }
            };
        });
    };

    const handleEmailInput = (section, email) => {
        setNotificationState(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                email,
            }
        }));
    };

    const handleWebhookInput = (section, webhookSource) => {
        const webhook = webhookSource.filter(item => item.url.trim() !== '');
        setNotificationState(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                webhook,
            }
        }));
    };

    const isChannelActive = (section) => {
        const channels = notificationState[section]?.notification_channels || [];
        return channels.some(channel => channel.type === 'email' && channel.is_active) ||
            channels.some(channel => channel.type === 'webhook' && channel.is_active);
    };

    const renderNotificationBlock = (sectionKey, sectionData) => (
        <div className="notifications-block" key={sectionKey}>
            <div className="left">
                <div className="heading">
                    <div className="title">{sectionData.title}</div>
                    <div className="subtitle">{sectionData.subtitle}</div>
                </div>
                {sectionKey === 'job_triggered' && isChannelActive(sectionKey) && notificationState[sectionKey].triggers?.map((trigger, index) => (
                    <div className="checkbox" key={index}>
                        <Checkbox
                            checked={trigger.is_active}
                            onChange={() => toggleTrigger(sectionKey, trigger.type)}
                        >
                            {trigger.label}
                        </Checkbox>
                    </div>
                ))}
            </div>
            <div className="right">
                <div className="notifications-by-email">
                    <div className="notifications-switch">
                        <Switch
                            checked={notificationState[sectionKey]?.notification_channels?.find(channel => channel.type === 'email')?.is_active || false}
                            style={{ backgroundColor: (notificationState[sectionKey]?.notification_channels?.find(channel => channel.type === 'email')?.is_active ? 'var(--primary-color)' : '#D0D5DD') }}
                            onChange={() => toggleNotificationChannel(sectionKey, 'email')}
                        />
                        via email
                    </div>

                    {notificationState[sectionKey].notification_channels.find(channel => channel.type === 'email').is_active &&
                        <EmailInput
                            section={sectionKey}
                            initialState={notificationState[sectionKey].email}
                            onSubmit={handleEmailInput}
                        />
                    }
                </div>

                <div className="notifications-by-webhook">
                    <div className="notifications-switch">
                        <Switch
                            checked={notificationState[sectionKey].notification_channels.find(channel => channel.type === 'webhook').is_active}
                            style={{ backgroundColor: notificationState[sectionKey].notification_channels.find(channel => channel.type === 'webhook').is_active ? 'var(--primary-color)' : '#D0D5DD' }}
                            onChange={() => toggleNotificationChannel(sectionKey, 'webhook')}
                        />
                        via REST POST
                    </div>

                    {notificationState[sectionKey].notification_channels.find(channel => channel.type === 'webhook').is_active &&
                        <WebhookInput
                            section={sectionKey}
                            initialState={notificationState[sectionKey].webhook}
                            onSubmit={handleWebhookInput}
                        />
                    }
                </div>
            </div>
        </div>
    );

    const hasStateChanged = (initialState, currentState) => {
        return JSON.stringify(initialState) !== JSON.stringify(currentState);
    };

    const saveAllNotifications = async () => {
        setIsUpdating(true);
        const updatePromises = [];

        Object.keys(notificationState).forEach((sectionKey) => {
            const initialSectionData = initialNotificationState[sectionKey];
            const currentSectionData = notificationState[sectionKey];

            if (hasStateChanged(initialSectionData, currentSectionData)) {
                const payload = {
                    notification_type: sectionKey,
                    data: {
                        email: currentSectionData.email || [],
                        webhook: currentSectionData.webhook
                            ? currentSectionData.webhook.filter(item => item.url.trim() !== '')
                            : [],
                        notification_channels: currentSectionData?.notification_channels?.map(channel => ({
                            type: channel.type,
                            is_active: channel.is_active
                        })) || [],
                        triggers: currentSectionData?.triggers?.map(trigger => ({
                            type: trigger.type,
                            is_active: trigger.is_active
                        })) || []
                    }
                };
                updatePromises.push(httpRequest('POST', apiEndpoints.NOTIFICATION_UPDATE, payload));
            }
        });

        try {
            await Promise.all(updatePromises);
            Notification('success', 'Notifications updated successfully', 1);
            setInitialNotificationState(notificationState);
        } catch (error) {
            Notification('error', 'Failed to update notifications. Please try again later', 1);
        } finally {
            setIsUpdating(false);
        }
    };

    const isApplyButtonDisabled = useMemo(() => {
        let hasChanges = false;
        let isValid = true;

        if (!hasStateChanged(initialNotificationState, notificationState))
            return true;

        Object.keys(notificationState).forEach((sectionKey) => {
            const initialSectionData = initialNotificationState[sectionKey];
            const currentSectionData = notificationState[sectionKey];

            if (hasStateChanged(initialSectionData, currentSectionData)) {
                hasChanges = true;

                const emailChannel = currentSectionData.notification_channels.find(channel => channel.type === 'email');
                const webhookChannel = currentSectionData.notification_channels.find(channel => channel.type === 'webhook');

                if (emailChannel.is_active && (!currentSectionData.email || currentSectionData.email.length === 0)) {
                    isValid = false;
                }

                if (webhookChannel.is_active && (!currentSectionData.webhook || currentSectionData.webhook.length === 0 || currentSectionData.webhook.every(item => item.url.trim() === ''))) {
                    isValid = false;
                }

                if (sectionKey === 'job_triggered' && (emailChannel.is_active || webhookChannel.is_active)) {
                    const activeTriggers = currentSectionData.triggers.filter(trigger => trigger.is_active);
                    if (activeTriggers.length === 0) {
                        isValid = false;
                    }
                }
            }
        });

        return !hasChanges || !isValid;
    }, [notificationState]);

    return isFetching ? <Loader background={false} isFullHeight={true} /> : (
        <div className="notifications">
            <div className="top">
                <div className="top-left">
                    <h1>Notifications</h1>
                    <p>
                        Manage your notifications by selecting the system actions that matter most <br /> to you and how
                        you
                        want to be informed.
                    </p>
                </div>
                <div className="top-right">
                    <Button
                        typeOfButton={"primary"}
                        placeholder={'Apply'}
                        onClick={saveAllNotifications}
                        loading={isUpdating}
                        disabled={isApplyButtonDisabled}
                    />
                </div>
            </div>

            <Spin spinning={isUpdating} delay={500}>
                <div className="notifications-block-wrapper">
                    {Object.entries(staticData).map(([sectionKey, sectionData]) =>
                        renderNotificationBlock(sectionKey, sectionData)
                    )}
                </div>
            </Spin>
        </div>
    );
};

export default Notifications;
