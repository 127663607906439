export const staticData = {
    job_triggered: {
        title: 'When a job has been triggered',
        subtitle: <div>Get notified when a job is triggered, with the option to specify which <br /> job types you want
            to monitor.</div>,
    },
    auto_scaler: {
        title: 'When some auto-scaler rule has been triggered',
        subtitle: <div>Receive alerts when an auto-scaler rule is activated, adjusting <br /> resources based on demand.
        </div>,
    },
    /*plan_change: {
        title: 'Plan change opportunity (Aiven users only)',
        subtitle: <div>Stay informed of opportunities to optimize your plan for better <br/> performance or cost
            savings.</div>,
    },
    instance: {
        title: 'Instance type or broker count changes (MSK users only)',
        subtitle: <div>Be alerted to changes in instance type or the number of brokers in <br/> your MSK clusters.
        </div>,
    },
    weekly: {
        title: 'Weekly optimization and resource savings summary',
        subtitle: <div>Receive a weekly summary of optimizations and resource savings <br/> achieved across your
            clusters.</div>,
    }*/
};

export const defaultTriggers = [
    // {type: "topic_deletion", is_active: false, label: "Topic Deletion"},
    // {type: "compress_topic", is_active: false, label: "Compress Topic"},
    // {type: "remove_consumer_group", is_active: false, label: "Remove Consumer Group"},
    { type: "remove_inactive_cg", is_active: false, label: "Remove inactive CGs" },
    { type: "remove_inactive_topic", is_active: false, label: "Remove inactive topics" },
    { type: "remove_inactive_empty_topic", is_active: false, label: "Remove inactive empty topics" },
    { type: "change_topic_retention", is_active: false, label: "Change topic retention" },
    // {type: "reduce_payload", is_active: false, label: "Reduce Payload"},
    { type: "compress_payload", is_active: false, label: "Compress payload" },
    { type: "recreate_inactive_topic", is_active: false, label: "Recreate inactive topics" },
    { type: "recreate_inactive_empty_topic", is_active: false, label: "Recreate inactive empty topics" }
];


export const defaultWebhook = [
    {
        url: '',
        headers: [
            { key: '', value: '' }
        ]
    }
];

export const defaultNotificationChannels = [
    { type: "email", is_active: false },
    { type: "webhook", is_active: false }
];