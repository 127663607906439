import { initialState } from './store';

export const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING_CONNECTIONS':
            return {
                ...state,
                isLoadingConnections: action.payload
            };
        case 'SET_LOADING':
            return {
                ...state,
                isAppLoading: action.payload
            };
        case 'SET_NEW_JOB_INDICATOR':
            return {
                ...state,
                newJobIndicator: action.payload
            };
        case 'RESET_STATE':
            return initialState;
        case 'SET_USER_DATA':
            return {
                ...state,
                currentUser: action.payload
            };
        case 'SET_AUTHENTICATION':
            return {
                ...state,
                isAuthenticated: action.payload
            };
        case 'SET_DARK_MODE':
            return {
                ...state,
                darkMode: action.payload
            };
        case 'SET_CONNECTIONS':
            return {
                ...state,
                connections: action.payload
            };
        case 'SET_ENVIRONMENTS':
            return {
                ...state,
                environments: action.payload
            };
        case 'SET_SIDEBAR_STATE':
            return {
                ...state,
                isSidebarCollapsed: action.payload
            };
        case 'SET_KEYS_LIST':
            return {
                ...state,
                keysList: action.payload
            };
        case 'SET_CURRENT_PLAN':
            return {
                ...state,
                currentPlan: action.payload
            };
        case 'SET_PLAN_PROGRESS':
            return {
                ...state,
                planProgress: action.payload
            };
        default:
            return state;
    }
};

export const FilterReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SEARCH_VALUE':
            return {
                ...state,
                searchValue: action.payload
            }
        case 'SET_SELECTED_FILTERS':
            return {
                ...state,
                selectedFilters: action.payload
            }
        case 'SET_RESET_FILTERS':
            return {
                ...state,
                resetFilters: action.payload
            }
        case 'SET_APPLY_FILTERS':
            return {
                ...state,
                applyFilters: action.payload
            };
        default:
            return state;
    }
}