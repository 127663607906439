import './style.scss';
import React from 'react';
import { Drawer } from 'antd';

const MSDrawer = ({ width = 600, children, ...props }) => {
    return (
        <Drawer
            width={width}
            {...props}
        >
            {children}
        </Drawer>
    );
};

export default MSDrawer;
